import { crud, http } from "../libs/react-mpk/services";

let service = null;
let serviceUrl = "/level_produk";
service = new crud("/level_produk");

service.getLevelProduk = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.getLevelProdukById = async (levelId) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${levelId}`,
  });
};

service.getAllLevelProduk = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query
  });
};

service.setMateri = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/assign_materi/${id}`,
    data,
  });
};

service.getMateri = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/view_materi/${id}`,
  });
};

service.getUserMateri = async (id, query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/get_materi/${id}`,
    query
  });
};

service.create = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

service.update = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

service.deleteById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

service.getId = async (levelId, produkId) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/get_id`,
    query: {
      levelId: levelId,
      produkId: produkId,
    },
  });
};

export default service;
