import React from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  Button,
  Text,
} from "react-md";

const Confirm = ({
  visible = false,
  onRequestClose = () => { },
  onSubmitRequest = () => { },
  message = "",
  ...props
}) => {
  return (
    <>
      <Dialog visible={visible} onRequestClose={onRequestClose}>
        <DialogContent>
          <Text
            id="dialog-title"
            type="subtitle-1"
            margin="none"
            color="secondary"
          >
            {message}
          </Text>
        </DialogContent>

        <DialogFooter>
          <Button id="dialog-cancel"
            theme="clear"
            themeType="contained"
            style={{margin:"5px"}}
            onClick={onRequestClose}>
            Cancel
          </Button>

          <Button
            id="dialog-discard"
            theme="primary"
            themeType="contained"
            className="btn-primary"
            onClick={onSubmitRequest}
          >
            Yes
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Confirm;
