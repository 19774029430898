import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import { cloneDeep, find } from 'lodash'
import t from 'counterpart'
import service from '../../services/levelService';
import services from '../../services/product.service';
import { TableWrapper } from '../../libs/react-mpk/wrapper';

const defaultData = () => ({
    id: '',
    namaLevel: '',
    levelProduks: [],
    // levelProduksBefore: [],
})
const CreateLevel = ({
    visible = false,
    onRequestClose = () => { },
    ...props
}) => {

    let [dataSubmit, setDataSubmit] = useState({
        id: '',
        namaLevel: '',
        levelProduks: [],
        // levelProduksBefore: [],
    })
    let [item, setItem] = useState(null)
    let [ready, setReady] = useState(true)
    let [produks, setProduks] = useState(null);

    useEffect(() => {

        services.getProduct({ size: 1000 }).then((res) => {
            // console.log(res)
            setProduks(res.data);
        });

        async function initData() {
            if (visible) {
                let selectedItem = defaultData();
                if (props.item) {
                    // console.log(props.item)
                    try {
                        let res = await service.getLevelById(props.item.id)
                        let { data } = res
                        selectedItem.namaLevel = data.namaLevel
                        selectedItem.id = data.id
                        for (let i = 0; i < data.levelProduks.length; i++) {
                            selectedItem.levelProduks.push(data.levelProduks[i].namaProduk)
                        }
                        console.log(selectedItem)

                        setDataSubmit(data);


                        // selectedItem.number = String(selectedItem.number)
                    } catch (e) {
                        // onRequestClose()
                    }
                }
                setItem(selectedItem)
            } else {
                setItem(defaultData())
            }
            setReady(visible)
        }
        initData()
    }, [visible])


    const onSubmit = async (data, callback) => {
        // console.log(data)
        let reqData = dataSubmit;
        reqData.namaLevel = data.namaLevel

        reqData.levelProduks = [];
        for (let i = 0; i < data.levelProduks.length; i++) {
            let productData = find(produks, { namaProduk: data.levelProduks[i] })

            if (productData) {
                reqData.levelProduks.push({
                    produkId: productData.id,
                    namaProduk: productData.namaProduk
                })
            }
        }

        if (reqData.id) {
            try {
                const resp = await service.update(reqData, data.id)
                // console.log(resp)
                TableWrapper.updateDataItem(
                    "view-level",
                    { id: data.id },
                    data
                );
                TableWrapper.reload("view-level");
                callback('Edit Success', false)
            } catch (error) {
                // console.log(error.message)
                callback('Edit Error ' + error.message, true)
            }
        }
        else {
            // reqData.dibuatOleh = "ABCD"
            try {
                const resp = await service.create(reqData)
                console.log(resp)
                TableWrapper.updateDataItem(
                    "view-level",
                    { id: data.id },
                    data
                );
                TableWrapper.reload("view-level");
                callback('Create Success', false)
            } catch (error) {
                // console.log(error.message)
                callback("Create Error " + error.message, true)
            }
        }
    }

    return ready && (
        <DataForm
            baseId="create-level"
            title={t.translate('modules.form.level')}
            visible={visible}
            // data={item}
            onRequestClose={onRequestClose}
            asDialog={true}
            defaultData={item}
            definitions={[
                {
                    inputType: DataForm.inputTypes.INPUT,
                    label: t.translate("table.nameLevel"),
                    key: 'namaLevel',
                    validation: 'required'
                },
                {
                    inputType: DataForm.inputTypes.MULTI_CHECKBOX,
                    label: t.translate("table.levelProduk"),
                    key: "levelProduks",
                    options:
                        produks !== null
                            ? produks
                                .map((d, i) => ({
                                    value: d.namaProduk,
                                    label: d.namaProduk,
                                }))
                            : null,
                },
                // {
                //     inputType: DataForm.inputTypes.PRE_DATA
                // }
            ]}
            onBeforeChange={(key, value) => {
                // console.log(key, value)
                return value
            }}
            onSubmit={onSubmit}
        />
    )
}

export default CreateLevel