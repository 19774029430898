import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import { cloneDeep } from 'lodash'
import t from 'counterpart'
import service from '../../services/product.service';
import { TableWrapper } from '../../libs/react-mpk/wrapper';

const defaultData = () => ({
    id: '',
    namaProduk: '',
})
const CreateProduct = ({
    visible = false,
    onRequestClose = () => { },
    ...props
}) => {

    let [dataSubmit, setDataSubmit] = useState({
        id: '',
        namaProduk: '',
    })
    let [item, setItem] = useState(null)
    let [ready, setReady] = useState(true)

    useEffect(() => {
        async function initData() {
            if (visible) {
                let selectedItem = defaultData();
                console.log(props)
                if (props.item) {
                    // console.log(props.item)
                    try {
                        service.getProductById(props.item.id).then((res) => {
                            // console.log(res)
                            setDataSubmit(res);
                        }).catch((e) => {
                            // console.log(e)
                        })
                        selectedItem = cloneDeep(props.item)
                        selectedItem.number = String(selectedItem.number)
                    } catch (e) {
                        // onRequestClose()
                    }
                }
                setItem(selectedItem)
            } else {
                setItem(defaultData())
            }
            setReady(visible)
        }
        initData()
    }, [visible])


    const onSubmit = async (data, callback) => {
        // console.log(data)
        let reqData = dataSubmit;
        // console.log(reqData)
        if (reqData && reqData.data && reqData.data.id) {
            try {
                const resp = await service.update(data, data.id)
                // console.log(resp)
                TableWrapper.updateDataItem(
                    "view-product",
                    { id: data.id },
                    data
                );
                TableWrapper.reload("view-product");
                callback('Edit Success', false)
            } catch (error) {
                // console.log(error.message)
                callback('Edit Error ' + error.message, true)
            }
        }
        else {
            try {
                const resp = await service.create(data)
                // console.log(resp)
                TableWrapper.updateDataItem(
                    "view-product",
                    { id: data.id },
                    data
                );
                TableWrapper.reload("view-product");
                callback('Create Success', false)
            } catch (error) {
                // console.log(error.message)
                callback('Create Error ' + error.message, true)
            }
        }
    }

    return ready && (
        <DataForm
            baseId="create-product"
            title={t.translate('modules.form.product').toUpperCase()}
            visible={visible}
            style={{width: "60vh"}}
            // data={item}
            onRequestClose={onRequestClose}
            asDialog={true}
            defaultData={props.item || defaultData()}
            definitions={[
                {
                    inputType: DataForm.inputTypes.INPUT,
                    label: t.translate("table.nameProduct"),
                    key: 'namaProduk',
                    required: true,
                    validation: 'required'
                },
                // {
                //     inputType: DataForm.inputTypes.PRE_DATA
                // }
            ]}
            onBeforeChange={(key, value) => {
                // console.log(key, value)
                return value
            }}
            onSubmit={onSubmit}
            submitLabel={"Simpan"}
        />
    )
}

export default CreateProduct