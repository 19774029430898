// import React, { useEffect, useState } from "react";
// import { DataForm } from "../../libs/react-mpk/components";
// import { cloneDeep, find } from "lodash";
// import levelService from "../../services/levelService";
// import productService from "../../services/product.service";
// import levelProdukService from "../../services/levelProduk.service";
// import iziToast from "izitoast";
// import t from "counterpart";
// import { TableWrapper } from "../../libs/react-mpk/wrapper";

// const defaultData = () => ({
//   levelId: "",
//   level: {},
//   produkId: "",
//   product: {},
//   namaLevel: "",
//   namaProduk: "",
//   kodePerusahaan: "",
// });

// const LevelProdukForm = ({
//   visible = false,
//   onRequestClose = () => {},
//   ...props
// }) => {
//   let [item, setItem] = useState(defaultData);
//   let [ready, setReady] = useState(true);
//   let [dataSubmit, setDataSubmit] = useState({
//     levelId: "",
//     produkId: "",
//     namaLevel: "",
//     namaProduk: "",
//     kodePerusahaan: "",
//   });
//   let [levelList, setLevelList] = useState([]);
//   let [productList, setProductList] = useState([]);

//   const getLevelList = () => {
//     setTimeout(async () => {
//       try {
//         let res = await levelService.getLevel({ size: 9999, sort: "namaLevel,ASC" });
//         let levels = [];
//         let { data } = res;

//         data.map((d) => {
//           levels.push({
//             value: d.id,
//             label: `${d.namaLevel}`,
//           });
//         });

//         // console.log(levels)
//         setLevelList(levels);
//       } catch (err) {
//         iziToast.error({
//           message: err.message,
//         });
//       }
//     }, 500);
//   };

//   const getProductList = () => {
//     setTimeout(async () => {
//       try {
//         let query = {
//           page: 0,
//           size: 9999,
//           sort: "namaProduk,ASC",
//         };

//         let res = await productService.getProduct(query);
//         let products = [];
//         let { data } = res;

//         data.map((d) => {
//           products.push({
//             value: d.id,
//             label: `${d.namaProduk}`,
//           });
//         });

//         // console.log(levels)
//         setProductList(products);
//       } catch (err) {
//         iziToast.error({
//           message: err.message,
//         });
//       }
//     }, 500);
//   };

//   const initData = async () => {
//     if (visible) {
//       let selectedItem = defaultData();

//       if (props.item) {
//         selectedItem = cloneDeep(props.item);
//         selectedItem.number = String(selectedItem.number);
//       }
//       setItem(selectedItem);
//     } else {
//       setItem(defaultData());
//     }
//     setReady(visible);
//   };

//   useEffect(() => {
//     getLevelList();
//     getProductList();
//     initData();
//   }, [visible]);

//   const submitHandler = async (item, callback) => {
//     try {
//       let submitData = dataSubmit;
//       submitData.kodePerusahaan = props.match.params.companyId;
//       submitData.levelId = item.level;
//       submitData.namaLevel = item.levelId;
//       submitData.produkId = item.product;
//       submitData.namaProduk = item.produkId;

//       let res = await levelProdukService.create(submitData);
//       let { status } = res;
//       if (status == 201) {
//         TableWrapper.updateDataItem(
//           "view-level-produks",
//           { id: item.id },
//           item
//         );
//         TableWrapper.reload("view-level-produks");
//         callback("success", false);
//       }
//       // dataSubmit.
//     } catch (err) {
//       callback(err.message, true, false);
//     }
//   };

//   return (
//     ready && (
//       <DataForm
//         baseId="level-product-form"
//         title="BUAT / EDIT LEVEL PRODUK"
//         visible={visible}
//         style={{width: "50vh"}}
//         // data={item}
//         onRequestClose={onRequestClose}
//         asDialog={true}
//         defaultData={item}
//         definitions={[
//           {
//             inputType: DataForm.inputTypes.SELECT,
//             label: t.translate("table.namaLevel"),
//             key: "levelId",
//             // validation: "required",
//             // labelKey: "label",
//             // valueKey: "label",
//             options: levelList,
//             // data: levelList,
//             required: true,
//           },
//           {
//             inputType: DataForm.inputTypes.SELECT,
//             label: t.translate("table.namaProduk"),
//             key: "produkId",
//             // validation: "required",
//             // labelKey: "label",
//             // valueKey: "label",
//             options: productList,
//             data: productList,
//             required: true,
//           },
//         ]}
//         onChange={(data, key, value) => {
//           //   console.log(key, value);
//           if (key == "levelId") {
//             let level = find(levelList, { label: value });
//             data.level = level ? level.value : null;
//           }
//           if (key == "produkId") {
//             let product = find(productList, { label: value });
//             data.product = product ? product.value : null;
//           }
//           return data;
//         }}
//         onSubmit={submitHandler}
//         submitLabel={"Simpan"}
//       />
//     )
//   );
// };

// export default LevelProdukForm;

import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep, find } from "lodash";
import levelService from "../../services/levelService";
import productService from "../../services/product.service";
import levelProdukService from "../../services/levelProduk.service";
import iziToast from "izitoast";
import t from "counterpart";
import { TableWrapper } from "../../libs/react-mpk/wrapper";

const defaultData = () => ({
  levelId: "",
  level: {},
  produkId: "",
  product: {},
  namaLevel: "",
  namaProduk: "",
  kodePerusahaan: "",
});

const LevelProdukForm = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState(defaultData);
  let [ready, setReady] = useState(true);
  let [dataSubmit, setDataSubmit] = useState({
    levelId: "",
    produkId: "",
    namaLevel: "",
    namaProduk: "",
    kodePerusahaan: "",
  });
  let [levelList, setLevelList] = useState([]);
  let [productList, setProductList] = useState([]);

  const getLevelList = () => {
    setTimeout(async () => {
      try {
        let res = await levelService.getLevel({ size: 9999 });
        let levels = [];
        let { data } = res;

        data.map((d) => {
          levels.push({
            value: d.id,
            label: `${d.namaLevel}`,
          });
        });

        // console.log(levels)
        setLevelList(levels);
      } catch (err) {
        iziToast.error({
          message: err.message,
        });
      }
    }, 500);
  };

  const getProductList = () => {
    setTimeout(async () => {
      try {
        let query = {
          page: 0,
          size: 9999,
          sort: "tanggalDibuat,DESC",
        };

        let res = await productService.getProduct(query);
        let products = [];
        let { data } = res;

        data.map((d) => {
          products.push({
            value: d.id,
            label: `${d.namaProduk}`,
          });
        });

        // console.log(levels)
        setProductList(products);
      } catch (err) {
        iziToast.error({
          message: err.message,
        });
      }
    }, 500);
  };

  const initData = async () => {
    if (visible) {
      let selectedItem = defaultData();

      if (props.item) {
        selectedItem = cloneDeep(props.item);
        selectedItem.number = String(selectedItem.number);
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    getLevelList();
    getProductList();
    initData();
  }, [visible]);

  const submitHandler = async (item, callback) => {
    try {
      let submitData = dataSubmit;
      submitData.kodePerusahaan = props.match.params.companyId;
      submitData.levelId = item.level;
      submitData.namaLevel = item.levelId;
      submitData.produkId = item.product;
      submitData.namaProduk = item.produkId;

      let res = await levelProdukService.create(submitData);
      let { status } = res;
      if (status == 201) {
        TableWrapper.updateDataItem(
          "view-level-produks",
          { id: item.id },
          item
        );
        TableWrapper.reload("view-level-produks");
        callback("success", false);
      }
      // dataSubmit.
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  return (
    ready && (
      <DataForm
        baseId="level-product-form"
        title="Level Produk Form"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("table.levelProduk"),
            key: "levelId",
            // validation: "required",
            labelKey: "label",
            valueKey: "label",
            data: levelList,
            required: true,
          },
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("table.product"),
            key: "produkId",
            // validation: "required",
            labelKey: "label",
            valueKey: "label",
            data: productList,
            required: true,
          },
        ]}
        onChange={(data, key, value) => {
          //   console.log(key, value);
          if (key == "levelId") {
            let level = find(levelList, { label: value });
            data.level = level ? level.value : null;
          }
          if (key == "produkId") {
            let product = find(productList, { label: value });
            data.product = product ? product.value : null;
          }
          return data;
        }}
        onSubmit={submitHandler}
      />
    )
  );
};

export default LevelProdukForm;
