import { crud, http } from "../libs/react-mpk/services";

let service = null;
let serviceUrl = "/materipembelajaran";
service = new crud("/materipembelajaran");

service.getMateri = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.getUserMateri = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/user`,
    query
  });
};

service.getAllMateri = async () => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
  });
};

service.getMateriById = async (Id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${Id}`,
  });
};

service.create = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

service.update = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

service.deleteById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

const fileToFormData = (
  pdf,
  video,
  meta = {},
  pdfLabel = "file",
  pdfName,
  videoLabel = "file",
  videoName
) => {
  const data = new FormData();

  if (pdf) {
    data.append(pdfLabel, pdf, pdfName || pdf.name);
  }

  if (video) {
    data.append(videoLabel, video, videoName || video.name);
  }
  const keys = Object.keys(meta);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data.append(key, meta[key]);
  }
  return data;
};

service.uploadMateri = async (
  url,
  pdf,
  video,
  data,
  config = {},
  options = {}
) => {
  let opt = Object.assign(
    {
      pdfLabel: "filePdf",
      videoLabel: "fileVideo",
      pdfName: null,
      videoName: null,
    },
    options
  );
  let formData = fileToFormData(
    pdf,
    video,
    data,
    opt.pdfLabel,
    opt.pdfName,
    opt.videoLabel,
    opt.videoName
  );

  return http.request({
    method: http.methods.POST,
    url,
    data: formData,
    config,
    options,
  });
};

service.getVideoById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/video/${id}`
  })
}

service.getMateriByType = async(type) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/list`,
    query: {
      tipe: type,
      size: 100
    }
  })
}

export default service;
