import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Text,
  Radio,
  Card,
  FontIcon,
  TextIconSpacing,
} from "react-md";
import service from "../../../services/ujian.service";
import ExamTimer from "../ExamTimer";
import moment from "moment";
import iziToast from "izitoast";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";

const ViewSoalUjianpg = ({
  visible = true,
  onRequestClose = () => {},
  ...props
}) => {
  const calculateTimeLeft = () => {
    if(props?.item?.tanggalAkhirUjian){
      const difference = new Date(props.item.tanggalAkhirUjian) - new Date();
      return difference > 0 ? Math.floor(difference / 1000) : 0;
    }
    else return 0;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const [ready, setReady] = useState(false);
  const [item, setItem] = useState([]);
  let [showTimer, setShowTimer] = useState(false);
  let [exam, setExam] = useState({});
  const [name, setName] = useState("");
  const currDate = new Date();
  const zonedTime = new Date(currDate.toLocaleString("en-US", { timeZone: "Asia/Jakarta" }))

  const initItem = async () => {
    if (visible) {
      console.log(props, "props");
      if (props.duration) {
        setExam(props.duration);

        // console.log("duration", exam)
      }

      if (props.item) {
        // console.log("props.item", props.item)
        setName(props.item.judulUjian);
        try {
          let res = await service.getSoalUjian(
            props.item.id,
            "Multiple Choice"
          );

          let { data, status } = res;
          data.map((d) => {
            d.jawabanUjian = d.jawabanUjian ? d.jawabanUjian : "";
            d.options = [];
            d.options.push(
              {
                id: "A",
                title: d.jawabanA,
              },
              {
                id: "B",
                title: d.jawabanB,
              },
              {
                id: "C",
                title: d.jawabanC,
              },
              {
                id: "D",
                title: d.jawabanD,
              }
            );
          });
          setItem(data);
        } catch (err) {
          iziToast.error({
            message: err.message,
          });
        }

        // try {
        //   let res = await service.getUjianById(props.item.id);
        //   let { data } = res;
        //   // console.log(data)
        //   setExam(data);
        // } catch (err) {
        //   iziToast.error({
        //     message: err.message,
        //   });
        // }
      }
    } else {
    }
    setReady(visible);
  };

  useEffect(() => {
    initItem();
  }, [visible]);

  const closeForm = () => {
    setItem([]);
    onRequestClose();
  };

  // setInterval(() => {
  
  //   if (
  //     moment(exam.tanggalMulaiUjian).diff(
  //       zonedTime
  //     ) < 0 &&
  //     moment(exam.tanggalAkhirUjian).diff(
  //       zonedTime
  //     ) > 0
  //     && exam.dikerjakan != 3
  //   ) {
  //     console.log("SAAT INI : " +  zonedTime);
  //     console.log("TANGGAL MULAI : " +  moment(exam.tanggalMulaiUjian).diff(zonedTime));
  //     console.log("TANGGAL AKHIR : " +  moment(exam.tanggalAkhirUjian).diff(zonedTime));
  //     autoSubmit();
  //     // clearInterval(interval);
  //   }
  // }, [1000]);

  useEffect(() => {
    if(visible){
      if(props?.item?.tanggalAkhirUjian){
        // console.log(calculateTimeLeft());
        // console.log(props.item.dikerjakan);
        if (calculateTimeLeft() > 0 && props.item.dikerjakan != 3) {
          const timerId = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
            console.log("SEDANG BERJALAN")
          }, 1000);
          return () => clearInterval(timerId);
        } else if(props.item.dikerjakan != 3) {
          console.log("SUBMIT OROMATIS")
          submit();
        }
        else {
          console.log("SUDAH SELESAI , CUMA VIEW")
        }
      }
    //   let interval = setInterval(() => {}, [1000]);

    //   console.log("SAAT INI : " +  zonedTime);
    //   console.log("TANGGAL MULAI : " +  moment(exam.tanggalMulaiUjian).diff(zonedTime));
    //   console.log("TANGGAL AKHIR : " +  moment(exam.tanggalAkhirUjian).diff(zonedTime));

    //   return () => {
    //     if (exam.tanggalAkhirUjian) {
    //       console.log(
    //         moment(exam.tanggalMulaiUjian).diff(
    //           zonedTime
    //         )
    //       );
    //       // console.log(moment(exam.tanggalAkhirUjian).diff(new Date()));
    //       // if (
    //       //   moment(exam.tanggalAkhirUjian).diff(
    //       //     new Date(
    //       //       zonedTime
    //       //     )
    //       //   ) < -1000 &&
    //       //   exam.dikerjakan != 3
    //       // ) {
    //       //   submit();
    //       //   clearInterval(interval);
    //       // }
    //       if (
    //         moment(exam.tanggalAkhirUjian).diff(
    //           new Date(
    //             zonedTime
    //           )
    //         ) <= 300000 &&
    //         moment(exam.tanggalAkhirUjian).diff(
    //           new Date(
    //             zonedTime
    //           )
    //         ) >= 295000
    //       ) {
    //         alert(t.translate("message.remainingTime") + "5 menit");
    //       }
    //     }
    //   };
    }
    setReady(visible);
  }, [visible, timeLeft]);

  const autoSubmit = async () => {
    try {
      if (props.item) {
        let res = await service.answerSubmit(props.item.id, item, false);
        iziToast.success({
          message: "Sukses Simpan Jawaban",
        });
      }
    } catch (err) {}
  };

  const submit = async () => {
    try {
      if (props.item) {
        console.log(props.item);
        console.log(item);
        let res = await service.answerSubmit(props.item.id, item, true);
        TableWrapper.updateDataItem(props.tableId, { id: props.item.id }, item);
        TableWrapper.reload(props.tableId);
        iziToast.success({
          message: "success",
        });

        onRequestClose();
      }
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };
  const changeAnswer = (e, idx) => {
    let temp = [...item];
    temp[idx].jawabanUjian = e.target.value;

    setItem(temp);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const selectOption = (e, idx, items) => {
    let temp = [...item];
    temp[idx].jawabanUjian = items;
    setItem(temp);
  };
  return (
    ready && (
      <Dialog
        visible={visible}
        disableEscapeClose
        onRequestClose={() => closeForm()}
        style={{
          width: "1100px",
          height: "95vh",
          borderRadius: "5px"
        }}
        modal={true}
      >
        <DialogHeader style={{ paddingBottom: "0px" }}>
          {/* {JSON.stringify(exam)} */}
          {calculateTimeLeft() > 0 ? (
            <div style={{ width: "100%" }}>
                <div style={{ color: "blue", fontWeight: "bold", fontSize: "25px", width: "100%" }}>{name?.toUpperCase()}</div>
                <div style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
                  SISA WAKTU : {formatTime(calculateTimeLeft())}
                </div>
                <hr></hr>
            </div>
            
          ) : (
            <div style={{ width: "100%" }}>
                <div style={{ color: "blue", fontWeight: "bold", fontSize: "25px", width: "100%" }}>{name?.toUpperCase()}</div>
                <div style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
                  SISA WAKTU : 00:00:00
                </div>
                <hr></hr>
            </div>
          )}
        </DialogHeader>
        <DialogContent style={{ paddingTop: "5px" }}>
          {/* <Text
            style={{
              textAlign: "center",
              fontSize: "35px",
            }}
          >
            {name}
            <hr></hr>
          </Text> */}

          {item.map((d, i) => (
            <>
              <Card
                style={{
                  width: "100%",
                  // marginTop: "5px",
                  marginBottom: "10px",
                  background: "#f2f2f2",
                  padding: "10px 20px"
                }}
              >
                <Text style={{ fontSize: "20px", textAlign: "justify" }}>
                  <b>{i+1}. {d.soalPertanyaan}</b>
                  {d.options.map((item, j) => (
                    <Radio
                      id={`multipleChoice${d.id}`}
                      name={`multipleChoice${d.id}`}
                      label={item.title}
                      value={item.title}
                      onChange={(e) => selectOption(e, i, item.title)}
                      checked={item.title == d.jawabanUjian}
                      disabled={
                        calculateTimeLeft() <= 0
                      }
                    />
                  ))}
                </Text>
              </Card>
            </>
          ))}
          {/* </ol> */}
        </DialogContent>

        <DialogFooter style={{ paddingTop: "10px", paddingBottom: "10px"}}>
          <Button
            theme="secondary"
            themeType="outline"
            style={{ margin: "5px" }}
            onClick={() => {
              onRequestClose();
            }}
          >
            <TextIconSpacing
              icon={<FontIcon iconClassName="mdi mdi-close" />}
            >
              BATAL
            </TextIconSpacing>
          </Button>
          <Button
            id="dialog-next"
            theme="primary"
            themeType={calculateTimeLeft() <= 0 ? "outline" : "contained"}
            style={{ margin: "5px", marginLeft: "5px" }}
            onClick={(e) => autoSubmit()}
            disabled={
              calculateTimeLeft() <= 0
            }
          >
             <TextIconSpacing
              icon={<FontIcon iconClassName="mdi mdi-content-save" />}
            >
              SIMPAN JAWABAN
            </TextIconSpacing>
          </Button>
          <Button
            id="dialog-next"
            theme="primary"
            themeType={calculateTimeLeft() <= 0 ? "outline" : "contained"}
            style={{ margin: "5px", marginLeft: "5px", marginRight: "12px" }}
            onClick={(e) => submit()}
            disabled={
              calculateTimeLeft() <= 0
            }
          >
            <TextIconSpacing
              icon={<FontIcon iconClassName="mdi mdi-check-all" />}
            >
              SUBMIT JAWABAN
            </TextIconSpacing>
          </Button>
        </DialogFooter>
      </Dialog>
    )
  );
};

export default ViewSoalUjianpg;
