import React, { useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "../../../services/assignUserLevel.service";
import Confirm from "../../Confrimation/Confirm";
import moment from "moment";
import CreateAssignLevel from "./CreateAssignLevel";
import UploadSertifikat from "./UploadSertifikat";
import FileSaver from "file-saver";
import { http } from "../../../libs/react-mpk/services";
import iziToast from "izitoast";
import { inject, observer } from "mobx-react";
import ImportAssignUser from "../ImportAssignUser";
import { Checkbox, Select, TableCell, TableHeader, TableRow } from "react-md";

const ViewAssignLevel = ({
  className = "",
  showCommandbar = true,
  modalStore,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setEditShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  let [search, setSearch] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [showUpload, setUpload] = useState(false);
  const [showGenerate, setGenerate] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [selectedItemFile, setSelectedItemFile] = useState(false);
  const [showImport, setShowImport] = useState(false);

  const [checkAll, setCheckAll] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [activatedList, setActivatedList] = useState([]);
  const [isDefault, setIsDefault] = useState(false)
  let companyId = props.match.params.companyId;
  let role = localStorage.getItem("getRole");

  const showDeleteItem = (data) => {
    setShowDelete(true);
    setSelectedItem(data);
  };
  const showActiveItem = (data) => {
    setShowActive(true);
    setSelectedItem(data);
  };

  const deleteItem = (item) => {
    service.deleteById(item.id).then((res) => {
      TableWrapper.updateDataItem(
        "view-assign-user-level",
        { id: item.id },
        item
      );
      TableWrapper.reload("view-assign-user-level");
    });
  };

  const deActivateaccount = (item) => {
    service.activation(item.id).then((res) => {
      TableWrapper.updateDataItem(
        "view-assign-user-level",
        { id: item.id },
        item
      );
      TableWrapper.reload("view-assign-user-level");
    });
  };

  const getFile = async (item, callback) => {
    try {
      let options = Object.assign({
        fileType: null,
        fileName: `${item.username}_${item.namaLevel}_${item.namaProduk}`,
      });

      let config = {
        params: {
          isDefault: item.isDefault,
        },
      };
      await http.download(
        `/user_level_produk/generate_sertifikat/${item.id}?isDefault=${item.isDefault}`,
        {},
        config,
        options
      );

      TableWrapper.updateDataItem(
        "view-assign-user-level",
        { id: item.id },
        item
      );
      TableWrapper.reload("view-assign-user-level");

      callback("success", false);
    } catch (err) {
      const msg = JSON.parse(new TextDecoder().decode(err.response.data));
        console.log(msg)
      callback(msg.message, true, false);
    }
  };

  const downloadCertificate = async (item, callback) => {
    try {
      let res = await service.getFileName(item.userId, item.levelProdukId);
      let { data } = res;
      // console.log(data);

      let res1 = await service.generateSertifikasiLulus(
        item.userId,
        item.levelProdukId
      );
      let type = res1.headers["content-type"].substring(
        0,
        res1.headers["content-type"].indexOf(";")
      );
      const blob = new Blob([res1.data], { type: type });
      FileSaver.saveAs(blob, data);
    } catch (err) {
      // console.log(err);
      // var arr = new Uint8Array(err.message);
      // var str = String.fromCharCode.apply(String, arr);
      // let msg = JSON.parse(str);
      callback(err.message, true);
    }
  };

  const downloadCertificateHandler = (item) => {
    modalStore.showConfirm({
      title: t.translate("form.downloadConfirmation"),
      children: t.translate("message.download"),
      onSubmit: (callback) => {
        downloadCertificate(item, callback);
        callback();
      },
    });
  };

  const exportUserLevel = async (callback) => {
    try {
      let res = await service.exportData(companyId);
      callback("success", false);
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  const exportHandler = () => {
    modalStore.showConfirm({
      title: t.translate("form.exportAssignLevel"),
      children: t.translate("message.exportData"),
      onSubmit: (callback) => {
        exportUserLevel(callback);
        callback();
      },
    });
  };

  const addItem = (e, item) => {
    item.isRilis = !item.isRilis;
    let temp = [];

    if (!item.isRilis) {
      setCheckAll(true);
    }

    itemList.map((d) => {
      if (d.isRilis) {
        temp.push(d);
      }
    });

    if (temp.length == itemList.length) {
      setCheckAll(false);
    }

    // setActivatedList([...activatedList, item]);
    setActivatedList(temp);
  };

  const addAllItem = (e) => {
    setCheckAll(!checkAll);
    let item = [];
    if (checkAll) {
      itemList.map((d) => {
        d.isRilis = true;
        item.push(d);
      });
      setActivatedList(item);
    } else {
      itemList.map((d) => {
        d.isRilis = false;
      });
      setActivatedList(itemList);
    }
  };

  const generateCertificateList = async (activatedList, callback) => {
    try {
      let res = await service.generateMultiCertificate(activatedList, isDefault);

      let { data, headers } = res;

      let fileName = "certificate.zip";
      let type = headers["content-type"];

      const blob = new Blob([data], { type: type });

      activatedList.map((d) => {});

      TableWrapper.reload("view-assign-user-level");

      FileSaver.saveAs(blob, fileName);
      callback("success", false);
      // let { data, status } = res;

      // if (status == 200) {
      // }
    } catch (err) {
      var arr = new Uint8Array(err.message);
      var str = String.fromCharCode.apply(String, arr);
      let msg = JSON.parse(str);
      callback(msg.message, true);
    }
  };

  const downloadCertificateList = async (activatedList, callback) => {
    try {
      let res = await service.downloadMultiCertificate(activatedList);

      let { data, headers } = res;
      console.log(data);

      let fileName = "certificate.zip";
      let type = headers["content-type"];

      const blob = new Blob([data], { type: type });
      FileSaver.saveAs(blob, fileName);
      callback("success", false);
      // let { data, status } = res;

      // if (status == 200) {
      // }
    } catch (err) {
      var arr = new Uint8Array(err.message);
      var str = String.fromCharCode.apply(String, arr);
      let msg = JSON.parse(str);
      callback(msg.message, true);
    }
  };

  const generateCertificateListConfirm = () => {
    modalStore.showConfirm({
      title: t.translate("form.certificateGenerateConfirmation"),
      children: (
        <>
          <Checkbox
            name="isDefault"
            value={isDefault}
            label="Gunakan Sertifikat Default"
            onChange={(e) => setIsDefault(e.target.checked)}
          />
        </>
      ),
      onSubmit: (callback) => {
        generateCertificateList(activatedList, callback);
        callback();
      },
    });
  };

  const downloadCertificateListConfirm = () => {
    modalStore.showConfirm({
      title: t.translate("form.downloadConfirmation"),
      children: t.translate("message.download"),
      onSubmit: (callback) => {
        downloadCertificateList(activatedList, callback);
        callback();
      },
    });
  };

  const generateCertificateHandler = (item) => {
    item.isDefault = false
    modalStore.showConfirm({
      title: t.translate("form.certificateGenerateConfirmation"),
      children: (
        <>
          <Checkbox
            name="isDefault"
            value={item.isDefault}
            label="Gunakan Sertifikat Default"
            onChange={(e) => item.isDefault = e.target.checked}
          />
        </>
      ),
      onSubmit: (callback) => {
        getFile(item, callback);
      },
    });
  };
  return (
    <>
      <TableWrapper
        baseId="view-assign-user-level"
        title={t.translate("modules.table.assign-user-level")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        // multiColumnFilter={true}
        columns={[
          {
            label: (
              <Checkbox
                label={t.translate("table.certificateStatus")}
                value={checkAll}
                checked={!checkAll}
                onChange={(e) => addAllItem(e)}
              />
            ),
            searchable: false,
            key: "checkbox",
            render: (item) => (
              <Checkbox
                label={
                  <TableWrapper.Status
                    type={
                      item.fileSertifikat
                        ? TableWrapper.Status.types.SUCCESS
                        : TableWrapper.Status.types.ERROR
                    }
                  >
                    {item.fileSertifikat
                      ? t.translate("table.generated")
                      : t.translate("table.unGenerated")}
                  </TableWrapper.Status>
                }
                value={item}
                checked={item.isRilis}
                onChange={(e) => addItem(e, item)}
              />
            ),
          },
          {
            label: t.translate("table.uploadStatus"),
            searchable: false,
            key: "fileFinalSertifikat",
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.fileFinalSertifikat
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.fileFinalSertifikat
                  ? t.translate("table.uploaded")
                  : t.translate("table.unUploaded")}
              </TableWrapper.Status>
            ),
          },
          {
            label: t.translate("table.username"),
            searchable: true,
            key: "username",
            render: (item) => item.username,
          },
          {
            label: t.translate("table.nama"),
            searchable: true,
            key: "nama",
            render: (item) => item.nama,
          },
          {
            label: t.translate("table.namaLevel"),
            searchable: true,
            key: "namaLevel",
            render: (item) => item.namaLevel,
          },
          {
            label: t.translate("table.namaProduk"),
            searchable: true,
            key: "namaProduk",
            render: (item) => item.namaProduk,
          },
          {
            label: t.translate("table.dibuatOleh"),
            searchable: true,
            key: "dibuatOleh",
            render: (item) => item.dibuatOleh,
          },
          {
            label: t.translate("table.tglDibuat"),
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.tanggalDibuat).format("lll"),
          },
          {
            label: t.translate("table.diperbaharui"),
            key: "diperbaharuiOleh",
            render: (item) => item.diperbaharuiOleh,
          },
          {
            label: t.translate("table.tglDiperbaharui"),
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.tanggalDiperbaharui).format("lll"),
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            role.includes("ADMIN")
          ),
          new TableWrapper.action(
            "Import",
            "mdi mdi-upload",
            () => {
              setSelectedItem(null);
              setShowImport(true);
            },
            role.includes("ADMIN")
          ),
          new TableWrapper.action(
            "Export",
            "mdi mdi-download",
            () => {
              exportHandler();
            },
            role.includes("ADMIN")
          ),
          new TableWrapper.action(
            "Generate Certificate",
            "mdi mdi-file-cog-outline",
            () => {
              setSelectedItem(null);
              generateCertificateListConfirm();
            }
          ),
          new TableWrapper.action(
            "Download Certificate",
            "mdi mdi-file-download-outline",
            () => {
              setSelectedItem(null);
              downloadCertificateListConfirm();
            }
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setEditShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              showDeleteItem(item);
            },
            true
          ),
          new TableWrapper.action(
            "Generate Sertifikat",
            "mdi mdi-cog",
            (item) => {
              generateCertificateHandler(item);
            },
            true
          ),
          new TableWrapper.action(
            "Download Sertifikat",
            "mdi mdi-download",
            (item) => {
              downloadCertificateHandler(item);
            },
            true
          ),
          new TableWrapper.action(
            "Upload Sertifikat",
            "mdi mdi-upload",
            (item) => {
              setUpload(true);
              setSelectedItem(item);
            },
            true
          ),
          new TableWrapper.action(
            "Activation Account",
            "mdi mdi-account",
            (item) => {
              showActiveItem(item);
            },
            true
          ),
        ]}
        onFetchData={(query) => {
          let item = [];
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                "kodePerusahaan.equals": companyId,
                ...query,
              };
              let getPage = await service.getAssignLevel(query);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];

              getPage.data.map((d) => {
                d.isRilis = false;
                if (d.fileSertifikat) {
                  d.isRilis = true;
                  item.push(d);
                }
              });

              if (item.length == getPage.data.length) {
                setCheckAll(false);
              } else {
                setCheckAll(true);
              }

              setItemList(getPage.data);
              // console.log(getPage)
              resolve(getPage);
            } catch (e) {
              reject(e);
              // ErrorService(e)
            }
          });
        }}
        // onFetchData={ async query => {
        //   return service.getData()
        // }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <Confirm
        visible={showDelete}
        onRequestClose={() => {
          setShowDelete(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          deleteItem(selectedItem);
          setShowDelete(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.levelDelete")}
      />
      <Confirm
        visible={showActive}
        onRequestClose={() => {
          setShowActive(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          deActivateaccount(selectedItem);
          setShowActive(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.activation")}
      />
      <CreateAssignLevel
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />

      <CreateAssignLevel
        visible={showEditForm}
        onRequestClose={() => {
          setEditShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />

      <UploadSertifikat
        visible={showUpload}
        onRequestClose={() => {
          setUpload(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

      {/* <Confirm
        visible={showGenerate}
        onRequestClose={() => {
          setGenerate(false);
          setSelectedItemFile(null);
        }}
        onSubmitRequest={() => {
          getFile(selectedItemFile);
          setGenerate(false);
          setSelectedItemFile(null);
        }}
        message={t.translate("message.materiFile")}
      /> */}
      {/* <Confirm
                visible={showDownload}
                onRequestClose={() => {
                    setDownload(false);
                    setSelectedItemFile(null);
                }}
                onSubmitRequest={() => {
                    viewSertifikat(selectedItemFile);
                    setDownload(false);
                    setSelectedItemFile(null);
                }}
                message={t.translate("message.materiFile")}
            /> */}

      <ImportAssignUser
        visible={showImport}
        onRequestClose={() => {
          setShowImport(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        type={"assignLevel"}
        {...props}
      />
    </>
  );
};

export default inject("modalStore")(observer(ViewAssignLevel));
