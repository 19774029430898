import React from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import service from '../sample/sample.service'

const cities = [
  { id: 1, name: 'Jakarta' },
  { id: 2, name: 'Bandung' },
  { id: 3, name: 'Semarang' },
  { id: 4, name: 'Yogyakarta' },
  { id: 5, name: 'Surabaya' }
]

const onSubmit = async () => {
  try {
    const resp = await service.create()
    console.log(resp)
  } catch (error) {
    console.log(error.message)
  }
}

const FormSample = ({ envStore }) => {
  const { inputTypes, definition } = FormWrapper
  return (
    <FormWrapper
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/${envStore.env.applicationType}/table`}
      baseId="mod-form-sample"
      title={t.translate('modules.form.title')}
      hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={{
        name: 'Test Nama',
        email: 'test@mail.com',
        password: '000000',
        confirmPassword: '000000',
        cityName: ''
      }}
      definitions={[
        // =====
        // CONTOH MENGGUNAKAN CLASS FORM DEFINITION
        new definition(inputTypes.INPUT, t.translate('column.name'), 'name', 'min:3'),
        // -----
        // =====
        // BISA JUGA MENGGUNAKAN OBJECT SECARA LANGSUNG
        {
          inputType: inputTypes.INPUT,
          label: 'Email',
          key: 'email',
          type: 'email',
          required: true,
          validation: 'email',
          onRelease: (value, data) => {
            data.name = value
            return data
          }
        },
        // -----
        {
          inputType: inputTypes.INPUT,
          className: 'mpk-padding-N padding-right',
          label: 'Password',
          key: 'password',
          type: 'password',
          width: '50%',
          required: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Confirm Password',
          key: 'confirmPassword',
          type: 'password',
          width: '50%',
          required: true,
        },
        {
          inputType: inputTypes.AUTOCOMPLETE,
          label: 'City',
          key: 'cityName',
          data: cities.map(d => ({ value: d.name, children: d.name }))
        },
        {
          inputType: inputTypes.PRE_DATA
        }
      ]}
      onBeforeChange={async (key, value) => {
        await new Promise((resolve) => {
          setTimeout(resolve, 2000)
        })
        return value
      }}
      onChange={(data, key, value) => {
        if (key === 'cityName') {
          let city = find(cities, { name: value })
          if (city) data.cityId = city.id
        }
        return data
      }}
      onSubmit={(data, callback) => {
        console.log(data);
        onSubmit()
        setTimeout(callback, 2000)
      }}
    />
  )
}

export default inject('envStore')(observer(FormSample))
