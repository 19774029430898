import { http, crud } from "../libs/react-mpk/services";

let serviceUrl = "/grup";
let service = new crud(`${serviceUrl}`);

service.upload = async (file, data) => {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    // params: params
  };
  return http.upload(`${serviceUrl}`, file, data, config, {});
};

service.update = async (id, file, data) => {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    // params: params
  };
  return http.upload(`${serviceUrl}/${id}`, file, data, config, {});
};

service.getLogo = async (id) => {
  let config  ={
    responseType: 'arraybuffer',
  }

  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/logo/${id}`,
    config
  })
}

// service.getLogo = async()

export default service;
