import React, { Children, useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { PageWrapper } from "../../libs/react-mpk/wrapper";
import { sso } from "../../libs/react-mpk/hocs";
import ViewRole from "../../modules/role/ViewRole";
import ViewLevel from "../../modules/level/ViewLevel";
import ViewProduct from "../../modules/product/ViewProduct";
import ViewTypeSoal from "../../modules/Typesoal/ViewTypeSoal";
import ContainerSoal from "../../modules/Soal/ContainerSoal";
import ViewMateri from "../../modules/Materi_Pembelajaran/ViewMateri";
import service from "../../services/assignUser.service";
import ContainerLatihan from "../../modules/Latihan/containerLatihan";
import ViewKelas from "../../modules/Kelas/ViewKelas";
import ContainerUser from "../../modules/Assign_User/ContainerUser";
import ViewUserLatihan from "../../modules/UserLatihan/ViewUserLatihan";
import ViewUserUjian from "../../modules/UserUjian/ViewUserUjian";
import ContainerUjian from "../../modules/Ujian/ContainerUjian";
import ViewNilai from "../../modules/Nilai/ViewNilai";
import ContainerNilai from "../../modules/Nilai/containerNilai";
import ViewLevelProduks from "../../modules/levelProduk/ViewLevelProduks";
import PageMateri from "../menu/level/pageMateri";
import PageMateriPembelajaran from "../menu/level/pageMateriPembelajaran";
import { FontIcon, List, ListItem } from "react-md";
import Dashboard from "../../modules/Dashboard/Dashboard";
import ViewGrade from "../../modules/Grade/ViewGrade";
import RemedialContainer from "../../modules/Remedial/RemedialContainer";
import { role as roles } from "../../constant/appConstant";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

const LevelKelas = ({ history, ...props }) => {
  let role = localStorage.getItem("getRole");
  // let role = "ROLE_ADMIN"
  let companyId = props.match.params.companyId;
  let levelId = props.match.params.levelId;
  let productId = props.match.params.productId;
  let kelasId = props.match.params.kelasId;

  let chooseMenu;

  if (role === "ROLE_ADMIN") {
    chooseMenu = [
      {
        label: "Dashboard",
        children: [
          {
            label: "Dashboard",
            index: "dasbor, dashboard",
            resourceUri: "",
            iconClassName: "mdi mdi mdi-view-dashboard",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/dashboard`,
            childPath: [],
          },
        ],
      },
      {
        label: "Ujian",
        children: [
          {
            label: "Ujian",
            index: "ujian, ujian",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/ujian`,
            childPath: [],
          },
        ],
      },
      {
        label: "Latihan",
        children: [
          {
            label: "Latihan",
            index: "latihan, latihan",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/latihan`,
            childPath: [],
          },
        ],
      },
      {
        label: "Remedial",
        children: [
          {
            label: "Remedial",
            index: "Remedial, remedial",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/remedial`,
            childPath: [],
          },
        ],
      },
      {
        label: "Daftar Nilai",
        children: [
          {
            label: "Nilai",
            index: "nilai, nilai",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/nilai`,
            childPath: [],
          },
        ],
      },
      {
        label: "Assign Nilai",
        children: [
          {
            label: "Assign Nilai",
            index: "assignNilai, assignNilai",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/assign-nilai`,
            childPath: [],
          },
        ],
      },
    ];
  } else if (role === "ROLE_PENGAJAR") {
    chooseMenu = [
      {
        label: "Dashboard",
        children: [
          {
            label: "Dashboard",
            index: "dasbor, dashboard",
            resourceUri: "",
            iconClassName: "mdi mdi mdi-view-dashboard",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/dashboard`,
            childPath: [],
          },
        ],
      },
      {
        label: "Materi Pembelajaran",
        children: [
          {
            label: "Materi Pembelajaran",
            index: "materi-pembelajaran, materi-pembelajaran",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/materi-pembelajaran`,
            childPath: [],
          },
        ],
      },
      {
        label: "Ujian",
        children: [
          {
            label: "Ujian",
            index: "ujian, ujian",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/ujian`,
            childPath: [],
          },
        ],
      },
      {
        label: "Latihan",
        children: [
          {
            label: "Latihan",
            index: "latihan, latihan",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/latihan`,
            childPath: [],
          },
        ],
      },
      {
        label: "Remedial",
        children: [
          {
            label: "Remedial",
            index: "Remedial, remedial",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/remedial`,
            childPath: [],
          },
        ],
      },
      {
        label: "Daftar Nilai",
        children: [
          {
            label: "Nilai",
            index: "nilai, nilai",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/nilai`,
            childPath: [],
          },
        ],
      },
      {
        label: "Assign Nilai",
        children: [
          {
            label: "Assign Nilai",
            index: "assignNilai, assignNilai",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/assign-nilai`,
            childPath: [],
          },
        ],
      },
    ];
  } else {
    chooseMenu = [
      {
        label: "Dashboard",
        children: [
          {
            label: "Dashboard",
            index: "dasbor, dashboard",
            resourceUri: "",
            iconClassName: "mdi mdi mdi-view-dashboard",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/dashboard`,
            childPath: [],
          },
        ],
      },
      {
        label: "Materi Pembelajaran",
        children: [
          {
            label: "Materi Pembelajaran",
            index: "materi-pembelajaran, materi-pembelajaran",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/materi-pembelajaran`,
            childPath: [],
          },
        ],
      },
      {
        label: "Ujian",
        children: [
          {
            label: "Ujian",
            index: "ujian, ujian",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/ujian`,
            childPath: [],
          },
        ],
      },
      {
        label: "Latihan",
        children: [
          {
            label: "Latihan",
            index: "latihan, latihan",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/latihan`,
            childPath: [],
          },
        ],
      },
      {
        label: "Remedial",
        children: [
          {
            label: "Remedial",
            index: "Remedial, remedial",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/remedial`,
            childPath: [],
          },
        ],
      },
      {
        label: "Nilai",
        children: [
          {
            label: "Nilai",
            index: "nilai, nilai",
            resourceUri: "",
            iconClassName: "mdi mdi-table",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/nilai`,
            childPath: [],
          },
        ],
      },
    ];
  }
  return (
    <PageWrapper
      sidebarCollapsible={true}
      inverseTheme={true}
      // useSidebar={false}
      sidebarMenu={chooseMenu}
      sidebarHeader={
        <>
          <List
            style={{
              width: "100%",
              background: "white",
              padding: "0px",
              height: "65px",
            }}
          >
            <ListItem
              style={{ marginTop: "-3px" }}
              secondaryText={`Kembali`}
              rightAddon={
                <FontIcon
                  onClick={() => {
                    window.location.href = `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class`;
                  }}
                  iconClassName="mdi mdi-arrow-left"
                ></FontIcon>
              }
            ></ListItem>
          </List>
        </>
      }
    >
      <Router history={history}>
        <Switch>
          {/* dashboard */}
          {role === roles.ROLE_ADMIN || role === roles.ROLE_PENGAJAR || role === roles.ROLE_PESERTA ? (
            <Route
              path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/dashboard"
              render={(props) => <Dashboard {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/dashboard">
               <Redirect to={"/error"} />
            </Route>
          )}

          {/* Materi */}
          {role === roles.ROLE_PENGAJAR || role === roles.ROLE_PESERTA ? (
            <Route
              path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/materi-pembelajaran"
              exact={true}
              render={(props) => <ViewMateri {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/materi-pembelajaran">
               <Redirect to={"/error"} />
            </Route>
          )}

          {role === roles.ROLE_ADMIN || role === roles.ROLE_PENGAJAR || role === roles.ROLE_PESERTA ? (
            <Route
              path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/ujian"
              exact={true}
              render={(props) => <ContainerUjian {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/ujian">
               <Redirect to={"/error"} />
            </Route>
          )}

          {role === roles.ROLE_ADMIN || role === roles.ROLE_PENGAJAR || role === roles.ROLE_PESERTA ? (
            <Route
              path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/nilai"
              exact={true}
              render={(props) => <ContainerNilai {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/nilai">
               <Redirect to={"/error"} />
            </Route>
          )}

          {/* Assign Nilai */}
          {role === roles.ROLE_ADMIN || role === roles.ROLE_PENGAJAR ? (
            <Route
              path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/assign-nilai"
              exact={true}
              render={(props) => <PageMateriPembelajaran {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/assign-nilai">
               <Redirect to={"/error"} />
            </Route>
          )}

          {/* Latihan */}
          {role === roles.ROLE_ADMIN || role === roles.ROLE_PENGAJAR || role === roles.ROLE_PESERTA ? (
            <Route
              path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/latihan"
              exact={true}
              render={(props) => <ContainerLatihan {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/latihan">
               <Redirect to={"/error"} />
            </Route>
          )}
          
          {/* Lainnya */}

          {/* Assign User */}
          <Route
            path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/assign-user"
            exact={true}
            render={(props) => <ContainerUser {...props} />}
          />

          {/* User Latihan */}
          <Route
            path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/user-exercise"
            exact={true}
            render={(props) => <ViewUserLatihan {...props} />}
          />

          {/* User Ujian */}
          <Route
            path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/user-exam"
            exact={true}
            render={(props) => <ViewUserUjian {...props} />}
          />

          <Route
            path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/remedial"
            exact={true}
            render={(props) => <RemedialContainer {...props} />}
          />
        </Switch>
      </Router>
    </PageWrapper>
  );
};

export default sso({
  basePath: "/product/company/:companyId",
  url: {
    me: `/api/sso/company/:companyId/me`,
  },
})(LevelKelas);
