import iziToast from "izitoast";
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  Button,
  Text,
  Card,
  typography,
  TextArea,
  DialogHeader,
  FileInput,
} from "react-md";
import service from "../../../services/ujian.service";
import t from "counterpart";
import ExamTimer from "../ExamTimer";
import moment from "moment";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import soalService from "../../../services/soal.service";

const ViewSoalUjianEssay = ({
  visible = false,
  onRequestClose = () => {},
  onSubmitRequest = () => {},
  message = "",
  ...props
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  let [questionList, setQuestionList] = useState([]);
  let [exam, setExam] = useState({});
  let [showTimer, setShowTimer] = useState(false);
  const [name, setName] = useState("");
  const [itemFile, setItemFile] = useState(null);
  const currDate = new Date();
  const zonedTime = new Date(currDate.toLocaleString("en-US", { timeZone: "Asia/Jakarta" }))

  const next = () => {
    if (questionList.length - 1 === currentIndex) return;
    setCurrentIndex(currentIndex + 1);
  };

  const previous = () => {
    if (currentIndex === 0) return;
    setCurrentIndex(currentIndex - 1);
  };

  useEffect(() => {}, [exam]);

  const initData = async () => {
    if (visible) {
      if (props.duration) {
        // console.log("props",props.duration)
        setExam(props.duration);
      }

      if (props.item) {
        // console.log("props", props.item)
        setName(props.item.judulUjian);
        try {
          let res = await service.getSoalUjian(props.item.id, "Essay");

          let { data, status } = res;

          let item = [];
          data.map((d) => {
            d.jawabanUjian = d.jawabanUjian ? d.jawabanUjian : "";

            if (d.isAktif) {
              item.push(d);
            }
          });

          console.log(item);

          setQuestionList(item);
        } catch (err) {
          iziToast.error({
            message: err.message,
          });
        }

        try {
          let res = await service.getUjianById(props.item.id);
          let { data } = res;
          // console.log(data)
          setExam(data);
        } catch (err) {
          iziToast.error({
            message: err.message,
          });
        }
      }
    }
  };

  useEffect(() => {
    initData();
  }, [visible]);

  setInterval(() => {
    if (
      moment(exam.tanggalMulaiUjian).diff(zonedTime) < 0 &&
      moment(exam.tanggalAkhirUjian).diff(zonedTime) > 0
    ) {
      autoSubmit()
    }
  }, [60000]);

  useEffect(() => {
    let interval = setInterval(() => {}, [1000]);

    return () => {
      if (exam.tanggalAkhirUjian) {
        // console.log(moment(exam.tanggalAkhirUjian).diff(zonedTime));
        if (
          moment(exam.tanggalAkhirUjian).diff(zonedTime) < -1000 &&
          exam.dikerjakan != 3
        ) {
          submit();
          clearInterval(interval);
        }
        if (
          moment(exam.tanggalAkhirUjian).diff(zonedTime) <= 300000 &&
          moment(exam.tanggalAkhirUjian).diff(zonedTime) >= 295000
        ) {
          alert(t.translate("message.remainingTime") + "5 menit");
        }
      }
    };
  });

  const changeAnswer = (e, idx) => {
    let temp = [...questionList];
    temp[idx].jawabanUjian = e.target.value;

    setQuestionList(temp);
  };

  const downloadQuestionHandler = async (e) => {
    let item = questionList[currentIndex];

    try {
      let res = await soalService.downloadSoal(item.soalId, item.filename);
      iziToast.success({
        message: "success",
      });
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  const changeFile = async (e) => {
    const [file] = Array.from(e.currentTarget.files || [null]);
    if (file) {
      setItemFile(file);
    }

    let item = questionList[currentIndex];

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      let res = await service.uploadJawaban(
        `/ujian/uploadjawaban/${exam.id}/${item.soalId}`,
        // filePdf,
        file,
        {},
        config
      );
      let { data, status } = res;
      if (status == 200) {
        TableWrapper.updateDataItem(props.tableId, { id: item.id }, item);
        TableWrapper.reload(props.tableId);
        iziToast.success({
          message: "Success",
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
      // onRequestClose();
    }
  };

  const submit = async (e) => {
    // e.preventDefault();

    try {
      let res = await service.answerSubmit(props.item.id, questionList, true);
      let { data, status } = res;
      // console.log(status);
      // TableWrapper.updateDataItem("exam-table", { id: questionList.id }, questionList);
      TableWrapper.reload("exam-table");
      if (status == 200) {
        iziToast.success({
          message: "success",
        });
        onRequestClose();
      }
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
    // service.answerSubmit(questionList)
  };

  const autoSubmit = async () => {
    try{
      let res = await service.answerSubmit(props.item.id, questionList, false);
    }catch(err){
      
    }
  }

  return (
    <>
      <Dialog
        visible={visible}
        onRequestClose={onRequestClose}
        style={{
          width: "800px",
          height: "600px",
        }}
      >
        <DialogHeader>
          {/* {JSON.stringify(exam)} */}
          {moment(exam.tanggalMulaiUjian).diff(zonedTime) < 0 &&
            moment(exam.tanggalAkhirUjian).diff(zonedTime) > 0 ? (
              <ExamTimer
                timer={
                  moment(exam.tanggalAkhirUjian).diff(moment(zonedTime)) / 1000
                }
              />
            ) : (
              <>00:00:00</>
            )}
        </DialogHeader>

        <DialogContent>
          <Text
            style={{
              textAlign: "center",
              fontSize: "35px",
            }}
          >
            {name}
            <hr></hr>
          </Text>

          <div
            style={{
              textAlign: "center",
            }}
          >
            <div
              style={{
                justifyItems: "center",
                display: "flex",
                flexWrap: "wrap",
                backgroundColor: "whitesmoke",
              }}
            >
              {questionList.map((item, index) => (
                <Button
                  themeType="contained"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    justifyItems: "center",
                    height: 40,
                    width: 40,
                    marginRight: 2,
                    marginLeft: 2.3,
                    borderRadius: 5,
                    backgroundColor:
                      index === currentIndex
                        ? "cyan"
                        : item.jawabanUjian
                        ? "grey"
                        : "transparent",
                  }}
                  onClick={() => setCurrentIndex(index)}
                >
                  {index + 1}
                </Button>
              ))}
            </div>
          </div>

          <Card
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                fontSize: "25px",
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              {currentIndex + 1}.{" "}
              {questionList.length > 0 &&
                questionList[currentIndex].soalPertanyaan}
            </div>

            {questionList.length > 0 && !questionList[currentIndex].filename ? (
              <div
                style={{
                  width: "752px",
                  justifyItems: "center",
                  backgroundColor: "whitesmoke",
                  fontSize: "20px",
                }}
              >
                <TextArea
                  placeHolder={`${t.translate("form.jawaban")}`}
                  rows={3}
                  maxRows={3}
                  value={
                    questionList.length > 0 &&
                    questionList[currentIndex].jawabanUjian
                  }
                  onChange={(e) => changeAnswer(e, currentIndex)}
                  disabled={
                    moment(exam.tanggalMulaiUjian).diff(zonedTime) < 0 &&
                    moment(exam.tanggalAkhirUjian).diff(zonedTime) > 0
                      ? false
                      : true
                  }
                />
              </div>
            ) : (
              <>
                <div
                  style={{
                    width: "752px",
                    justifyItems: "center",
                    backgroundColor: "whitesmoke",
                    fontSize: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Button onClick={(e) => downloadQuestionHandler(e)}>
                    Download File
                  </Button>
                  <FileInput
                    id={`Excel`}
                    buttonType="text"
                    theme="primary"
                    themeType="contained"
                    onChange={(e) => changeFile(e)}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  >
                    File Excel
                  </FileInput>
                </div>
              </>
            )}
          </Card>
        </DialogContent>

        <DialogFooter>
          <div>
            <Button
              id="dialog-previous"
              theme="primary"
              themeType="contained"
              style={{ margin: "5px" }}
              onClick={() => previous()}
              disabled={currentIndex === 0 ? true : false}
            >
              Previous
            </Button>

            {questionList.length - 1 === currentIndex ? (
              <Button
                id="dialog-next"
                theme="primary"
                themeType="contained"
                style={{ margin: "5px" }}
                onClick={(e) => submit()}
                disabled={
                  moment(exam.tanggalMulaiUjian).diff(zonedTime) < 0 &&
                  moment(exam.tanggalAkhirUjian).diff(zonedTime) > 0
                    ? false
                    : true
                }
              >
                Submit
              </Button>
            ) : (
              <Button
                id="dialog-next"
                theme="primary"
                themeType="contained"
                style={{ margin: "5px" }}
                onClick={() => next()}
                disabled={
                  questionList.length - 1 === currentIndex ? true : false
                }
              >
                Next
              </Button>
            )}
          </div>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ViewSoalUjianEssay;
