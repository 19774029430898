import React from "react";
import { Button } from "react-md";
import {format} from '../../libs/react-mpk/services/number.service'
class ExamTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { time: {}, seconds: props.timer };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    this.startTimer();
  }

  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // console.log(this.state)

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }

  render() {
    return (
      <div style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
        {(this.state.seconds > 0 )&&(
          <div>
            { format(this.state.time.h)}:{format(this.state.time.m)}:{format(this.state.time.s)}
          </div>
        )}
      </div>
    );
  }
}

export default ExamTimer;
