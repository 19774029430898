import React, { Children, useEffect, useState } from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { PageWrapper } from '../../libs/react-mpk/wrapper'
import { sso } from '../../libs/react-mpk/hocs'
import ViewUserLatihan from '../../modules/UserLatihan/ViewUserLatihan'
import ViewUserUjian from '../../modules/UserUjian/ViewUserUjian'
import { FontIcon, List, ListItem } from 'react-md'
import ViewUserRemedial from '../../modules/UserRemedial/ViewUserRemedial'


const LevelMateri = ({ history, ...props }) => {
    let role = localStorage.getItem("getRole")
    let companyId = props.match.params.companyId
    let levelId = props.match.params.levelId
    let productId = props.match.params.productId
    let kelasId = props.match.params.kelasId
    let materiId = props.match.params.materiId

    let chooseMateri = [
        {
            label: "Exercise",
            children: [
                {
                    label: "Exercise",
                    index: "userExercise, userExercise",
                    resourceUri: "",
                    iconClassName: "mdi mdi-table",
                    path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/assign-nilai/${materiId}/user-exercise`,
                    childPath: []
                }
            ],
        },
        {
            label: "Exam",
            children: [
                {
                    label: "Exam",
                    index: "userExam, userExam",
                    resourceUri: "",
                    iconClassName: "mdi mdi-table",
                    path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/assign-nilai/${materiId}/user-exam`,
                    childPath: []
                }
            ],
        },
        {
            label: "Remedial",
            children: [
                {
                    label: "Remedial",
                    index: "remedial, remedial",
                    resourceUri: "",
                    iconClassName: "mdi mdi-table",
                    path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/assign-nilai/${materiId}/user-remedial`,
                    childPath: []
                }
            ],
        }
    ]
    return (
        <PageWrapper
            sidebarCollapsible={true}
            inverseTheme={true}
            // useSidebar={false}
            sidebarMenu={chooseMateri}
            sidebarHeader={(
                <>
                    <List style={{ width: '100%', background: 'white', padding: '0px', height: '65px' }}>
                        <ListItem style={{ marginTop: '-3px' }} secondaryText={`Kembali`} rightAddon={<FontIcon onClick={() => {
                            window.location.href = `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/assign-nilai`
                        }} iconClassName="mdi mdi-arrow-left"></FontIcon>}>

                        </ListItem>
                    </List>
                </>
            )}
        >
            <Router history={history}>
                <Switch>
                    {/* User Latihan */}
                    <Route
                        path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/assign-nilai/:materiId/user-exercise'
                        exact={true}
                        render={props => (
                            <ViewUserLatihan {...props} />
                        )}
                    />

                    {/* User Ujian */}
                    <Route
                        path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/assign-nilai/:materiId/user-exam'
                        exact={true}
                        render={props => (
                            <ViewUserUjian {...props} />
                        )}
                    />

                    {/* User Remedila */}
                    <Route
                        path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/assign-nilai/:materiId/user-remedial'
                        exact={true}
                        render={props => (
                            <ViewUserRemedial {...props} />
                        )}
                    />
                </Switch>
            </Router>
        </PageWrapper>
    )
}

export default sso({
    basePath: '/product/company/:companyId',
    url: {
        me: `/api/sso/company/:companyId/me`
    }
})(LevelMateri)
