import React, { useEffect, useState } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import t from "counterpart";
import iziToast from "izitoast";
import http from "../../../libs/react-mpk/services/http.service";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "react-md";

const defaultData = () => ({
    string: "",
    number: 0,
    boolean: false,
    multiCheckbox: [""],
});

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewPDF = ({
    visible = false,
    onRequestClose = () => { },
    ...props
}) => {
    let [item, setItem] = useState(null);
    let [ready, setReady] = useState(true);
    let [pdf, setPdf] = useState(null);
    let [type, setType] = useState("");

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const nextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const prevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const initItem = async () => {
        if (visible) {
            let selectedItem = defaultData();
            if (props.item) {
                try {
                    if (props.item.jawabanPdfPraktek) {
                        let config = Object.assign({
                            method: "get",
                            responseType: "blob",
                        });

                        let options = Object.assign({
                            fileType: null,
                            fileName: props.item.jawabanPdfPraktek,
                        });

                        let res = await (config.method === "post"
                            ? http.post(
                                `/ujian/praktek/${props.item.id}`,
                                item,
                                config
                            )
                            : http.get(
                                `/ujian/praktek/${props.item.id}`,
                                {
                                    file: 'pdf'
                                },
                                config,
                            ));
                        let fileName =
                            options.fileName ||
                            res.headers["content-disposition"].split("filename=")[1];
                        let type =
                            options.fileType ||
                            res.headers["content-type"].substring(
                                0,
                                res.headers["content-type"].indexOf(";")
                            );

                        let { data } = res;
                        // console.log(data)
                        // console.log(res)

                        const blob = new Blob([res.data], { type: type });

                        let uri = URL.createObjectURL(blob);
                        // let pdfWindows = window.open()
                        // pdfWindows.location.href = uri
                        setPdf(uri);
                        setType(type);
                    }
                } catch (err) {
                    iziToast.error({
                        message: err.message,
                    });
                }
            }
            setItem(selectedItem);
        } else {
            setItem(defaultData());
        }
        setReady(visible);
    };

    useEffect(() => {
        initItem();
    }, [visible]);

    return (
        ready && (
            <DataForm
                baseId="video-player-dialogue"
                title={t.translate("modules.form.viewPdf")}
                visible={visible}
                // data={item}
                onRequestClose={()=>{
                    onRequestClose()
                    setPdf(null)
                }}
                asDialog={true}
                defaultData={props.item || defaultData()}
                definitions={[
                    {
                        render: (
                            <div>
                                <div className="controls">
                                    <Button onClick={prevPage} disabled={pageNumber === 1}>
                                        Prev
                                    </Button>
                                    <Button onClick={nextPage} disabled={pageNumber === numPages}>
                                        Next
                                    </Button>
                                </div>

                                {pdf && (
                                    <Document
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        file={pdf}
                                        onContextMenu={(e) => e.preventDefault()}
                                    >
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                ) }
                                {/* <VideoPlayer
                  // src={}
                  poster={video.poster}
                  width="720"
                  height="420"
                  
                /> */}
                            </div>
                        ),
                    },
                ]}
                onBeforeChange={(key, value) => {
                    console.log(key, value);
                    return value;
                }}
                onSubmit={null}
            />
        )
    );
};

export default ViewPDF;
