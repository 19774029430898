import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import { companyGroupService } from "../../services";

const defaultData = () => ({
  companyName: "",
  companyCode: "",
  attachment: null,
});

const CompanyGroupForm = ({
  visible = false,
  onRequestClose = () => {},
  tableId = "",
  ...props
}) => {
  let [item, setItem] = useState(defaultData());
  let [ready, setReady] = useState(true);
  let [img, setImg] = useState(null);
  let [disabled, setDisabled] = useState(false);

  const initData = async () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        try {
          let res = await companyGroupService.getLogo(props.item.id);
          let { data } = res;
          const base64String = btoa(
            String.fromCharCode(...new Uint8Array(data))
          );
          setImg(`data:image/jpeg;base64, ${base64String}`);
        } catch (err) {
          console.log(err);
        }
        selectedItem = cloneDeep(props.item);
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const submitHandler = async (data, callback) => {
    setDisabled(true);
    let postData = {
      namaGrup: data.namaGrup,
      kodeGrup: data.kodeGrup,
    };
    try {
      let grup = {
        grup: JSON.stringify(postData),
      };

      if (data.id) {
        await companyGroupService.update(data.id, data.attachment, grup);
        TableWrapper.updateDataItem(tableId, { id: data.id }, data);
      } else {
        await companyGroupService.upload(data.attachment, grup);
      }

      setDisabled(false);
      TableWrapper.reload(tableId);
      setImg(null);
      callback("success", false);
    } catch (err) {
      if (item.id) {
        callback("error update", true, false);
      } else {
        callback("error create", true, false);
      }
      setDisabled(false);
    }
  };

  return (
    ready && (
      <DataForm
        baseId="company-form"
        title={t.translate("table.companyGroupForm")}
        visible={visible}
        // data={item}
        onRequestClose={() => {
          setImg(null);
          onRequestClose();
        }}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.groupName"),
            key: "namaGrup",
            validation: "required",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.groupCode"),
            key: "kodeGrup",
            validation: "required",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.FILE_INPUT,
            label: "Logo",
            key: "attachment",
            validation: item.id || !item.logoGrup ? "required" : null,
            disabled: disabled,
            accept: "image/*",
          },
          {
            render: img && (
              <>
                <img src={img} />
              </>
            ),
          },
        ]}
        onChange={(data, key, value) => {
          if (key == "attachment") {
            setImg(URL.createObjectURL(value));
          }
          return data;
        }}
        onSubmit={!disabled ? submitHandler : null}
      />
    )
  );
};

export default CompanyGroupForm;
