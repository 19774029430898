import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import service from "../../services/materi.service";
import services from "../../services/typeSoal.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import { http } from "../../libs/react-mpk/services";

const defaultData = () => ({
  id: null,
  namaMateri: null,
  metodePembelajaran: null,
  pdf: null,
  video: null,
  urlAplikasi: null
});

const typeSoal = [
  { id: "Teori", namaTipe: "Teori" },
  { id: "Praktek", namaTipe: "Praktek" },
];

const CreateMateri = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [dataSubmit, setDataSubmit] = useState({
    id: "",
    namaMateri: "",
    metodePembelajaran: "",
    video: "",
    pdf: null,
  });
  let [item, setItem] = useState(defaultData());
  let [ready, setReady] = useState(true);
  let [docType, setDocType] = useState(null);
  // let [typeSoal, setTypeSoal] = useState([]);

  useEffect(() => {
    // services.getAllTypeSoal({ size: 1000 }).then((res) => {
    //     console.log(res)
    //     setTypeSoal(res.data);
    // });
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();
        console.log(props);
        if (props.item) {
          // console.log(props.item)
          try {
            service
              .getMateriById(props.item.id)
              .then((res) => {
                console.log(res);
                setDataSubmit(res.data);
              })
              .catch((e) => {
                // console.log(e)
              });
            selectedItem = cloneDeep(props.item);
            selectedItem.number = String(selectedItem.number);
          } catch (e) {
            // onRequestClose()
          }
        }
        setItem(selectedItem);
      } else {
        setItem(defaultData());
      }
      setReady(visible);
    }
    initData();
  }, [visible]);

  const onSubmit = async (item, callback) => {
    let reqData = dataSubmit;

    if (reqData.id) {
      if (item.video || item.pdf) {
        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        let filePdf = item.pdf;
        let fileVideo = item.video;

        delete item.pdf;
        delete item.video;

        let materiPembelajaran = {
          materiPembelajaran: JSON.stringify(item),
        };

        try {
          let res = await service.uploadMateri(
            `/materipembelajaran/upload/${reqData.id}`,
            filePdf,
            fileVideo,
            materiPembelajaran,
            config
          );
          let { data } = res;

          TableWrapper.updateDataItem("view-materi", { id: item.id }, item);
          TableWrapper.reload("view-materi");
          callback("Create Success", false);
        } catch (err) {
          callback(err.message, true, false);
        }
      } else {
        try {
          let res = await service.update(item, reqData.id);

          TableWrapper.updateDataItem("view-materi", { id: item.id }, item);
          TableWrapper.reload("view-materi");
          callback("Edit Success", false);
        } catch (err) {
          callback(err.message, true, false);
        }
      }
    } else {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let filePdf = item.pdf;
      let fileVideo = item.video;
      // console.log(fileVideo)

      delete item.pdf;
      delete item.video;

      let materiPembelajaran = {
        materiPembelajaran: JSON.stringify(item),
      };

      try {
        let res = await service.uploadMateri(
          `/materipembelajaran`,
          filePdf,
          fileVideo,
          materiPembelajaran,
          config
        );
        let { data } = res;

        TableWrapper.updateDataItem("view-materi", { id: item.id }, item);
        TableWrapper.reload("view-materi");
        callback("Create Success", false);
      } catch (err) {
        callback(err.message, true, false);
      }
    }
  };

  // const onSubmit = async (data, callback) => {
  //     // console.log(data)
  //     let reqData = dataSubmit;
  //     console.log(reqData)
  //     if (reqData && reqData.data && reqData.data.id) {
  //         if (data.attachment) {
  //             try {
  //                 delete reqData.data.id;
  //                 delete reqData.data.nama_file;
  //                 let config = {
  //                     headers: {
  //                         "Content-Type": "multipart/form-data",
  //                     },
  //                 };
  //                 let attachment = data.attachment;

  //                 delete data.attachment;
  //                 let materiPembelajaran = {
  //                     materiPembelajaran: JSON.stringify(data),
  //                 };
  //                 console.log(materiPembelajaran)

  //                 http.upload(
  //                     `/materipembelajaran/upload/${data.id}`,
  //                     attachment,
  //                     materiPembelajaran,
  //                     config
  //                 ).then((res) => {
  //                     TableWrapper.updateDataItem(
  //                         "view-materi",
  //                         { id: data.id },
  //                         data
  //                     );
  //                     TableWrapper.reload("view-materi");
  //                     callback('Create Success', false)
  //                 })
  //             } catch (error) {
  //                 // console.log(error.message)
  //                 callback('Create Error ' + error.message, true)
  //             }
  //         } else {
  //             try {
  //                 http
  //                     .put(`/materipembelajaran/${data.id}`, data)
  //                     .then((res) => {
  //                         TableWrapper.updateDataItem(
  //                             "view-materi",
  //                             { id: data.id },
  //                             data
  //                         );
  //                         TableWrapper.reload("view-materi");
  //                         callback("Edit Success", false);
  //                     })
  //             } catch (error) {
  //                 // console.log(error.message)
  //                 callback('Edit Error ' + error.message, true)
  //             }
  //         }
  //     }
  //     else {
  //         try {
  //             let config = {
  //                 headers: {
  //                     "Content-Type": "multipart/form-data",
  //                 },
  //             };
  //             let attachment = data.attachment;

  //             delete data.attachment;
  //             // console.log(data)
  //             // console.log(JSON.stringify(data))
  //             // console.log(JSON.stringify(config))
  //             let materiPembelajaran = {
  //                 materiPembelajaran: JSON.stringify(data),
  //             };
  //             // console.log(materiPembelajaran)
  //             http.upload(
  //                 `/materipembelajaran`,
  //                 attachment,
  //                 materiPembelajaran,
  //                 config
  //             ).then((res) => {
  //                 TableWrapper.updateDataItem(
  //                     "view-materi",
  //                     { id: data.id },
  //                     data
  //                 );
  //                 TableWrapper.reload("view-materi");
  //                 callback('Create Success', false)
  //             })
  //             // const resp = await service.update(data, data.id)
  //             // // console.log(resp)
  //         } catch (error) {
  //             // console.log(error.message)
  //             callback('Create Error ' + error.message, true)
  //         }
  //     }
  // }

  return (
    ready && (
      <DataForm
        baseId="create-materi"
        title={item.id ? t.translate("modules.form.materi").toUpperCase() : t.translate("modules.form.materi").toUpperCase()}
        visible={visible}
        style={{width: "70vh"}}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.TEXTAREA,
            label: t.translate("table.nameMateri"),
            key: "namaMateri",
            validation: "required",
            required: true
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("table.metodePembelajaran"),
            key: "metodePembelajaran",
            options: typeSoal.map((d) => ({
              value: d.id,
              children: d.namaTipe,
            })),
            validation: "required",
            required: true
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.urlAplikasi"),
            key: "urlAplikasi",
            type: 'url'
          },
        ]}
        onChange={(data, key, value) => {
          return data;
        }}
        watchDefaultData={false}
        onSubmit={onSubmit}
        submitLabel="Simpan"
      />
    )
  );
};

export default CreateMateri;
