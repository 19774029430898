import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import service from "../../services/soal.service";
import { http } from "../../libs/react-mpk/services";

const defaultData = () => ({
  file: null,
});

const UploadExcelSoal = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  useEffect(() => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        selectedItem = cloneDeep(props.item);
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  }, [visible]);

  const onSubmit = async (item, callback) => {
    try {
      let res = await service.uploadSoal(item.id, item.file);

      let { data } = res;

      TableWrapper.updateDataItem("soal-table", { id: item.id }, item);
      TableWrapper.reload("soal-table");
      callback("success", false);
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  return (
    ready && (
      <DataForm
        baseId="upload-question"
        title={t.translate("modules.form.questionUpload")}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.FILE_INPUT,
            label: "File",
            key: "file",
            // accept:
            //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
          },
        ]}
        onChange={(data, key, value) => {
          return data;
        }}
        watchDefaultData={false}
        onSubmit={onSubmit}
      />
    )
  );
};

export default UploadExcelSoal;
