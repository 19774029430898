import iziToast from "izitoast";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  FileInput,
  FontIcon,
  Text,
  TextField,
  TextIconSpacing,
} from "react-md";
import { http } from "../../../libs/react-mpk/services";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import service from "../../../services/ujian.service";

const UploadFile = ({
  visible = false,
  onRequestClose = () => {},
  onRequestSubmit = () => {},
  ...props
}) => {
  const [ready, setReady] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [item, setItem] = useState({});
  const chunkSize = 1048576 * 2;
  const [counter, setCounter] = useState(1);
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  const [fileGuid, setFileGuid] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [progress, setProgress] = useState(0);
  const [fileToBeUpload, setFileToBeUpload] = useState({});
  const [chunkCount, setChunkCount] = useState(0);
  const [exam, setExam] = useState({});

  const [excel, setExcel] = useState({});

  const initData = async () => {
    if (visible) {
      let selectedItem = {};
      if (props.item) {
        selectedItem = cloneDeep(props.item);
      }

      if (props.exam) {
        setExam(props.exam);
      }
      setItem(selectedItem);
    } else {
      setItem({});
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  useEffect(() => {
    if (fileSize > 0) {
      uploadFile(counter);
    }
  }, [item, progress]);

  const changeExcel = (e) => {
    const [file] = Array.from(e.currentTarget.files || [null]);
    if (file) {
      setExcel(file);
    }
  };

  const getFileContext = (e) => {
    resetChunkProperties();
    const _file = e.target.files[0];
    setFileSize(_file.size);
    const _totalCount =
      _file.size % chunkSize == 0
        ? _file.size / chunkSize
        : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount);
    setFileToBeUpload(_file);
    const _fileID = _file.name;
    setFileGuid(_fileID);
  };

  const resetChunkProperties = () => {
    setCounter(1);
    setBeginingOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
  };

  const uploadFileExcel = async () => {
    if (excel) {
      setShowProgress(true)
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      try {
        let res = await service.uploadJawaban(
          `/ujian/uploadjawaban/${exam.id}/${item.soalId}`,
          // filePdf,
          excel,
          {},
          config
        );
        let { data, status } = res;
        if (status == 200) {
          // TableWrapper.updateDataItem("exam-table", { id: item.id }, item);
          // TableWrapper.reload("exam-table");
          iziToast.success({
            message: "Success",
          });
          onRequestSubmit();
          setShowProgress(false)
          onRequestClose();
        }
      } catch (err) {
        iziToast.error({
          message: err.message,
        });
        setShowProgress(false)
        // onRequestClose();
      }
    }
  };

  const uploadFile = async () => {
    setCounter(counter + 1);
    if (excel.name) {
      // console.log(excel);
      uploadFileExcel();
    }
    if (counter <= chunkCount) {
      var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      try {
        uploadChunk(chunk);
        //   callback("success", false);
      } catch (err) {
        iziToast.error({
          message: err.message,
        });
      }
    }
  };

  const uploadChunk = async (chunk) => {
    setShowProgress(true);
    try {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          namaFile: fileGuid,
          totalCounter: chunkCount,
          nowCounter: counter,
        },
      };
      const response = await http.upload(
        `/ujian/uploadjawaban/pdf/${exam.id}/${item.soalId}`,
        chunk,
        {},
        config
      );
      const data = response.data;
      //   if (data.isSuccess) {
      setBeginingOfTheChunk(endOfTheChunk);
      setEndOfTheChunk(endOfTheChunk + chunkSize);
      if (counter == chunkCount) {
        // console.log("Process is complete, counter", counter);
        // await uploadCompleted(item);

        resetData();
        iziToast.success({
          message: "Success",
        });
        onRequestSubmit();
        setShowProgress(false);
        onRequestClose();
      } else {
        var percentage = (counter / chunkCount) * 100;
        setProgress(percentage);
      }
      //   }
    } catch (error) {
      iziToast.error({
        message: error.message,
      });
      setShowProgress(false);
      resetData();
      // console.log("error", error);
    }
  };

  const resetData = () => {
    setShowProgress(false);
    // setItem({});
    setExcel({});
    setCounter(1);
    setBeginingOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
    setFileGuid("");
    setFileSize(0);
    setProgress(0);
    setFileToBeUpload({});
    setChunkCount(0);
  };

  return (
    ready && (
      <div>
        <Dialog visible={visible} onRequestClose={onRequestClose} style={{ borderRadius: "5px", width: "40vw" }}>
          <DialogHeader style={{ paddingBottom: "0px" }}>
                <div style={{ width: "100%" }}>
                  <div style={{ fontWeight: "bold", fontSize: "25px", width: "100%" }}>FORM UPLOAD JAWABAN</div>
                  <hr></hr>
                </div>
          </DialogHeader>

          <DialogContent style={{ marginBottom: "10%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FileInput
                  id={`videoFile`}
                  buttonType="text"
                  theme="primary"
                  themeType="contained"
                  onChange={(e) => getFileContext(e)}
                  accept="application/pdf"
                  required
                >
                  JAWABAN PDF
                </FileInput>

                <TextField
                  id={`videoName`}
                  value={fileGuid}
                  style={{ flexGrow: "2", marginLeft: "10px" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "5%",
                }}
              >
                <FileInput
                  id={`Excel`}
                  buttonType="text"
                  theme="primary"
                  themeType="contained"
                  onChange={(e) => changeExcel(e)}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                >
                  JAWABAN Excel
                </FileInput>

                <TextField
                  id={`videoName`}
                  value={excel.name}
                  style={{ flexGrow: "2", marginLeft: "10px" }}
                />
              </div>
            </div>
          </DialogContent>

          <DialogFooter>
            <Button
             theme="secondary"
              themeType="outline"
              onClick={() => {
                resetData();
                onRequestClose();
              }}
              disabled={showProgress}
            >
              BATAL
            </Button>

            <Button
              id={`btnExcel`}
              // style={{ marginTop: "2%" }}
              theme="primary"
              themeType="contained"
              onClick={(e) => uploadFile(e)}
              disabled={showProgress}
              style={{ marginLeft: "5px" }}
            >
              <TextIconSpacing
                // iconAfter={true}
                icon={
                  showProgress ? (
                    <CircularProgress id={`progressExcel`} centered={false} />
                  ) : <FontIcon iconClassName="mdi mdi-upload"/>
                }
              >
                Upload
              </TextIconSpacing>
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    )
  );
};

export default UploadFile;
