import { crud, http } from "../libs/react-mpk/services";

let service = null;
let serviceUrl = "/userujian";
service = new crud("/userujian");

service.getUserUjian = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.getUserUjianNilai = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/nilai/user`,
    query,
  });
};
// service.createLatihan = async (materiPembelajaranId, userId) => {
//     return http.request({
//         method: http.methods.POST,
//         url: `${serviceUrl}`,
//         config: {
//             headers: { 'content-type': 'application/json' },
//             params: {
//                 materiPembelajaranId: materiPembelajaranId,
//                 userId: userId
//             }
//         }
//     })
// }

service.getUserUjianById = async (Id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${Id}`,
  });
};

service.create = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

service.update = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

service.deleteById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

service.rilisNilai = async (data) => {
  console.log(data);
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/list`,
    data,
  });
};

service.userMateriUjian = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/nilai`,
    query,
  });
};

service.exportNilai = async (query) => {
  let config = Object.assign({
    responseType: "arraybuffer",
  });
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/export/ujian`,
    query,
    config,
  });
  // return http.request({
  //     method: http.methods.GET,
  //     url: `${serviceUrl}/export/ujian`,
  //     query
  // })
};
export default service;
