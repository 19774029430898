import { crud, http } from "../libs/react-mpk/services";

let serviceUrl = "/setting_application";
let service = new crud(serviceUrl);

service.uploadSign = async (file) => {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return http.upload(`${serviceUrl}/upload_ttd`, file, {}, config);
};

service.getSign = async () => {
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/get_ttd`,
        config:{
            responseType: 'arraybuffer',
        }
    })
}

export default service
