import React, { useEffect, useState } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import service from "../../../services//assignUserLevel.service";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import http from "../../../libs/react-mpk/services/http.service";

const defaultData = () => ({
    fileFinalSertifikat: "",
});
const UploadSertifikat = ({
    visible = false,
    onRequestClose = () => { },
    ...props
}) => {
    let [dataSubmit, setDataSubmit] = useState({
        fileFinalSertifikat: "",
    });
    let [item, setItem] = useState(null);
    let [ready, setReady] = useState(true);
    let [typeSoal, setTypeSoal] = useState([]);
    let [materiPembelajaran, setMateriPembelajaran] = useState([]);
    let [isEssay, setisEssay] = useState(false);
    let [isMultiple, setisMultiple] = useState(false);
    let [isPraktek, setIsPraktek] = useState(false);

    useEffect(() => {
        async function initData() {
            if (visible) {
                let selectedItem = defaultData();
                // console.log(props)
                if (props.item) {
                    try {
                        let res = await service.getAssignById(props.item.id);
                        console.log(res.data);
                        let { data } = res;
                        setDataSubmit(data);
                        selectedItem = cloneDeep(props.item);
                        selectedItem.number = String(selectedItem.number);
                    } catch (e) {
                        // onRequestClose()
                    }
                }
                setItem(selectedItem);
            } else {
                setItem(defaultData());
            }
            setReady(visible);
        }
        initData();
    }, [visible]);

    const onSubmit = async (data, callback) => {
        // console.log(data)
        // let reqData = dataSubmit;
        try {
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            delete data["file"];

            let file = data.fileFinalSertifikat;
            delete data.fileFinalSertifikat;

            let res = await http.upload(
                `/user_level_produk/upload_sertifikat/${data.id}`,
                file,
                {},
                config
            );
            // console.log(res)
            // let { status } = res;
            // if (status == 201) {
            TableWrapper.updateDataItem(
                "view-assign-user",
                { id: data.id },
                data
            );
            TableWrapper.reload("view-assign-user");
            callback("Upload Success", false);
            // }
        }
        catch (err) {
            callback(err.message, true, false)
        }
    };

    return (
        ready && (
            <DataForm
                baseId="upload-sertifikat"
                title={t.translate("modules.form.sertifikat")}
                visible={visible}
                // data={item}
                onRequestClose={onRequestClose}
                asDialog={true}
                defaultData={props.item || defaultData()}
                definitions={[
                    {
                        inputType: DataForm.inputTypes.FILE_INPUT,
                        // label: t.translate("table.upload"),
                        label: "File",
                        key: "fileFinalSertifikat",
                        accept:
                            "application/pdf",
                    },
                ]}
                onChange={(data, key, value) => {
                    // console.log(key, value)
                    return data;
                }}
                watchDefaultData={false}
                onSubmit={onSubmit}
            />
        )
    );
};

export default UploadSertifikat;
