import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import { cloneDeep, find } from 'lodash'
import t from 'counterpart'
import service from '../../services/levelProduk.service';
import serviceMateri from '../../services/materi.service';
import { TableWrapper } from '../../libs/react-mpk/wrapper';

const defaultData = () => ({
    id: '',
    materi: [],
})
const AssignMateri = ({
    visible = false,
    onRequestClose = () => { },
    ...props
}) => {
    let [dataSubmit, setDataSubmit] = useState({
        id: '',
        materi: [],
    })
    let [item, setItem] = useState(defaultData())
    let [ready, setReady] = useState(true)
    let [materiPembelajaran, setMateri] = useState([]);
    let [tempData, setTempData] = useState([])

    useEffect(() => {
        serviceMateri.getMateri({ size: 1000, sort: "namaMateri,ASC" }).then((res) => {
            // console.log(res)
            setMateri(res.data);
        });
        async function initData() {
            if (visible) {
                let selectedItem = defaultData();
                console.log(props)
                if (props.item) {
                    selectedItem.id = props.item.id
                    let res = await service.getMateri(props.item.id)
                    let { data } = res
                    selectedItem.materi = data.materi
                    setDataSubmit(data);
                    console.log(data)
                }
                setItem(selectedItem)
            } else {
                setItem(defaultData())
            }
            setReady(visible)
        }
        initData()
    }, [visible])

    useEffect(() => {
        console.log(tempData)
    }, [tempData])


    const onSubmit = async (data, callback) => {
        let reqData = dataSubmit;
        reqData.materi = data.materi
        try {
            const resp = await service.setMateri(reqData, data.id)
            TableWrapper.updateDataItem(
                "view-level-produks",
                { id: data.id },
                data
            );
            TableWrapper.reload("view-level-produks");
            callback('Assign Materi Success', false)
        } catch (error) {
            callback('Assign Materi Error ' + error.message, true)
        }
    }

    return ready && (
        <DataForm
            baseId="create-assign-materi"
            title={t.translate('modules.form.AssignMateri').toUpperCase()}
            visible={visible}
            style={{minWidth: "100vh"}}
            // data={item}
            onRequestClose={onRequestClose}
            asDialog={true}
            defaultData={item}
            definitions={[
                {
                    inputType: DataForm.inputTypes.MULTI_CHECKBOX,
                    // label: t.translate("table.namaMateri"),
                    key: 'materi',
                    theme: "filled",
                    options: materiPembelajaran.map((d, i) => ({
                        value: d.id,
                        label: d.namaMateri,
                    })),
                },
                // {
                //     inputType: DataForm.inputTypes.PRE_DATA
                // }
            ]}
            onChange={async (item, key, value) => {
                return item;
            }}
            watchDefaultData={false}
            onSubmit={onSubmit}
            submitLabel={"Simpan"}
        />
    )
}

export default AssignMateri