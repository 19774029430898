import { crud, http } from "../libs/react-mpk/services";

let service = new crud("/remedial");
let serviceUrl = "/remedial";

service.getRemedy = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.createRemedy = async (data, ujianId) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/${ujianId}`,
    data,
  });
};

service.getRemedyDetail = async (remedyId) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${remedyId}`,
  });
};

service.updateRemedy = async (remedyId, data) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${remedyId}`,
    data,
  });
};

service.deleteRemedy = async (remedyId) => {
    return http.request({
        method: http.methods.DELETE,
        url: `${serviceUrl}/${remedyId}`,
    })
}

export default service
