import { crud, http } from "../libs/react-mpk/services";

let service = new crud("/user_remedial");
let serviceUrl = "/user_remedial";

service.getUserRemedy = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.getAssignUserNilaiRemedy = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/nilai`,
    query,
  });
};

service.getUserNilaiRemedy = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/nilai/user`,
    query,
  });
};

export default service
