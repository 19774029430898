import { NightsStay } from '@material-ui/icons';
import { crud, http } from '../../libs/react-mpk/services';
let companyId = sessionStorage.getItem("companyId")

let service = null
// let serviceUrl = '/sample'
// service = new crud('/sample');
let serviceUrl = '/dto'
service = new crud('/dto');

service.create = async()=> {
    let data = {
      "message": "suksesss"
  }
    return http.request({
      method: http.methods.POST,
      url: `${serviceUrl}`,
      data
    })
  }

  service.getData = async() => {
    // let config = {
    //   headers: {
    //     "x-company-id": null
    //   },
    // };
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}`,
      // config
    })
  }
export default service;