import React, { useEffect, useState } from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { sso } from '../../libs/react-mpk/hocs'
import { PageWrapper } from '../../libs/react-mpk/wrapper'
import { TableSample } from '../../modules'
import AutoLogOut from '../../modules/Autologout/Logout'
import Companies from '../../modules/Companies'


const Index = ({ history, authStore}) => {
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    autoLogOut();
  }, []);

  const autoLogOut = () => {
    const timer = new AutoLogOut({
      timeout: 3600 * 1, //set expire time
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        setIsTimeout(true);
      },
      getAuth: authStore
    });

    return () => {
      timer.cleanUp(authStore);
    };
  };
  return (
    <PageWrapper
      sidebarCollapsible={true}
      inverseTheme={true}>
      <Router history={history}>
        <Switch>
          <Route
            path='/product/index/companies'
            render={props => (
              <Companies {...props} />
            )}
          />
        </Switch>
      </Router>
    </PageWrapper>
  )
}

export default sso({
  basePath: '/product/index'
})(Index)