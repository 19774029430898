import { crud, http } from '../libs/react-mpk/services';

let service = null
let serviceUrl = '/role'
service = new crud('/role');

service.getRole = async(query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query
  })
}

service.getAllRole = async() => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
  })
}

service.getRoleById = async(roleId) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${roleId}`
  })
}

service.createRole = async(data)=> {
    return http.request({
      method: http.methods.POST,
      url: `${serviceUrl}`,
      data
    })
  }

  service.update = async(data, id)=> {
    return http.request({
      method: http.methods.PUT,
      url: `${serviceUrl}/${id}`,
      data
    })
  }
  
  service.deleteById = async (id)=>{
    return http.request({
      method: http.methods.DELETE,
      url: `${serviceUrl}/${id}`,
    })
  }
export default service;