import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import { TabsWrapper } from "../../libs/react-mpk/wrapper";
import ViewAssignUser from "./ViewAssignUser";
import ViewAssignLevel from "./AssignLevel/ViewAssignLevel";

const ContainerUser = ({
  className = "",
  navigationStore,
  envStore,
  ...props
}) => {
  let companyId = props.match.params.companyId;
  let levelId = props.match.params.levelId;
  let productId = props.match.params.productId;
  let kelasId = props.match.params.kelasId;

  let getRole = localStorage.getItem("getRole");

  let tabs = [];
  if (getRole.includes("ADMIN")) {
    tabs = [
      {
        label: t.translate("tab.assign-user"),
        key: "view-assign-user",
        render: <ViewAssignUser showCommandbar={true} {...props} />,
      },
      {
        label: t.translate("tab.assign-user-level"),
        key: "view-assign-user-level",
        render: <ViewAssignLevel showCommandbar={true} {...props} />,
      },
    ];
  } else if (getRole.includes("PENGAJAR")) {
    tabs = [
      {
        label: t.translate("tab.assign-user-level"),
        key: "view-assign-user-level",
        render: <ViewAssignLevel showCommandbar={true} {...props} />,
      },
    ];
  }

  return (
    <TabsWrapper
      //   title={t.translate("modules.soal")}
      baseId="view-user"
      className={className}
      tabs={tabs}
      onChange={(tab, callback) => {
        // navigationStore.redirectTo(`/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/assign-user`);
        callback();
      }}
    />
  );
};

export default inject("navigationStore", "envStore")(observer(ContainerUser));
