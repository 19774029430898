import React, { useEffect } from 'react'
import { CompanyCard, Masonry } from '../../../libs/react-mpk/components'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import httpService from '../../../libs/react-mpk/services/http.service'
import service from "../../../services/levelService"
import CustomProduct from '../../../modules/Card/CustomProduct'

const PageProduct = ({ authStore, envStore, navigationStore, ...props }) => {
    let companyId = props.match.params.companyId
    let levelId = props.match.params.levelId
    return (
        <TableWrapper
            title={t.translate('menu.product')}
            baseId="mod-companies-product "
            useFilter={authStore.user ? authStore.user.isSupport : false}
            // render={(data) => (
            //     // <>
            //     //     {JSON.stringify(data)}
            //     // </>
            //     <div className="mpk-padding-N padding-all mpk-animation slide-in mpk-full full-width">
            //         <Masonry
            //             className="mpk-full full-width"
            //             baseId="mod-companies-masonry"
            //             items={
            //                 data.levelProduks && data.levelProduks.map(a => (
            //                     <CustomProduct
            //                         key={`company-${a.id}`}
            //                         value={a}
            //                         href={`/product/company/${companyId}/choose-level/${levelId}/choose-product/${a.id}/choose-class`}
            //                     />
            //                 ))
            //             }
            //         />
            //     </div>
            // )}
            columns={[
                {
                  label: t.translate("column.name"),
                  searchable: true,
                  key: "namaProduk",
                  render: (item) => item.namaProduk,
                },
              ]}
              itemActions={[
                new TableWrapper.action(
                  "Pilih",
                  "mdi mdi-details",
                  (item) => {
                    // navigationStore.redirectTo(`/product/company/${companyId}/choose-level/${levelId}/choose-product/${item.id}/choose-class`)
                    window.location.href = `/product/company/${companyId}/choose-level/${levelId}/choose-product/${item.id}/choose-class`
                  },
                ),
              ]}
            onFetchData={(query) => {
                return (
                    new Promise(async (resolve, reject) => {
                        try {
                            // query = {
                            //   ...search,
                            //   ...query
                            // }
                            // console.log(search)
                            let getPage = await service.getUserLevelProduk(levelId, companyId)
                            console.log(getPage, "getPage")
                            // getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                            resolve(getPage)
                        } catch (e) {
                            reject(e)
                            // ErrorService(e)
                        }
                    })
                )
                // return authStore.user.isSupport
                //   ? httpService.stream(`${envStore.env.sso.host}/api/user_console/company_support`, query, {}, {
                //     useDefaultHost: false,
                //     useDefaultBaseUrl: false,
                //   })
                //   : new Promise(resolve => {
                //     let { companies } = authStore.user
                //     resolve({
                //       data: companies,
                //       headers: {
                //         'x-pagination-count': companies.length,
                //       }
                //     })
                //   })
            }}
        />
    )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(PageProduct))
