import React, {useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from '../sample/sample.service'
import moment from 'moment'
import TableSampleForm from './TableSampleForm'
import Confirm from '../Confrimation/Confirm'

const TableSample = ({
  className       = '',
  showCommandbar  = true
}) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  
  return (
    <>
      <TableWrapper
        baseId="mod-table-sample"
        title={t.translate('modules.table.title')}
        className={className}
        hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="date"
        defaultCollapse={true}
        useCriteria={false}
        selectable={false}
        useQueryHistory={false}
        extraFilter={<div>Test</div>}
        multiColumnFilter={true}
        columns={[
          {
            label: 'String',
            searchable: true,
            sortable: true,
            key:'string',
            definition:{
              inputType: TableWrapper.inputTypes.INPUT,
              defaultValue: ''
            },
            render: item => (
              <TableWrapper.Link
                to={`/internal/inline-form`}
              >
                {item.string}
              </TableWrapper.Link>)
          },
          {
            label: 'Number',
            searchable: false,
            key: 'number',
            type: TableWrapper.dataTypes.NUMBER,
            render: item => (item.number)
          },
          {
            label: 'Boolean',
            type: TableWrapper.dataTypes.BOOLEAN,
            searchable: true,
            definition:{
              inputType: TableWrapper.inputTypes.CHECKBOX,
              defaultValue: true
            },
            render: item => (
              <TableWrapper.Status 
                type={item.boolean 
                  ? TableWrapper.Status.types.SUCCESS
                  : TableWrapper.Status.types.ERROR
                }
              >
                {item.boolean ? 'TRUE' : 'FALSE'}
              </TableWrapper.Status>
            )
          },
          {
            label: 'Date',
            type: TableWrapper.dataTypes.DATE,
            render: item => (moment(item.date).format('lll'))
          }
        ]}
        actions={[
          new TableWrapper.action(
            'Add', 
            'mdi mdi-plus', 
            () => {
              setSelectedItem(null)
              setShowForm(true)
            }, 
            true
          )
        ]}
        itemActions={[
          new TableWrapper.action(
            'Edit', 
            'mdi mdi-pencil', 
            (item) => {
              setSelectedItem(item)
              setShowForm(true)
            }, 
            true
          ),
          new TableWrapper.action('Delete', 'mdi mdi-delete', () => console.log('request delete'), true)
        ]}
        onFetchData = {(query) => {
          return (
              new Promise(async (resolve, reject) => {
                try{  
                  // query = {
                  //   ...search,
                  //   ...query
                  // }
                  // console.log(search)
                  let getPage = await service.getData()
                  // getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                  resolve(getPage)
                } catch(e){
                  reject(e)
                  // ErrorService(e)
                }
              })
          )
        }}
        // onFetchData={ async query => {
        //   return service.getData()
        // }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <Confirm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
        message={"are u sure"}
      />
      {/* <TableSampleForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      /> */}
    </>
  )
}

export default TableSample
