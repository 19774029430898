import React, { useEffect } from 'react'
import { CompanyCard, Masonry } from '../../libs/react-mpk/components'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import httpService from '../../libs/react-mpk/services/http.service'
import { Button } from '@react-md/button'
import CustomCompanyCard from '../Card/CustomCompanyCard'
import moment from 'moment'

const Companies = ({ authStore, envStore, navigationStore, temporaryStore }) => {


  return (
    <TableWrapper
      title={t.translate('menu.companies')}
      baseId="mod-companies"
      useFilter={authStore.user ? authStore.user.isSupport : false}
      // render={(data) => (
      //   <div className="mpk-padding-N padding-all mpk-animation slide-in mpk-full full-width">
      //     <Masonry
      //       className="mpk-full full-width"
      //       baseId="mod-companies-masonry"
      //       items={data.map(d => (
      //           <CompanyCard
      //             key={`company-${d.id}`}
      //             value={d}
      //             // onClick={sessionStorage.setItem("companyId", d.id)}
      //             href={`/product/company/${d.id}/choose-level`}
      //           />
      //       ))}
      //     />
      //   </div>
      // )}
      columns={[
        {
          label: t.translate("column.name"),
          searchable: true,
          key: "name",
          render: (item) => item.name,
        },
        // {
        //   label: "Alamat",
        //   searchable: true,
        //   key: "address",
        //   render: (item) => item.address,
        // },
        // {
        //   label: t.translate("column.industry"),
        //   searchable: true,
        //   key: "industry",
        //   render: (item) => item.industry,
        // },
        {
          label: t.translate("table.tglDibuat"),
          sortable: true,
          type: TableWrapper.dataTypes.DATE,
          key: "tanggalDibuat",
          render: (item) => item.createdAt ? moment(item.createdAt).format("DD MMMM YYYY, HH:mm:ss") : "",
        },
      ]}
      itemActions={[
        new TableWrapper.action(
          "Pilih",
          // "mdi mdi-arrow",
          "mdi mdi-details",
          (item) => {
            // temporaryStore.setProperties('defaultPath', `/product/company/${item.id}/choose-level`);
            // navigationStore.redirectTo(`/product/company/${item.id}/choose-level`)
            window.location.href = `/product/company/${item.id}/choose-level`;
          },
        ),
      ]}
      onFetchData={(query) => {
        return authStore.user.isSupport
          ? httpService.stream(`${envStore.env.sso.host}/api/user_console/company_support`, query, {}, {
            useDefaultHost: false,
            useDefaultBaseUrl: false,
          })
          : new Promise(resolve => {
            let { companies } = authStore.user
            resolve({
              data: companies,
              headers: {
                'x-pagination-count': companies.length,
              }
            })
          })
      }}
    />
  )
}

export default inject('authStore', 'envStore', "navigationStore", "temporaryStore")(observer(Companies))
