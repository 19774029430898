import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import kelasService from "../../services/kelas.service";

const defaultData = () => ({
  file: null,
});

const ImportUser = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let companyId = props.match.params.companyId
  // useEffect(() => {
  // 	if (visible) {
  // 		let selectedItem = defaultData();
  // 		if (props.item) {
  // 			selectedItem = cloneDeep(props.item)
  // 			selectedItem.number = String(selectedItem.number)
  // 		}
  // 		setItem(selectedItem)
  // 	} else {
  // 		setItem(defaultData())
  // 	}
  // 	setReady(visible)
  // }, [visible])

  const submitHandler = async (item, callback) => {
    try {
      let res = await kelasService.importPengajar(companyId, item.file);

      // console.log(res);
      TableWrapper.updateDataItem("view-kelas", { id: item.id }, item);
      TableWrapper.reload("view-kelas");
      callback("success", false);
    } catch (err) {
      console.log(err);
      callback(err.message, true, false);
    }
  };

  return (
    ready && (
      <DataForm
        baseId="import-user"
        title={t.translate("form.importMentor")}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.FILE_INPUT,
            label: "File",
            key: "file",
            accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
          },
        ]}
        onChange={(data, key, value) => {
          return data;
        }}
        onSubmit={submitHandler}
      />
    )
  );
};

export default ImportUser;
