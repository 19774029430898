import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "../../services/kelas.service";
import Confirm from "../Confrimation/Confirm";
import moment from "moment";
import CreateKelas from "./CreateKelas";
import CreateAssignKelas from "./Assign/CreateAssignKelas";
import ImportUser from "./ImportUser";

const ViewKelas = ({ className = "", showCommandbar = true, ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setEditShowForm] = useState(false);
  const [showAssignForm, setAssignShowForm] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  let [search, setSearch] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [type, setType] = useState("");

  let getRole = localStorage.getItem("getRole");
  let userId = localStorage.getItem("getUserId");
  let companyId = props.match.params.companyId;

  const showDeleteItem = (data) => {
    setShowDelete(true);
    setSelectedItem(data);
  };

  const deleteItem = (item) => {
    service.deleteById(item.id).then((res) => {
      TableWrapper.updateDataItem("view-kelas", { id: item.id }, item);
      TableWrapper.reload("view-kelas");
    });
  };
  return (
    <>
      <TableWrapper
        baseId="view-kelas"
        title={t.translate("modules.table.kelas")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="namaKelas"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        // useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        // multiColumnFilter={true}
        columns={[
          {
            label: t.translate("table.namaKelas"),
            sortable: true,
            searchable: true,
            key: "namaKelas",
            render: (item) => item.namaKelas,
          },
          {
            label: t.translate("table.lokasiPelatihan"),
            searchable: true,
            key: "lokasiPelatihan",
            render: (item) => item.lokasiPelatihan,
          },
          {
            label: t.translate("table.namaLevel"),
            searchable: true,
            key: "namaLevel",
            render: (item) => item.namaLevel,
          },
          {
            label: t.translate("table.namaProduk"),
            searchable: true,
            key: "namaProduk",
            render: (item) => item.namaProduk,
          },
          {
            label: t.translate("table.dibuatOleh"),
            key: "dibuatOleh",
            render: (item) => item.dibuatOleh,
          },
          {
            label: t.translate("table.tglDibuat"),
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.tanggalDibuat).format("lll"),
          },
          {
            label: t.translate("table.diperbaharui"),
            key: "diperbaharuiOleh",
            render: (item) => item.diperbaharuiOleh,
          },
          {
            label: t.translate("table.tglDiperbaharui"),
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.tanggalDiperbaharui).format("lll"),
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            getRole === "ROLE_ADMIN"
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setEditShowForm(true);
            },
            getRole === "ROLE_ADMIN"
          ),
          new TableWrapper.action(
            "Assign Kelas",
            "mdi mdi-pencil",
            (item) => {
              item.id = item.id;
              setSelectedItem(item);
              setAssignShowForm(true);
            },
            getRole === "ROLE_ADMIN"
          ),
          new TableWrapper.action(
            t.translate("form.importMentor"),
            "mdi mdi-account-group-outline",
            (item) => {
              setSelectedItem(item);
              setShowImport(true);
              setType("pengajar");
            },
            getRole.includes("ADMIN")
          ),
          // new TableWrapper.action(
          //   t.translate("form.importParticipant"),
          //   "mdi mdi-account-group-outline",
          //   (item) => {
          //     setSelectedItem(item);
          //     setShowImport(true);
          //     setType('peserta')
          //   },
          //   getRole.includes("ADMIN")
          // ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              showDeleteItem(item);
            },
            getRole === "ROLE_ADMIN"
          ),
        ]}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              // query = {
              //     ...search,
              //     ...query
              // }
              query = {
                ...query,
                "kodePerusahaan.equals": companyId,
              };
              let getPage = await service.getKelas(query);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              console.log(getPage);
              resolve(getPage);
            } catch (e) {
              reject(e);
              // ErrorService(e)
            }
          });
        }}
        // onFetchData={async query => {
        //     // let getPage = await service.getLevel(query)
        //     // service.getLevel(query).headers['x-pagination-count'] = service.getLevel(query).headers['x-total-count']
        //     console.log(query)
        //     return service.getLevel(query)
        // }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <Confirm
        visible={showDelete}
        onRequestClose={() => {
          setShowDelete(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          deleteItem(selectedItem);
          setShowDelete(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.levelDelete")}
      />
      <CreateKelas
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />

      <CreateKelas
        visible={showEditForm}
        onRequestClose={() => {
          setEditShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />

      <CreateAssignKelas
        visible={showAssignForm}
        onRequestClose={() => {
          setAssignShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />

      <ImportUser
        visible={showImport}
        onRequestClose={() => {
          setShowImport(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />
    </>
  );
};

export default ViewKelas;
