import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import service from "../../services/remedy.service";
import ujianService from "../../services/ujian.service";
import { inject, observer } from "mobx-react";
import ViewSoalUjianEssay from "../Ujian/Essay/ViewSoalUjianEssay";
import ViewSoalUjianPraktek from "../Ujian/Praktek/ViewSoalUjianPraktek";
import iziToast from "izitoast";
import ViewSoalUjianpg from "../Ujian/Multiple/ViewSoalUjianpg";
import RemedyForm from "./RemedyForm";

const PracticeRemedial = ({
  className = "",
  showCommandbar = true,
  modalStore,
  ...props
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showEssay, setShowEssay] = useState(false);
  const [showPilgan, setShowPilgan] = useState(false);
  const [showPraktek, setShowPraktek] = useState(false);
  const [duration, setDuration] = useState(0);
  const [showImport, setShowImport] = useState(false);

  let productId = props.match.params.productId;
  let userId = localStorage.getItem("getUserId");
  const role = localStorage.getItem("getRole");
  let companyId = props.match.params.companyId;
  let kelasId = props.match.params.kelasId

  let act;
  const deleteExam = async (item) => {
    try {
      let res = await service.deleteRemedy(item.id);

      let { data, status } = res;

      if (status == 200) {
        iziToast.success({
          message: "success",
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  const showDeleteExam = (item) => {
    modalStore.showConfirm({
      title: t.translate("form.deleteConfirm"),
      children: t.translate("message.ujianDelete"),
      onSubmit: (callback) => {
        deleteExam(item);
        callback();
      },
    });
  };

  if (role === "ROLE_ADMIN") {
    act = [
      new TableWrapper.action(
        "Add",
        "mdi mdi-plus",
        () => {
          setSelectedItem(null);
          setShowForm(true);
        },
        true
      ),
    ];
  }

  return (
    <>
      <TableWrapper
        baseId="practice-remedial-table"
        title={t.translate("tab.remedy")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="judulUjian"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        multiColumnFilter={false}
        showFilter={false}
        columns={[
          // {
          //   label: t.translate("table.judulRemedial"),
          //   searchable: true,
          //   key: "namaUjian",
          //   render: (item) => item.judulUjian,
          // },
          {
            label: "Status",
            searchable: true,
            key: "dikerjakan",
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.dikerjakan == 1
                    ? TableWrapper.Status.types.IDLE
                    : item.dikerjakan == 2
                    ? TableWrapper.Status.types.PROGRESS
                    : TableWrapper.Status.types.SUCCESS
                }
              >
                {item.dikerjakan == 1
                  ? "Belum Dimulai"
                  : item.dikerjakan == 2
                  ? "Sedang Berjalan"
                  : "Selesai"}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Nama Peserta",
            searchable: true,
            key: "namaPengguna",
            render: (item) => item.namaPengguna,
          },
          {
            label: t.translate("table.durasiUjian") + " (Menit)",
            searchable: false,
            key: "durasiUjian",
            render: (item) => item.durasiUjian,
          },
          {
            label: t.translate("table.tglMulai"),
            type: TableWrapper.dataTypes.DATE,
            key: "tanggalMulaiUjian",
            render: (item) => moment(item.tanggalMulaiUjian).format("DD MMMM YYYY, HH:mm:ss"),
          },
          {
            label: t.translate("table.tglSelesai"),
            type: TableWrapper.dataTypes.DATE,
            key: "tanggalAkhirUjian",
            render: (item) => moment(item.tanggalAkhirUjian).format("DD MMMM YYYY, HH:mm:ss"),
          },
          {
            label: t.translate("table.namaMateri"),
            searchable: true,
            key: "namaMateri",
            render: (item) => item.namaMateri,
          },
        ]}
        // actions={act}
        actions={[
          // new TableWrapper.action(
          //   "Add",
          //   "mdi mdi-plus",
          //   () => {
          //     setSelectedItem(null);
          //     setShowForm(true);
          //   },
          //   role.includes("ADMIN")
          // ),
          // new TableWrapper.action(
          //   "Import",
          //   "mdi mdi-upload",
          //   () => {
          //     setSelectedItem(null);
          //     setShowImport(true);
          //   },
          //   true
          // ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item.id);
              setShowForm(true);
            },
            () => role.includes("ADMIN")
          ),
          new TableWrapper.action(
            "Kerjakan Soal Remedial Praktek",
            "mdi mdi-upload",
            (item) => {
              setSelectedItem(item);
              setShowPraktek(true);
            },
            // (item) => (role.includes("ADMIN") || item.isPraktek ? true : false)
            (item) => ((role.includes("ADMIN") && item.isPraktek) || (item.isPraktek && item.dikerjakan === 2) ? true : false)
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => showDeleteExam(item),
            () => role.includes("ADMIN")
          ),
        ]}
        onFetchData={(query) => {
          query["isPraktek.equals"] = true;
          query["userId.equals"] = userId;
          query["levelProdukId.equals"] = productId;
          query["kodePerusahaan.equals"] = companyId;
          query["kodeKelas.equals"] = kelasId

          // query['dikerjakan.greaterThan'] = 1
          return new Promise(async (resolve, reject) => {
            try {
              let res = await service.getRemedy(query);
              res.headers["x-pagination-count"] = res.headers["x-total-count"];
              resolve(res);
            } catch (e) {
              reject(e);
              // ErrorService(e)
            }
          });
        }}
        // onFetchData={ async query => {
        //   return service.getData()
        // }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />

      <ViewSoalUjianPraktek
        visible={showPraktek}
        onRequestClose={() => {
          setShowPraktek(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        tableId={"practice-remedial-table"}
      />

      <RemedyForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        type={"update"}
        productId={props.match.params.productId}
        tableId={'practice-remedial-table'}
        {...props}
      />
    </>
  );
};

export default inject("modalStore")(observer(PracticeRemedial));
