import React from 'react'
import { FontIcon } from 'react-md'

import { inject, observer } from 'mobx-react'
import '../../libs/react-mpk/components/CompanyCard/CompanyCard.scss'
import { Link } from '../../libs/react-mpk/components'

const CustomCompanyCard = ({
  className = '',
  href = '',
  thumbnail = false,
  value = {},
  onClickRequest = () => { },
  ...props
}) => {
  return (
    <Link
      to={href}
      className={thumbnail ? 'mpk-full full-width' : ''}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        onClickRequest()
        props.navigationStore.redirectTo(href)
      }}
    >
      <div style={{ width: "250px" }} className={`mpk-company-card mpk-border solid dark ${className} ${thumbnail ? 'thumbnail mpk-flex align-center border-bottom' : 'border-all thin '}`}>
        <div className="image mpk-flex justify-center align-center">
          {value.icon ? (
            <img src={value.icon} alt={value.namaLevel} />
          ) : (
            <FontIcon iconClassName="mdi mdi-atom" />
          )}
        </div>
        <div className={`mpk-padding-N padding-all ${thumbnail ? '' : 'mpk-border thin solid dark border-top'}`}>
          <div className="mpk-font weight-B">{value.namaLevel}</div>
        </div>
      </div>
    </Link>
  )
}

export default inject('navigationStore')(observer(CustomCompanyCard))
