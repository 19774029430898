import React, { useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "../../../services/latihan.service";
import moment from "moment";
import Confirm from "../../Confrimation/Confirm";
import CreateLatihanEssay from "../createLatihanEssay";
import ViewSoalEssay from "./ViewSoalEssay";
import ViewSoalMultiple from "../Multiple/ViewSoalMultiple";
import iziToast from "izitoast";
import ImportAssignment from "../ImportAssignment";

const ViewEssay = ({ className = "", showCommandbar = true, ...props }) => {
  const [showForm, setShowForm] = useState();
  const [showEditForm, setEditShowForm] = useState(false);
  const [showMultipleForm, setMultipleShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  let [search, setSearch] = useState({});
  const [showDelete, setShowDelete] = useState(false);

  const [showImport, setShowImport] = useState(false);

  let productId = props.match.params.productId;
  let companyId = props.match.params.companyId;
  let kelasId = props.match.params.kelasId;
  let userId = localStorage.getItem("getUserId");
  let role = localStorage.getItem("getRole");
  let act;

  const showDeleteItem = (data) => {
    setShowDelete(true);
    setSelectedItem(data);
  };
  const deleteItem = async (item) => {
    try {
      let res = await service.deleteById(item.id);
      console.log(res);
      TableWrapper.updateDataItem("view-essay-latihan", { id: item.id }, item);
      TableWrapper.reload("view-essay-latihan");
      iziToast.success({
        message: "Success",
      });
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  return (
    <>
      <TableWrapper
        baseId="view-essay-latihan"
        title={t.translate("tab.latihan")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="judulLatihan"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        showFilter={false}
        // extraFilter={<div>Test</div>}
        // multiColumnFilter={true}
        columns={[
          // {
          //   label: t.translate("table.judulLatihan"),
          //   searchable: true,
          //   key: "judulLatihan",
          //   render: (item) => item.judulLatihan,
          // },
          {
            label: "Status",
            searchable: true,
            key: "dikerjakan",
            definition: {
              inputType: TableWrapper.inputTypes.SELECT,
              options: [
                { value: "1", children: "Belum Dimulai" },
                { value: "2", children: "Sedang Berjalan" },
                { value: "3", children: "Selesai" },
              ],
              criterias: ["equals", "notEquals"],
              // defaultValue: true
            },
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.dikerjakan == 1
                    ? TableWrapper.Status.types.IDLE
                    : item.dikerjakan == 2
                    ? TableWrapper.Status.types.PROGRESS
                    : TableWrapper.Status.types.SUCCESS
                }
              >
                {item.dikerjakan == 1
                  ? "Belum Dimulai"
                  : item.dikerjakan == 2
                  ? "Sedang Berjalan"
                  : "Selesai"}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Nama Peserta",
            searchable: true,
            key: "namaPengguna",
            render: (item) => item.namaPengguna,
          },
          {
            label: t.translate("table.tglMulai"),
            type: TableWrapper.dataTypes.DATE,
            key: "tanggalAwalLatihan",
            render: (item) => moment(item.tanggalAwalLatihan).format("DD MMMM YYYY, HH:mm:ss"),
          },
          {
            label: t.translate("table.tglSelesai"),
            type: TableWrapper.dataTypes.DATE,
            key: "tanggalAkhirLatihan",
            render: (item) => moment(item.tanggalAkhirLatihan).format("DD MMMM YYYY, HH:mm:ss"),
          },
          {
            label: t.translate("table.namaMateri"),
            searchable: true,
            key: "namaMateri",
            render: (item) => item.namaMateri,
          },
          // {
          //   label: t.translate("table.dibuatOleh"),
          //   searchable: true,
          //   key: "dibuatOleh",
          //   render: (item) => item.dibuatOleh,
          // },
          // {
          //   label: t.translate("table.tglDibuat"),
          //   type: TableWrapper.dataTypes.DATE,
          //   key: "tanggalDibuat",
          //   render: (item) => moment(item.tanggalDibuat).format("lll"),
          // },
        ]}
        // actions={act}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            role.includes("ADMIN")
          ),
          new TableWrapper.action(
            "Import Latihan Teori",
            "mdi mdi-upload",
            () => {
              setSelectedItem(null);
              setShowImport(true);
            },
            // ["ADMIN", "PENGAJAR"].some((role) => role.includes(role))
            role.includes("ADMIN") || role.includes("PENGAJAR")
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setShowForm(true);
            },
            role.includes("ADMIN")
          ),
          new TableWrapper.action(
            "Kerjakan Soal Essay",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setEditShowForm(true);
            },
            (item) =>
              (role.includes("ADMIN") &&
                item.isEssay &&
                item.dikerjakan === 2) ||
              (item.isEssay && item.dikerjakan === 2)
                ? true
                : false
            // (item) =>
            //   item.isEssay &&
            //   moment(item.tanggalAwalLatihan).diff(new Date()) < 0 &&
            //   moment(item.tanggalAkhirLatihan).diff(new Date()) > 0
          ),
          new TableWrapper.action(
            "Kerjakan Soal Pilihan Ganda",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setMultipleShowForm(true);
            },
            (item) =>
              (role.includes("ADMIN") && item.isPg) ||
              (item.isPg && item.dikerjakan === 2)
                ? true
                : false
            // (item) => item.isPg && moment(item.tanggalAwalLatihan).diff(new Date()) < 0 &&
            //   moment(item.tanggalAkhirLatihan).diff(new Date()) > 0
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              setShowDelete(true);
              setSelectedItem(item);
            },
            role.includes("ADMIN")
          ),
          // new TableWrapper.action("Delete", "mdi mdi-delete", (item) =>
          //   service.deleteById(item.name)
          // ),
        ]}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            query["isPg.equals"] = true;
            query["isEssay.equals"] = true;
            query["isPraktek.equals"] = false;
            // query["userId.equals"] = userId;
            query["levelProdukId.equals"] = productId;
            query["kodePerusahaan.equals"] = companyId;
            query["kodeKelas.equals"] = kelasId;
            // query["dikerjakan.greaterThan"] = 1;

            try {
              query = {
                ...search,
                ...query,
              };
              let getPage = await service.getLatihan(query);
              // let getPage = await service.getFilterLatihan("Teori")
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              // console.log(getPage);
              resolve(getPage);
            } catch (e) {
              reject(e);
              // ErrorService(e)
            }
          });
        }}
        // onFetchData={ async query => {
        //   return service.getData()
        // }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <Confirm
        visible={showDelete}
        onRequestClose={() => {
          setShowDelete(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          deleteItem(selectedItem);
          setShowDelete(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.latihanDelete")}
      />
      <CreateLatihanEssay
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        tableId={"view-essay-latihan"}
        type={"teori"}
        {...props}
      />

      <ViewSoalEssay
        visible={showEditForm}
        onRequestClose={() => {
          setEditShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        tableId = {"view-essay-latihan"}
      />
      <ViewSoalMultiple
        visible={showMultipleForm}
        onRequestClose={() => {
          setMultipleShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        tableId = {"view-essay-latihan"}
      />

      <ImportAssignment
        visible={showImport}
        onRequestClose={() => {
          setShowImport(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        tableId={"view-essay-latihan"}
        {...props}
      />
    </>
  );
};

export default ViewEssay;
