import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "../../services/materi.service";
import moment from "moment";
import Confirm from "../Confrimation/Confirm";
import CreateMateri from "./CreateMateri";
import VideoPlayerDialogue from "./VideoPlayerDialogue";
import MateriPdf from "./MateriPdf";
import UploadVideo from "./UploadVideo";
import UploadPdf from "./UploadPdf";
import { metodePembelajaranType, role } from "../../constant/appConstant";

const ViewMateri = ({ 
  className = "", 
  showCommandbar = true,
  ...props 
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setEditShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [uploadVideo, setUploadVideo] = useState(false);
  const [uploadPdf, setUploadPdf] = useState(false);

  let getRole = localStorage.getItem("getRole");
  let userId = localStorage.getItem("getUserId");

  const showDeleteItem = (data) => {
    setShowDelete(true);
    setSelectedItem(data);
  };

  const deleteItem = (item) => {
    service.deleteById(item.id).then((res) => {
      TableWrapper.updateDataItem("view-materi", { id: item.id }, item);
      TableWrapper.reload("view-materi");
    });
  };

  let act = [
    new TableWrapper.action(
      "Add",
      "mdi mdi-plus",
      () => {
        setSelectedItem(null);
        setShowForm(true);
      },
      getRole === role.ROLE_ADMIN
    ),
  ];

  let actionBar = [
    new TableWrapper.action(
      "Edit",
      "mdi mdi-pencil",
      (item) => {
        item.pdf = "";
        item.video = "";
        setSelectedItem(item);
        setEditShowForm(true);
      },
      getRole === role.ROLE_ADMIN
    ),
    new TableWrapper.action(
      "Upload Video",
      "mdi mdi-upload",
      (item) => {
        item.video = "";
        setSelectedItem(item);
        setUploadVideo(true);
      },
      getRole === role.ROLE_ADMIN
    ),
    new TableWrapper.action(
      "Upload PDF",
      "mdi mdi-upload",
      (item) => {
        item.pdf = "";
        setSelectedItem(item);
        setUploadPdf(true);
      },
      getRole === role.ROLE_ADMIN
    ),
    new TableWrapper.action(
      "Lihat Materi Video",
      "mdi mdi-movie-open-play-outline",
      (item) => {
        setSelectedItem(item);
        setShowVideo(true);
      },
      (item) => (item.pathVideo ? true : false)
    ),
    new TableWrapper.action(
      "Lihat Materi PDF",
      "mdi mdi-eye",
      (item) => {
        setSelectedItem(item);
        setShowPdf(true);
      },
      (item) => (item.pathMateri ? true : false)
    ),
    new TableWrapper.action(
      "Buka Aplikasi",
      "mdi mdi-web",
      (item) => {
          window.open(item.urlAplikasi)
      },
      (item) => (item.urlAplikasi)
    ),
    new TableWrapper.action(
      "Delete",
      "mdi mdi-delete",
      (item) => {
        showDeleteItem(item);
      },
      getRole === role.ROLE_ADMIN
    )
  ];

  return (
    <>
      <TableWrapper
        baseId="view-materi"
        title={t.translate("modules.table.materi")}
        className={className}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        showFilter={false}
        columns={[
          {
            label: t.translate("table.metodePembelajaran"),
            searchable: false,
            sortable: true,
            key: "metodePembelajaran",
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.metodePembelajaran === metodePembelajaranType.TEORI
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.PROGRESS
                }
              >
                {item.metodePembelajaran === metodePembelajaranType.TEORI ? metodePembelajaranType.TEORI : metodePembelajaranType.PRAKTEK}
              </TableWrapper.Status>
            ),
          },
          {
            label: t.translate("table.nameMateri"),
            searchable: true,
            sortable: true,
            key: "namaMateri",
            render: (item) => item.namaMateri,
          },
          {
            label: t.translate("table.urlAplikasi"),
            searchable: false,
            key: "urlAplikasi",
            render: (item) => item.urlAplikasi !== null && item.urlAplikasi !== "" ? item.urlAplikasi : "-",
          },
          {
            label: t.translate("table.dibuatOleh"),
            searchable: false,
            sortable: true,
            key: "dibuatOleh",
            render: (item) => item.dibuatOleh,
          },
          {
            label: t.translate("table.tglDibuat"),
            sortable: true,
            type: TableWrapper.dataTypes.DATE,
            key: "tanggalDibuat",
            render: (item) => item.tanggalDibuat ? moment(item.tanggalDibuat).format("DD MMMM YYYY, hh:mm:ss A") : "",
          },
          {
            label: t.translate("table.diperbaharui"),
            sortable: true,
            key: "diperbaharuiOleh",
            searchable: false,
            render: (item) => item.diperbaharuiOleh,
          },
          {
            label: t.translate("table.tglDiperbaharui"),
            sortable: true,
            type: TableWrapper.dataTypes.DATE,
            render: (item) => item.tanggalDiperbaharui ? moment(item.tanggalDiperbaharui).format("DD MMMM YYYY, hh:mm:ss A") : "",
          },
        ]}
        actions={act}
        itemActions={actionBar}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query['dibuatOleh.contains'] = "simpjk22060000248";
              if (getRole === role.ROLE_ADMIN) {
                let getPage = await service.getMateri(query);
                getPage.headers["x-pagination-count"] = getPage.headers["x-total-count"];
                resolve(getPage);
              } 
              else {  
                query.userId = userId;
                query["levelProdukId.equals"] = props.match.params.productId;
                
                let getPage = await service.getUserMateri(query);
                getPage.headers["x-pagination-count"] = getPage.headers["x-total-count"];
                resolve(getPage);
              }
            } catch (e) {
              reject(e);
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />

      <Confirm
        visible={showDelete}
        onRequestClose={() => {
          setShowDelete(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          deleteItem(selectedItem);
          setShowDelete(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.materiDelete")}
      />

      <CreateMateri
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

      <CreateMateri
        visible={showEditForm}
        onRequestClose={() => {
          setEditShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

      <VideoPlayerDialogue
        visible={showVideo}
        onRequestClose={() => {
          setShowVideo(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

      <MateriPdf
        visible={showPdf}
        onRequestClose={() => {
          setShowPdf(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

      <UploadVideo
        visible={uploadVideo}
        onRequestClose={() => {
          setUploadVideo(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

      <UploadPdf
        visible={uploadPdf}
        onRequestClose={() => {
          setUploadPdf(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

    </>
  );
};

export default ViewMateri;