import React, { useEffect, useState } from "react";
import ReactCustomizableProgressbar from "react-customizable-progressbar";
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "react-md";
import userService from "../../services/assignUser.service";
import userLevelService from "../../services/assignUserLevel.service";
import "./Dashboard.css";
import iziToast from "izitoast";
import t from "counterpart";
import dashboardService from "../../services/dashboard.service";
import Clock from "./Clock/Clock";
import ProgressBar from "./Bar/ProgressBar";

const Dashboard = ({...props}) => {
  const [user, setUser] = useState({});
  const [userLevel, setUserLevel] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [score, setScore] = useState([]);
  let productId = props.match.params.productId;
  let kelasId = props.match.params.kelasId;
  let companyId = props.match.params.companyId;

  let getRole = localStorage.getItem("getRole");
  let role;

  if (getRole === "ROLE_PESERTA") {
    role = [<div>Peserta</div>];
  } else if (getRole === "ROLE_ADMIN") {
    role = [<div>Admin</div>];
  } else {
    role = [<div>Pengajar</div>];
  }
  const init = async () => {
    try {
      let res = await dashboardService.getProfile(productId);
      let { data } = res;
      // console.log("data", data)
      setUser(data);
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }

    try {
      let res = await dashboardService.getUserLevel(productId, kelasId, companyId);
      let { data } = res;
      console.log(data);
      // console.log("data", data[0].levelProduks[0].kelas[0].namaKelas)
      setUserLevel(data);
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }

    try {
      let res = await dashboardService.getMaterial(productId, companyId);
      let { data } = res;
      setMaterialList(data);
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }

    try {
      let res = await dashboardService.getScore(productId, companyId);
      let { data } = res;
      data.map((d) => {
        d.nilaiRataRata = d.nilaiRataRata ? d.nilaiRataRata : 0;
      });
      setScore(data);
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="dashboard" style={{ padding: "2%" }}>
      <div className="header">
        <Clock />
      </div>

      <div>
        <Card
          style={{
            width: "100%",
            background: "#d64c42",
            color: "white",
            fontSize: "25px",
          }}
        >
          <CardHeader
            style={{
              borderBottomStyle: "solid",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            {t.translate("modules.dashboard.userInformation")}
          </CardHeader>

          <div className="dataDiri">
            <CardHeader
              style={{
                marginTop: "-30px",
                width: "65%",
                fontSize: "35px",
                color: "white",
              }}
            >
              Welcome, {user.nama}
              <div
                style={{
                  fontSize: "15px",
                }}
              >
                {role}
              </div>
            </CardHeader>

            <CardContent>
              {userLevel.map((d) => (
                <Card
                  style={{
                    width: "100%",
                    background: "white",
                    color: "black",
                    textAlign: "center",
                    color: "black",
                    padding: "10px",
                    borderStyle: "groove",
                  }}
                >
                  {/* <table>
                    <tr>
                      <td>Level</td>
                      <td>:</td>
                      <td>{d.namaLevel}</td>
                    </tr>

                    <tr VALIGN="top">
                      <td>{t.translate("table.product")}</td>
                      <td>:</td>
                      <td>
                        {d.levelProduks &&
                          d.levelProduks.map((item) => (
                            <tr>{item.namaProduk}</tr>
                          ))}
                      </td>
                    </tr>

                    <tr>
                      <td>{t.translate("table.namaKelas")}</td>
                    </tr>
                  </table> */}

                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <b>Level :</b> {d.namaLevel}
                    <div>
                      {d.levelProduks &&
                        d.levelProduks.map((item) => (
                          <>
                            <div>
                              <b>
                                {t.translate("table.product")} :{" "}
                              </b>
                                {item.namaProduk}
                            </div>
                            <div
                            // style={{
                            //   display: "flex",
                            //   flexDirection: "row",
                            // }}
                            >
                              <div>
                                <b>{t.translate("table.namaKelas")}</b>
                                <br />
                                {item.kelas.length !== 0
                                  ? item.kelas.map((d) => (
                                      <div>{d.namaKelas}</div>
                                    ))
                                  : t.translate("message.unregisteredClass")}
                                
                                <br/>

                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                </Card>
              ))}
            </CardContent>
          </div>
        </Card>
      </div>

      {/* Daftar Level */}
      {/* <Card style={{
        marginTop: "20px",
        width: "100%",
        background: "#2670ac",
        color: "white"
      }}>
        <div>
          <CardHeader style={{
            fontSize: "25px"
          }}>
            {t.translate("modules.dashboard.levelList")}
          </CardHeader>

          <CardContent>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around'
            }}>
              {
                userLevel.map(d => (
                  <Card style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: '100%',
                    flexDirection: "row",
                    background: "black",
                    textAlign: "center",
                    color: "white",
                    padding: "20px",
                    margin: "20px"
                  }}>
                    <div style={{
                      textAlign: 'center',
                    }}>
                      {d.namaLevel}
                      <div>
                        {d.levelProduks && d.levelProduks.map(item => (
                          <div>{item.namaProduk}</div>
                        ))}
                      </div>
                    </div>
                  </Card>
                ))
              }
            </div>
          </CardContent>
        </div>
      </Card> */}

      <Card
        style={{
          marginTop: "20px",
          width: "100%",
          background: "#d64c42",
          color: "white",
        }}
      >
        <div>
          <CardHeader
            style={{
              borderBottomStyle: "solid",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              fontSize: "25px",
            }}
          >
            {t.translate("modules.dashboard.materiList")}
          </CardHeader>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              width: "100%",
              textAlign: "center",
            }}
          >
            {materialList.map((material) => (
              <div className="materi">
                <Card
                  style={{
                    margin: "20px",
                    width: "450px",
                    textAlign: "center",
                    flexGrow: 2,
                  }}
                >
                  <CardHeader>{material.namaLevel}</CardHeader>

                  <CardContent>
                    <b>{material.namaProduk}</b>

                    <>
                      {/* <div className="teori"> */}
                      <table
                        style={{
                          marginTop: "10px",
                          borderStyle: "solid",
                          background: "whitesmoke",
                          borderRadius: "5px",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <tr>
                          <th>Teori</th>
                          <th>Praktik</th>
                        </tr>

                        <tr>
                          <td>
                            {material.materiPembelajarans &&
                              material.materiPembelajarans
                                .filter((d) => d.metodePembelajaran === "Teori")
                                .map((item) => (
                                  <div>
                                    {/* {item.metodePembelajaran} */}
                                    {item.namaMateri}
                                  </div>
                                ))}
                          </td>
                          <td>
                            {material.materiPembelajarans &&
                              material.materiPembelajarans
                                .filter(
                                  (d) => d.metodePembelajaran === "Praktik"
                                )
                                .map((item) => (
                                  <div>
                                    {/* {item.metodePembelajaran} */}
                                    {item.namaMateri}
                                  </div>
                                ))}
                          </td>
                        </tr>
                      </table>

                      {/* </div> */}
                    </>
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </Card>

      <Card
        style={{
          marginTop: "20px",
          width: "100%",
          color: "white",
          background: "#d64c42",
        }}
      >
        <CardHeader
          style={{
            borderBottomStyle: "solid",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            fontSize: "25px",
          }}
        >
          {t.translate("modules.dashboard.scoreList")}
        </CardHeader>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          {/* {score.map((d) => (
            <div className="nilai">
              <Card
                style={{
                  margin: "10px",
                  width: "100px",
                  background: "white",
                  textAlign: "center",
                  flexGrow: 2,
                }}
              >
                <CardHeader>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>{d.namaProduk}</div>

                    <div>{d.nama}</div>
                    <div>{d.namaLevel}</div>
                  </div>
                </CardHeader>
                <CardContent
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <ReactCustomizableProgressbar
                    progress={d.nilaiRataRata ? d.nilaiRataRata : 0}
                    radius={100}
                    steps={100}
                  >
                    <div className="indicator">
                      <div>{d.nilaiRataRata ? d.nilaiRataRata : 0}/100</div>
                    </div>
                  </ReactCustomizableProgressbar>
                </CardContent>
              </Card>
            </div>
          ))} */}

          <div style={{ display: "flex", flexDirection: "column", marginLeft: '1%' }}>
            {score.map((d, i) => (
              <div>
                <div>{d.namaProduk}</div>

                <div>{d.nama}</div>
                <div>{d.namaLevel}</div>
                {/* <div>
                </div> */}
                <ProgressBar
                  key={i}
                  bgcolor={"#feb236"}
                  completed={d.nilaiRataRata}
                />
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Dashboard;
