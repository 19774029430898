import React, { useEffect } from 'react'
import { Appbar, Flex, NewsTicker, Sidebar } from '../components'
import { inject, observer } from 'mobx-react';

const PageWrapper = ({
  appbarStyle = {},
  baseId = `mpk-page-wrapper`,
  brand = null,
  sidebarCollapsible = false,
  sidebarDefaultCollapse = true,
  sidebarHeader = null,
  sidebarMenu = null,
  inverseTheme = false,
  useSidebar = true,
  showNewsTicker = false,
  handleLogout = null,
  handleProfile = null,
  username = null,
  appbar = {},
  commandbar = {},
  sidebar = {},
  showAppbar = true,
  showFilterMenu = true,
  ...props
}) => {
  return (
    <Flex
      className="mpk-full full-width full-height"
      direction={Flex.properties.direction.COLUMN}
    >
      {showAppbar && (
        <Appbar
          brand={brand}
          inverseTheme={inverseTheme}
          style={appbarStyle}
          handleLogout={handleLogout}
          handleProfile={handleProfile}
          username={username}
          {...appbar}
        />
      )}
      <Flex fit className="mpk-full full-width">
        {useSidebar && (
          <Sidebar
            style={{
              boxShadow: "6px 0 4px -4px #999, -6px 0 4px -4px #999", 
            }}
            baseId={`${baseId}-sidebar`}
            collapsible={sidebarCollapsible}
            defaultCollapse={sidebarDefaultCollapse}
            header={sidebarHeader}
            menu={sidebarMenu}
            showFilterMenu={showFilterMenu}
            {...sidebar}
          />
        )}
        <Flex
          direction={Flex.properties.direction.COLUMN}
          fit
          className={`mpk-full full-width full-height mpk-position position-relative`}
        >
          <Flex scrollable={true} fit className={`mpk-full full-width`}>
            {props.children}
          </Flex>
          {showNewsTicker && <NewsTicker className="mpk-border thin solid dark border-top" />}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default inject('envStore')(observer(PageWrapper));
