import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  FileInput,
  Text,
  TextField,
  CircularProgress,
  TextIconSpacing,
  DialogHeader,
  Card,
  FontIcon,
} from "react-md";
import service from "../../services/latihan.service";
import t from "counterpart";
import http from "../../libs/react-mpk/services/http.service";
import iziToast from "izitoast";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import ViewPDF from "../Latihan/Praktek/ViewPDF";
import FileSaver from "file-saver";
import { inject, observer } from "mobx-react";

const ViewUserPraktek = ({
  visible = false,
  onRequestClose = () => {},
  modalStore,
  ...props
}) => {
  const [ready, setReady] = useState(false);
  const [item, setItem] = useState([]);
  const [exam, setExam] = useState({});
  const [itemExcel, setItemExcel] = useState([]);
  const [showExcel, setShowExcel] = useState([]);
  const [showPdf, setShowPdf] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedItemExcel, setSelectedItemExcel] = useState(false);
  const [name, setName] = useState({});

  const initItem = async () => {
    if (visible) {
      if (props.item) {
        setName(props.item.judulLatihan);

        try {
          let res = await service.getSoalLatihan(
            props.item.latihanId,
            "Praktek"
          );
          setShowPdf(false);
          let { data, status } = res;

          data
            .sort((a, b) =>
              parseInt(a.urutanSoal) > parseInt(b.urutanSoal)
                ? 1
                : parseInt(a.urutanSoal) < parseInt(b.urutanSoal)
                ? -1
                : 0
            )
            .map((d) => {
              d.disabled = false;
              d.pointSoal = d.pointSoal ? d.pointSoal : 0
            });
          console.log(data);
          setItem(data);
          setItemExcel(data);
        } catch (err) {
          iziToast.error({
            message: err.message,
          });
        }
      }
    }
    setReady(visible);
  };

  useEffect(() => {}, [item]);

  useEffect(() => {
    initItem();
  }, [visible]);

  const closeForm = () => {
    setItem([]);
    onRequestClose();
  };
  const downloadAttach = (e, i) => {
    modalStore.showConfirm({
      title: t.translate("form.downloadConfirmation"),
      children: t.translate("message.download"),
      onSubmit: (callback) => {
        getExcelItem(item[i]);
        callback();
      },
    });
  };
  const openPDF = async (e, i) => {
    setShowPdf(true);
    setSelectedItem(item[i]);
  };
  const getExcelItem = (item) => {
    let config = Object.assign({
      method: "get",
      responseType: "arraybuffer",
    });

    let options = Object.assign({
      fileType: null,
      fileName: item.jawabanExcelPraktek,
    });

    return new Promise(async (resolve, reject) => {
      try {
        let res = await (config.method === "post"
          ? http.post(`/latihan/praktek/${item.id}`, item, config)
          : http.get(
              `/latihan/praktek/${item.id}`,
              {
                file: "excel",
              },
              config
            ));
        let fileName =
          options.fileName ||
          res.headers["content-disposition"].split("filename=")[1];
        let type =
          options.fileType ||
          res.headers["content-type"].substring(
            0,
            res.headers["content-type"].indexOf(";")
          );
        let { data } = res;
        const blob = new Blob([res.data], { type: type });
        FileSaver.saveAs(blob, fileName);
        resolve(res)
      } catch (error) {
        reject(error);
      }
    });
  };

  const changePoint = (e, i) => {
    let temp = [...item];

    temp[i].pointSoal = e.target.value;
    // setItem(temp);
    console.log(e.target.value);
    // console.log(temp[i].batasPoin.toString().length - 1)
    // console.log(typeof (temp[i].batasPoin.toString()))
    if (temp[i].pointSoal > temp[i].batasPoin) {
      temp[i].pointSoal = "";
      console.log(temp[i].pointSoal);
      // temp[i].pointSoal.substring(1, temp[i].batasPoin.toString().length - 1)
      // iziToast.success({
      //   message: "success",
      // });
      alert("Point Soal TIdak boleh lebih besar dari batas nilai");
      // console.log(a)
    }
    // else{
    // temp[i].pointSoal.substring(1, temp[i].batasPoin.toString().length - 1)
    // setItem(temp);
    // }
    setItem(temp);
  };

  const submit = async () => {
    try {
      let res = await service.assignScore(props.item.ujianId, item);

      iziToast.success({
        message: "success",
      });

      TableWrapper.updateDataItem("view-user-latihan", { id: item.id }, item);
      TableWrapper.reload("view-user-latihan");

      onRequestClose();
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  return (
    ready && (
      <>
        <Dialog
          visible={visible}
          onRequestClose={() => closeForm()}
          style={{
            width: "1100px",
            height: "95vh",
            borderRadius: "5px"
          }}
        >
          <DialogHeader style={{ paddingBottom: "0px" }}>
              <div style={{ width: "100%" }}>
                  <div style={{ color: "blue", fontWeight: "bold", fontSize: "25px", width: "100%" }}>{name?.toUpperCase()}</div>
                  <div style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
                    NAMA PESERTA : {props.item?.namaUser}
                  </div>
                  <hr></hr>
              </div>
          </DialogHeader>
          <DialogContent>
            <div>
              {item.map((d, i) => (
                <>
                 <Card
                    style={{
                      width: "100%",
                      // marginTop: "5px",
                      marginBottom: "10px",
                      background: "#f2f2f2",
                      padding: "15px 20px"
                    }}
                  >
                     <Text style={{ fontSize: "20px", textAlign: "justify" }}>
                      <b>{i + 1}. {d.soalPertanyaan}</b>
                    </Text>
                  <div
                    id="excel"
                    name="excel"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    {d.jawabanPdfPraktek && (
                      <Button
                        id={`btnExcel-${i}`}
                        style={{ marginTop: "2%" }}
                        theme="primary"
                        themeType="contained"
                        onClick={(e) => openPDF(e, i)}
                        disabled={d.disabled}
                      >
                        <TextIconSpacing
                          iconAfter={true}
                          icon={
                            d.disabled ? (
                              <CircularProgress
                                id={`progressExcel-${i}`}
                                centered={false}
                              />
                            ) : null
                          }
                        >
                          LIhat Jawaban (.PDF)
                        </TextIconSpacing>
                      </Button>
                    )}

                    {d.jawabanExcelPraktek && (
                      <Button
                        id={`btnExcel-${i}`}
                        style={{ marginTop: "2%", marginLeft: "20px" }}
                        theme="primary"
                        themeType="contained"
                        onClick={(e) => downloadAttach(e, i)}
                        disabled={d.disabled}
                      >
                        <TextIconSpacing
                          iconAfter={true}
                          icon={
                            d.disabled ? (
                              <CircularProgress
                                id={`progressExcel-${i}`}
                                centered={false}
                              />
                            ) : null
                          }
                        >
                          Download JAWABAN (.xlsx)
                        </TextIconSpacing>
                      </Button>
                    )}
                  </div>

                  <div>
                    <Text style={{ color: "red" }}>
                    Maksimal Poin Nilai : {d.batasPoin}
                    </Text>
                    <Text type="body-1">{d.jawabanUjian}</Text>
                    <TextField
                      placeholder={t.translate("form.nilai")}
                      type="number"
                      max={d.batasPoin}
                      // maxLength={d.batasPoin.length}
                      min={0}
                      id={`examPoint-${i}`}
                      value={d.pointSoal}
                      onChange={(e) => changePoint(e, i)}
                    />
                  </div>
                  </Card>
                </>
              ))}
            </div>
          </DialogContent>

          <DialogFooter>
          <Button
            theme="secondary"
              themeType="outline"
              onClick={() => {
                onRequestClose();
              }}
            >
              <TextIconSpacing
                icon={<FontIcon iconClassName="mdi mdi-close" />}
                >
                  BATAL
                </TextIconSpacing>
            </Button>

            <Button
             theme="primary"
              themeType={"contained"}
              style={{ marginLeft: "10px",marginRight: "12px" }}
              onClick={() => {
                submit();
              }}
            >
               <TextIconSpacing
                  icon={<FontIcon iconClassName="mdi mdi-check-all" />}
                >
                  SIMPAN NILAI
                </TextIconSpacing>
            </Button>
          </DialogFooter>
        </Dialog>
        <ViewPDF
          visible={showPdf}
          onRequestClose={() => {
            setShowPdf(false);
            setSelectedItem(null);
          }}
          item={selectedItem}
        />

        {/* <Confirm
          visible={showExcel}
          onRequestClose={() => {
            setShowExcel(false);
            setSelectedItemExcel(null);
          }}
          onSubmitRequest={() => {
            getExcelItem(selectedItem);
            setShowExcel(false);
            setSelectedItemExcel(null);
          }}
          message={t.translate("message.materiExcel")}
        /> */}
      </>
    )
  );
};

export default inject("modalStore")(observer(ViewUserPraktek));
