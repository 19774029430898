import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import { TabsWrapper } from "../../libs/react-mpk/wrapper";
import ViewEssay from "./Essay/ViewEssay";
import ViewPraktekLatihan from "./Praktek/ViewPraktek";

const ContainerLatihan = ({
  className = "",
  navigationStore,
  envStore,
  ...props
}) => {
  let companyId = props.match.params.companyId
  let levelId = props.match.params.levelId
  let productId = props.match.params.productId
  let kelasId = props.match.params.kelasId
  return (
    <TabsWrapper
      title={"LATIHAN PESERTA"}
      baseId="view-soal-latihan"
      className={className}
      tabs={[
        {
          label: t.translate("tab.teori"),
          key: "view-essay-latihan",
          render: <ViewEssay showCommandbar={false} {...props}/>,
        },
        {
          label: t.translate("tab.praktek"),
          key: "view-praktek-latihan",
          render: <ViewPraktekLatihan showCommandbar={false} {...props}/>,
        },
      ]}
      onChange={(tab, callback) => {
        // navigationStore.redirectTo(`/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/latihan`);
        callback();
      }}
    />
    );
};

export default inject(
  "navigationStore",
  "envStore"
)(observer(ContainerLatihan));
