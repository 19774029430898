import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import Script from "react-load-script";
import routes from "./routes";
import "./App.scss";
import { init } from "./libs/react-mpk/hocs";
import qs from "query-string";

const App = ({ envStore, navigationStore }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  useEffect(() => {
    if (!checked && window.location.pathname === "/") {
      console.log(window.location.href);
      const parsedUrl = qs.parseUrl(window.location.href);
      navigationStore.redirectTo(
        `/${envStore.env.applicationType}${
          Object.keys(parsedUrl.query).length > 0
            ? `?${qs.stringify(parsedUrl.query)}`
            : ""
        }`
      );
      setChecked(true);
    }
  }, [checked]);

  return (
    <div className="App mpk-full viewport-height">
      {Object.keys(envStore.env.widgetInterface).map((key) => (
        <Script
          key={`widget-interface-${key}`}
          url={envStore.env.widgetInterface[key].url}
        />
      ))}
      <Router
        history={navigationStore.browserHistory}
        className="mpk-position relative"
      >
        {routes()}
      </Router>
      {/* {envStore.kbs && envStore.kbs.length > 0 ? (
        <SupportMpk appName={appInfo.name} kbs={kbs} />
      ) : null} */}
    </div>
  );
};

export default init()(App);
