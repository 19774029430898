import React, { useEffect, useState } from 'react';
import { DataForm } from '../../../libs/react-mpk/components';
import { cloneDeep, find } from 'lodash'
import t from 'counterpart'
import service from '../../../services/kelas.service';
import servicesUsers from '../../../services/assignUser.service';
import serviceRole from '../../../services/role.service';
import { TableWrapper } from '../../../libs/react-mpk/wrapper';

const defaultData = () => ({
    id: '',
    user: [],
})
const CreateAssignKelas = ({
    visible = false,
    onRequestClose = () => { },
    ...props
}) => {
    let [dataSubmit, setDataSubmit] = useState({
        id: '',
        user: [],
    })
    let [item, setItem] = useState(null)
    let [ready, setReady] = useState(true)
    let [role, setRole] = useState([]);
    let [users, setUsers] = useState([]);
    let [tempData, setTempData] = useState([])
    let [viewUsers, setViewUsers] = useState(false)

    useEffect(() => {
        serviceRole.getAllRole({ size: 100 }).then((res) => {
            setRole(res.data);
        });
        async function initData() {
            if (visible) {
                let selectedItem = defaultData();
                console.log(props)
                if (props.item) {
                    selectedItem.id = props.item.id                    
                }
                setItem(selectedItem)
            } else {
                setItem(defaultData())
            }
            setReady(visible)
        }
        initData()
    }, [visible])

    useEffect(() => {
        console.log(tempData)
    }, [tempData])


    const onSubmit = async (data, callback) => {
        // console.log(data)
        let reqData = dataSubmit;
        reqData.user = data.user
        console.log(reqData, "reqData")
        console.log(data.roles)
        if (data.roles === "ROLE_PENGAJAR") {
            try {
                const resp = await service.assignPengajar(reqData, data.id)
                // console.log(resp)
                TableWrapper.updateDataItem(
                    "view-kelas",
                    { id: data.id },
                    data
                );
                TableWrapper.reload("view-kelas");
                callback('Create Assign Pengajar Success', false)
            } catch (error) {
                // console.log(error.message)
                callback('Create Assign Pengajar Error ' + error.message, true)
            }
        }
        else if (data.roles === "ROLE_PESERTA") {
            try {
                const resp = await service.assignPeserta(reqData, data.id)
                // console.log(resp)
                TableWrapper.updateDataItem(
                    "view-kelas",
                    { id: data.id },
                    data
                );
                TableWrapper.reload("view-kelas");
                callback('Create Assign Peserta Success', false)
            } catch (error) {
                // console.log(error.message)
                callback('Create Assign Peserta Error ' + error.message, true)
            }
        }
    }

    return ready && (
        <DataForm
            baseId="create-assign-kelas"
            title={t.translate('modules.form.Assignkelas')}
            visible={visible}
            // data={item}
            onRequestClose={() => {
                setViewUsers(false);
                onRequestClose()
            }}
            asDialog={true}
            defaultData={item}
            definitions={[
                {
                    inputType: DataForm.inputTypes.SELECT,
                    label: t.translate("table.role"),
                    key: 'roles',
                    theme: "filled",
                    options: role.filter((d) => d.namaRole !== "ROLE_ADMIN").map((d) => ({
                        value: d.namaRole,
                        children: d.namaRole,
                    })),
                    validation: 'required'
                },
                {
                    inputType: DataForm.inputTypes.MULTI_CHECKBOX,
                    label: t.translate("table.namaUser"),
                    key: 'user',
                    theme: "filled",
                    options: users !== null ? users.map((d, i) => ({
                        value: d.id,
                        label: d.nama + " - " + d.email,
                    }))
                        : null,
                    show: (viewUsers),
                    validation: 'required'
                },
            ]}
            onChange={async(item, key, value) => {
                if (key === "roles") {
                    let temp = find(role, { namaRole: value });
                    setViewUsers(true);

                    servicesUsers.getUserAssign(temp.namaRole).then((res) => {
                        setUsers(res.data);
                    })

                    if (temp.namaRole === "ROLE_PENGAJAR") {
                        let res = await service.getPengajar(item.id)
                        let {data} = res
                        item.user = data.user
                    }
                    else if (temp.namaRole === "ROLE_PESERTA") {
                        let res = await service.getPeserta(item.id)
                        let {data} = res
                        item.user = data.user
                    }
                }
                return item;
            }}
            watchDefaultData={false}
            onSubmit={onSubmit}
        />
    )
}

export default CreateAssignKelas