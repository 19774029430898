import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import service from "../../services/soal.service";
import serviceType from "../../services/typeSoal.service";
import serviceMateri from "../../services/materi.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import http from "../../libs/react-mpk/services/http.service";

const defaultData = () => ({
  pertanyaan: "",
  tipeSoalId: "",
  materiPembelajaranId: "",
  kategoriSoal: null,
});
const CreateSoal = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  const kunci = [
    { id: "A", nama: "A" },
    { id: "B", nama: "B" },
    { id: "C", nama: "C" },
    { id: "D", nama: "D" },
  ];
  let [dataSubmit, setDataSubmit] = useState({
    id: "",
    pertanyaan: "",
    tipeSoalId: "",
    kategoriSoal: null,
    materiPembelajaranId: "",
  });
  let [item, setItem] = useState(defaultData());
  let [ready, setReady] = useState(true);
  let [typeSoal, setTypeSoal] = useState([]);
  let [materiPembelajaran, setMateriPembelajaran] = useState([]);
  let [isEssay, setisEssay] = useState(false);
  let [isMultiple, setisMultiple] = useState(false);
  let [isPraktek, setIsPraktek] = useState(false);

  useEffect(() => {
    if (props.type){
      serviceType.getTypeSoal({ size: 1000, ['namaTipeSoal.contains']: props.type }).then((res) => {
        setTypeSoal(res.data);
        // defaultData().tipeSoalId = res.data[0].id
      });
    }
    
    // serviceMateri.getMateri({ size: 1000 }).then((res) => {
    //   // console.log(res)
    //   setMateriPembelajaran(res.data);
    // });
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();
        // console.log(props)
        if (props.item) {
          try {
            let res = await service.getSoalById(props.item.id);
            let { data } = res;
            setDataSubmit(data);

            serviceMateri.getMateriByType(data.tipeSoal.namaTipe).then((result) => {
              console.log(result)
              setMateriPembelajaran(result.data);
            });
            if (data.tipeSoal.namaTipe === "Multiple Choice") {
              setisMultiple(true);
            } else if (data.tipeSoal.namaTipe === "Essay") {
              setisEssay(true);
            } else {
              setIsPraktek(true);
            }

            selectedItem = cloneDeep(props.item);
          } catch (e) {
            console.log(e)
            // onRequestClose()
          }
        }
        setItem(selectedItem);
      } else {
        setItem(defaultData());
      }
      setReady(visible);
    }
    initData();
  }, [visible]);

  const onSubmit = async (item, callback) => {
    let reqData = dataSubmit;
    if (reqData.id) {
      if (item.fileKunciJawaban) {
        try {
          let temp = {
            id: reqData.id,
            pertanyaan: item.pertanyaan,
            tipeSoalId: item.tipeSoalId,
            materiPembelajaranId: item.materiPembelajaranId,
            urutanSoal: item.urutanSoal,
            isAktif: item.isAktif,
            kategoriSoal: item.kategoriSoal
          };
          // delete data.id;
          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          item.namaTipe = data.tipeSoal;
          delete item["tipesoal"];
          delete item["kunciJawabanPraktek"];

          let kunciJawaban = item.fileKunciJawaban;
          delete item.fileKunciJawaban;

          let soal = {
            soal: JSON.stringify(temp),
          };

          // console.log(soal);

          let res = await http.upload(
            `/soal/praktek/${reqData.id}`,
            kunciJawaban,
            soal,
            config
          );

          let { data, status } = res;
          // if (status == 200) {
          TableWrapper.updateDataItem("soal-table", { id: data.id }, data);
          TableWrapper.reload("soal-table");
          callback("Create Success", false);
          // }
        } catch (err) {
          callback(err.message, true, false);
        }
      } else {
        try {
          const resp = await service.update(item, reqData.id);
          // console.log(resp)
          TableWrapper.updateDataItem("soal-table", { id: reqData.id }, item);
          TableWrapper.reload("soal-table");
          callback("Edit Success", false);
        } catch (error) {
          // console.log(error.message)
          callback("Edit Error " + error.message, true, false);
        }
      }
    } else {
      item.isAktif = true;
      if (item.kunciJawabanPraktek) {
        item.namaTipe = item.tipeSoal;
        delete item["tipesoal"];
        delete item["kunciJawaban"];
        try {
          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          let file = item.kunciJawabanPraktek;
          delete item.kunciJawabanPraktek;

          let soal = {
            dto: JSON.stringify(item),
          };

          let res = await http.upload("/soal/praktek", file, soal, config);

          let { data, status } = res;
          if (status == 200) {
            TableWrapper.updateDataItem("soal-table", { id: data.id }, data);
            TableWrapper.reload("soal-table");
          }
          callback("Create Success", false);
        } catch (err) {
          callback(err.message, true, false);
        }
      } else {
        try {
          const resp = await service.create(item);
          let {data} = resp
          // console.log(resp)
          // console.log(resp.data.namaTipe)
          TableWrapper.updateDataItem("soal-table", { id: data.id }, data);
          TableWrapper.reload("soal-table");
          callback("Create Success", false);
        } catch (error) {
          // console.log(error.message)
          callback("Create Error " + error.message, true, false);
        }
      }
    }
  };

  return (
    ready && (
      <DataForm
        baseId="create-soal"
        title={t.translate("modules.form.question")}
        visible={visible}
        // data={item}
        onRequestClose={() => {
          setDataSubmit({
            id: "",
            pertanyaan: "",
            kunciJawaban: "",
            tipeSoalId: "",
            materiPembelajaranId: "",
          });
          onRequestClose();
        }}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("table.nameType"),
            key: "tipeSoalId",
            theme: "filled",
            options: typeSoal.map((d) => ({
              value: d.id,
              children: d.namaTipe,
            })),
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.TEXTAREA,
            label: t.translate("table.pertanyaan"),
            key: "pertanyaan",
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("table.questionCategory"),
            key: "kategoriSoal",
            theme: "filled",
            options: [
              {value: "LATIHAN", children: t.translate("table.LATIHAN")},
              {value: "UJIAN", children: t.translate("table.UJIAN")},
            ],
            validation: !isMultiple && "required",
            show: !isMultiple,
          },
          // {
          //   inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
          //   label: t.translate("table.pointSoal"),
          //   key: "pertanyaan",
          //   validation: "required",
          // },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.jawabanA"),
            key: "jawabanA",
            validation: isMultiple ? "required" : null,
            show: isMultiple,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.jawabanB"),
            key: "jawabanB",
            validation: isMultiple ? "required" : null,
            show: isMultiple,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.jawabanC"),
            key: "jawabanC",
            validation: isMultiple ? "required" : null,
            show: isMultiple,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.jawabanD"),
            key: "jawabanD",
            validation: isMultiple ? "required" : null,
            show: isMultiple,
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("table.kunciJawaban"),
            key: "kunciJawaban",
            show: isMultiple,
            options: kunci.map((d) => ({
              value: d.id,
              children: d.nama,
            })),
            validation: isMultiple ? "required" : null,
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("table.nameMateri"),
            key: "materiPembelajaranId",
            theme: "filled",
            options: materiPembelajaran.map((d) => ({
              value: d.id,
              children: d.namaMateri,
            })),
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("table.queue"),
            key: "urutanSoal",
            show: isPraktek,
            validation: isPraktek && "required",
          },
          {
            inputType: DataForm.inputTypes.FILE_INPUT,
            label: t.translate("table.kunciJawaban"),
            key: "fileKunciJawaban",
            accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv",
            show: isPraktek,
            // validation: isPraktek && !props.item ? "required" : null,
          },
        ]}
        onChange={async(data, key, value) => {
          // console.log(key, value)
          if (key === "tipeSoalId") {
            // console.log(data, value);

            let currType = typeSoal.find(d => d.id == value)
            let res = await serviceMateri.getMateriByType(currType.namaTipe)
            let {data} = res
            setMateriPembelajaran(data)

            serviceType
              .getTypeSoalById(value)
              .then((res) => {
                // console.log(res.data.namaTipe);
                if (res.data.namaTipe === "Multiple Choice") {
                  setisMultiple(true);
                  setisEssay(false);
                  setIsPraktek(false);
                } else if (res.data.namaTipe === "Essay") {
                  setisEssay(true);
                  setisMultiple(false);
                  setIsPraktek(false);
                } else {
                  setIsPraktek(true);
                  setisEssay(false);
                  setisMultiple(false);
                }
              })
              .catch((e) => {
                // console.log(e)
              });

            
          }
          return data;
        }}
        watchDefaultData={false}
        onSubmit={onSubmit}
      />
    )
  );
};

export default CreateSoal;
