import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "../../services/userUjian.service";
import moment from "moment";

const ViewNilaiUjian = ({
  className = "",
  showCommandbar = true,
  ...props
}) => {
  let [search, setSearch] = useState({});
  let productId = props.match.params.productId;
  let companyId = props.match.params.companyId;
  let kelasId = props.match.params.kelasId;

  let userId = localStorage.getItem("getUserId");
  let role = localStorage.getItem("getRole");
  return (
    <>
      <TableWrapper
        baseId="view-nilai-ujian"
        title={t.translate("modules.table.nilaiUjian")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="id"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        showActionColumn={false}
        showFilter={false}
        // extraFilter={<div>Test</div>}
        // multiColumnFilter={true}
        columns={[
          {
            label: "Status",
            type: TableWrapper.dataTypes.BOOLEAN,
            searchable: false,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.isRilis
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.isRilis
                  ? t.translate("table.released")
                  : t.translate("table.unReleased")}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Nama Peserta",
            searchable: true,
            key: "namaUser",
            render: (item) => item.namaUser,
          },
          {
            label: t.translate("table.email"),
            searchable: true,
            key: "emailUser",
            render: (item) => item.emailUser,
          },
          {
            label: "Nilai Ujian",
            key: "nilaiLatihan",
            searchable: false,
            render: (item) =>
              (item.nilaiAwal != null && item.isRilis)
                ? item.nilaiAwal
                : "N/A",
          },
          {
            label: t.translate("table.judulUjian"),
            searchable: true,
            key: "namaUjian",
            render: (item) => item.judulUjian,
          },
        ]}
        itemActions={[]}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...search,
                ...query,
                userId: userId,
                levelProdukId: productId,
                "kodePerusahaan.equals": companyId,
                "kodeKelas.equals": kelasId
              };
              let getPage = await service.getUserUjianNilai(query);
              console.log(getPage);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              resolve(getPage);
            } catch (e) {
              reject(e);
              // ErrorService(e)
            }
          });
        }}
        // onFetchData={async query => {
        //   return service.getData()
        // }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
    </>
  );
};

export default ViewNilaiUjian;
