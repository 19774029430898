import { crud, http } from '../libs/react-mpk/services';

let service = null
let serviceUrl = '/level'
service = new crud('/level');

service.getLevel = async(query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query
  })
}

service.getLevelById = async(levelId, companyId) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${levelId}`,
    query : {
      kodePerusahaan: companyId
    }
  })
}

service.getAllLevel = async(query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query
  })
}

service.create = async(data)=> {
    return http.request({
      method: http.methods.POST,
      url: `${serviceUrl}`,
      data
    })
  }

  service.update = async(data, id)=> {
    return http.request({
      method: http.methods.PUT,
      url: `${serviceUrl}/${id}`,
      data
    })
  }
  
  service.deleteById = async (id)=>{
    return http.request({
      method: http.methods.DELETE,
      url: `${serviceUrl}/${id}`,
    })
  }
  
  service.getUserLevel = async (companyId)=>{
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/user`,
      query: {
        'kodePerusahaan.equals': companyId
      }
    })
  }
  
  service.getUserLevelProduk = async (levelId, companyId)=>{
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/user/${levelId}`,
      query: {
        'kodePerusahaan': companyId
      }
    })
  }

export default service;