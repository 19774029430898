import React, { Children, useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { PageWrapper } from "../../libs/react-mpk/wrapper";
import { sso } from "../../libs/react-mpk/hocs";
import t from 'counterpart';
import service from "../../services/assignUser.service";
import ViewLevel from "../../modules/level/ViewLevel";
import ViewRole from "../../modules/role/ViewRole";
import ViewKelas from "../../modules/Kelas/ViewKelas";
import ViewUserUjian from "../../modules/UserUjian/ViewUserUjian";
import ViewUserLatihan from "../../modules/UserLatihan/ViewUserLatihan";
import ContainerUser from "../../modules/Assign_User/ContainerUser";
import ViewLevelProduks from "../../modules/levelProduk/ViewLevelProduks";
import { FontIcon, List, ListItem } from "react-md";
import PageLevel from "../menu/level/pageLevel";
import ContainerSoal from "../../modules/Soal/ContainerSoal";
import ViewTypeSoal from "../../modules/Typesoal/ViewTypeSoal";
import ViewMateri from "../../modules/Materi_Pembelajaran/ViewMateri";
import ViewProduct from "../../modules/product/ViewProduct";
import ViewGrade from "../../modules/Grade/ViewGrade";
import UploadSign from "../../modules/Sign/UploadSign";
import CompanyGroup from "../../modules/Company/CompanyGroup";
import appVersionService from "../../services/appVersion.service";
import { role as roles } from "../../constant/appConstant";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

const Company = ({ modalStore, history, ...props }) => {
  const [appVersion, setAppVersion] = useState(null);
  let [role, setRole] = useState();
  let userRole = localStorage.getItem("getRole");
  
  let companyId = props.match.params.companyId;
  let chooseLevel;

  if (role === roles.ROLE_ADMIN) {
    chooseLevel = [
      {
        label: "Level",
        children: [
          {
            label: "Choose Level",
            index: "choose, choose",
            resourceUri: "",
            iconClassName: "mdi mdi mdi-view-dashboard",
            path: `/product/company/${companyId}/choose-level`,
            childPath: [],
          },
        ],
      },
      {
        label: "Materi Pembelajaran",
        children: [
          {
            label: "Materi Pembelajaran",
            index: "materi-pembelajaran, materi-pembelajaran",
            resourceUri: "",
            iconClassName: "mdi mdi-book-open-page-variant-outline",
            path: `/product/company/${companyId}/materi-pembelajaran`,
            childPath: [],
          },
        ],
      },
      {
        label: "Soal",
        children: [
          {
            label: "Soal",
            index: "soal, soal",
            resourceUri: "",
            iconClassName: "mdi mdi-help-network",
            path: `/product/company/${companyId}/exam`,
            childPath: [],
          },
          {
            label: "Type Soal",
            index: "tipeSoal, tipeSoal",
            resourceUri: "",
            iconClassName: "mdi mdi-help-box",
            path: `/product/company/${companyId}/type-soal`,
            childPath: [],
          },
        ],
      },
      {
        label: "Kelas",
        children: [
          {
            label: "Kelas",
            index: "kelas, kelas",
            resourceUri: "",
            iconClassName: "mdi mdi-google-classroom",
            path: `/product/company/${companyId}/kelas`,
            childPath: [],
          },
        ],
      },
      {
        label: "Lain-Lain",
        children: [
          {
            label: "Assign User",
            index: "assign, assign",
            resourceUri: "",
            iconClassName: "mdi mdi-account",
            path: `/product/company/${companyId}/assign-user`,
            childPath: [],
          },
          {
            label: "Level",
            index: "level, level",
            resourceUri: "",
            iconClassName: "mdi mdi-package-variant-closed",
            path: `/product/company/${companyId}/level`,
            childPath: [],
          },
          {
            label: "Level Produk",
            index: "levelProduk, levelProduk",
            resourceUri: "",
            iconClassName: "mdi mdi-package-variant",
            path: `/product/company/${companyId}/level-produk`,
            childPath: [],
          },
          {
            label: "Product",
            index: "product, product",
            resourceUri: "",
            iconClassName: "mdi mdi-package-variant-closed",
            path: `/product/company/${companyId}/product`,
            childPath: [],
          },
          {
            label: "Grade",
            index: "grade, grade",
            resourceUri: "",
            iconClassName: "mdi mdi-star-circle-outline",
            path: `/product/company/${companyId}/grade`,
            childPath: [],
          },
          {
            label: "Role",
            index: "role, role",
            resourceUri: "",
            iconClassName: "mdi mdi-shield-account-outline",
            path: `/product/company/${companyId}/role`,
            childPath: [],
          },
          {
            label: "Tanda Tangan",
            index: "tanda, Tanda, Tangan, tangan",
            resourceUri: "",
            iconClassName: "mdi mdi-draw",
            path: `/product/company/${companyId}/sign`,
            childPath: [],
          },
          {
            label: "Perusahaan",
            index: "perusahaan",
            resourceUri: "",
            iconClassName: "mdi mdi-domain",
            resourceUri: "",
            path: `/product/company/${companyId}/company`,
            childPath: [],
          },
        ],
      },
    ];
  } 
  else if (role === roles.ROLE_PENGAJAR) {
    chooseLevel = [
      {
        label: "Level",
        children: [
          {
            label: "Choose Level",
            index: "choose, choose",
            resourceUri: "",
            iconClassName: "mdi mdi mdi-view-dashboard",
            path: `/product/company/${companyId}/choose-level`,
            childPath: []
          }
        ]
      },
      {
        label: "Lain-Lain",
        children: [
          {
            label: "Assign User",
            index: "assign, assign",
            resourceUri: "",
            iconClassName: "mdi mdi-account",
            path: `/product/company/${companyId}/assign-user`,
            childPath: []
          }
        ]
      }
    ];
  } 
  else {
    // ROLE PESERTA
    chooseLevel = [
      {
        label: "Level",
        children: [
          {
            label: "Choose Level",
            index: "choose, choose",
            resourceUri: "",
            iconClassName: "mdi mdi mdi-view-dashboard",
            path: `/product/company/${companyId}/choose-level`,
            childPath: []
          }
        ]
      }
    ];
  }

  const getApplicationInfo = async () => {
    try {
      let res = await appVersionService.get();
      setAppVersion(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleApplicationInfo = () => {
    modalStore.showInfo({
      title: t.translate("mpk.sentence.applicationInfo"),
      children: (
        <>
          <div className="mpk-font size-L weight-B">
            Frontend Version
            {/* {appVersion && appVersion.frontendName} */}
          </div>
          <div className="mpk-font size-NS">
            v{appVersion && appVersion.frontendVersion}
          </div>
          <br></br>
          <div className="mpk-font size-L weight-B">
            Backend Version
            {/* {appVersion && appVersion.backendName} */}
          </div>
          <div className="mpk-font size-NS">
            v{appVersion && appVersion.backendVersion}
          </div>
        </>
      ),
    });
  };

  useEffect(() => {
    service.login({}).then((res) => {
      localStorage.setItem("getUserId", res.data.id);
      localStorage.setItem("getRole", res.data.authorities[0]);
      setRole(res.data.authorities[0]);
    });

    getApplicationInfo();
  }, []);

  let ctr = sessionStorage.getItem("ctr");

  if (!ctr) {
    ctr = 1;
    sessionStorage.setItem("ctr", ctr);
    window.location.reload();
  }

  return (
    <PageWrapper
      sidebarCollapsible={true}
      inverseTheme={true}
      appbar={{
        subname: `SIM-PJK v${appVersion && appVersion.frontendVersion}`,
        handleApplicationInfo: handleApplicationInfo,
      }}
      showFilterMenu={false}
      sidebarDefaultCollapse={true}
      sidebarMenu={chooseLevel}
      sidebarHeader={
        <>
          <List
            style={{
              width: "100%",
              background: "white",
              padding: "0px",
              height: "65px",
            }}
          >
            <ListItem
              style={{ marginTop: "-3px" }}
              secondaryText={`Kembali`}
              rightAddon={
                <FontIcon
                  onClick={() => {
                    window.location.href = `/product/index`;
                  }}
                  iconClassName="mdi mdi-arrow-left"
                ></FontIcon>
              }
            ></ListItem>
          </List>
        </>
      }
    >
      <Router history={history}>
        <Switch>
          {userRole === roles.ROLE_ADMIN || userRole === roles.ROLE_PENGAJAR || userRole === roles.ROLE_PESERTA ? (
            <Route
              path="/product/company/:companyId/choose-level"
              render={(props) => <PageLevel {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/choose-level">
               <Redirect to={"/error"} />
            </Route>
          )}

          {userRole === roles.ROLE_ADMIN ? (
            <Route
              path="/product/company/:companyId/materi-pembelajaran"
              exact={true}
              render={(props) => <ViewMateri {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/materi-pembelajaran">
               <Redirect to={"/error"} />
            </Route>
          )}
          
          {userRole === roles.ROLE_ADMIN ? (
            <Route
              path="/product/company/:companyId/exam"
              exact={true}
              render={(props) => <ContainerSoal {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/exam">
               <Redirect to={"/error"} />
            </Route>
          )}    

          {userRole === roles.ROLE_ADMIN ? (
            <Route
              path="/product/company/:companyId/type-soal"
              exact={true}
              render={(props) => <ViewTypeSoal {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/type-soal">
               <Redirect to={"/error"} />
            </Route>
          )}         

          {/* KELAS */}
          {userRole === roles.ROLE_ADMIN ? (
            <Route
              path="/product/company/:companyId/kelas"
              exact={true}
              render={(props) => <ViewKelas {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/kelas">
               <Redirect to={"/error"} />
            </Route>
          )} 
          

          {/* LAINNYA */}

          {/* ASSIGN USER */}
          {userRole === roles.ROLE_ADMIN || userRole === roles.ROLE_PENGAJAR ? (
            <Route
              path="/product/company/:companyId/assign-user"
              exact={true}
              render={(props) => <ContainerUser {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/assign-user">
               <Redirect to={"/error"} />
            </Route>
          )} 
          

          {/* User Latihan */}
          {/* <Route
            path='/product/company/:companyId/user-exercise'
            exact={true}
            render={props => (
              <ViewUserLatihan {...props} />
            )}
          /> */}

          {/* User Ujian */}
          {/* <Route
            path='/product/company/:companyId/user-exam'
            exact={true}
            render={props => (
              <ViewUserUjian {...props} />
            )}
          /> */}

          {/* KELAS */}
          {/* <Route
            path="/product/company/:companyId/kelas"
            exact={true}
            render={(props) => <ViewKelas {...props} />}
          /> */}

          {/* ROLE */}
          {userRole === roles.ROLE_ADMIN ? (
            <Route
              path="/product/company/:companyId/role"
              exact={true}
              render={(props) => <ViewRole {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/role">
               <Redirect to={"/error"} />
            </Route>
          )} 
          

          {/* PRODUK */}
          {userRole === roles.ROLE_ADMIN ? (
            <Route
              path="/product/company/:companyId/product"
              exact={true}
              render={(props) => <ViewProduct {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/product">
               <Redirect to={"/error"} />
            </Route>
          )} 

          {/* LEVEL */}
          {userRole === roles.ROLE_ADMIN ? (
            <Route
              path="/product/company/:companyId/level"
              exact={true}
              render={(props) => <ViewLevel {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/level">
               <Redirect to={"/error"} />
            </Route>
          )} 
          

          {/* LEVEL PRODUCT */}
          {userRole === roles.ROLE_ADMIN ? (
            <Route
              path="/product/company/:companyId/level-produk"
              exact={true}
              render={(props) => <ViewLevelProduks {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/level-produk">
               <Redirect to={"/error"} />
            </Route>
          )} 
          

          {/* GRADE */}
          {userRole === roles.ROLE_ADMIN ? (
            <Route
              path="/product/company/:companyId/grade"
              exact={true}
              render={(props) => <ViewGrade {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/grade">
               <Redirect to={"/error"} />
            </Route>
          )} 
          

          {/* TANDA TANGAN */}
          {userRole === roles.ROLE_ADMIN ? (
            <Route
              path="/product/company/:companyId/sign"
              exact={true}
              render={(props) => <UploadSign {...props} />}
            />
          ) : (
            <Route path="/product/company/:companyId/sign">
               <Redirect to={"/error"} />
            </Route>
          )} 
          

          {/* PERUSAHAAN LOGO */}
          {userRole === roles.ROLE_ADMIN ? (
            <Route
              path="/product/company/:companyId/company"
              exact={true}
              render={(props) => (
                <CompanyGroup className="company-table" {...props} />
              )}
            />
          ) : (
            <Route path="/product/company/:companyId/company">
               <Redirect to={"/error"} />
            </Route>
          )} 
          
        </Switch>
      </Router>
    </PageWrapper>
  );
};

// export default inject('navigationStore')(observer(Company))
export default sso({
  basePath: "/product/company/:companyId",
  url: {
    me: "/api/sso/company/:companyId/me",
  },
}, "modalStore")(Company);
