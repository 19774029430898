import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "../../services/levelProduk.service";
import Confirm from "../Confrimation/Confirm";
import moment from "moment";
import AssignMateri from "./AssignMateri";
import LevelProdukForm from "./LevelProdukForm";

const ViewLevelProduks = ({
  className = "",
  showCommandbar = true,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setEditShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  let [search, setSearch] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  let companyId = props.match.params.companyId;

  const showDeleteItem = (data) => {
    setShowDelete(true);
    setSelectedItem(data);
  };
  const deleteItem = (item) => {
    service.deleteById(item.id).then((res) => {
      TableWrapper.updateDataItem("view-level-produks", { id: item.id }, item);
      TableWrapper.reload("view-level-produks");
    });
  };
  return (
    <>
      <TableWrapper
        baseId="view-level-produks"
        title={t.translate("modules.table.levelProduk")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        // useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        // multiColumnFilter={true}
        columns={[
          {
            label: t.translate("table.nameLevel"),
            searchable: true,
            key: "namaLevel",
            render: (item) => item.namaLevel,
          },
          {
            label: t.translate("table.namaProduk"),
            searchable: true,
            key: "namaProduk",
            render: (item) => item.namaProduk,
          },
          {
            label: t.translate("table.dibuatOleh"),
            key: "dibuatOleh",
            render: (item) => item.dibuatOleh,
          },
          {
            label: t.translate("table.tglDibuat"),
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.tanggalDibuat).format("lll"),
          },
          {
            label: t.translate("table.diperbaharui"),
            key: "diperbaharuiOleh",
            render: (item) => item.diperbaharuiOleh,
          },
          {
            label: t.translate("table.tglDiperbaharui"),
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.tanggalDiperbaharui).format("lll"),
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Assign Materi",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setEditShowForm(true);
            },
            true
          ),
          // new TableWrapper.action(
          //     "Delete",
          //     "mdi mdi-delete",
          //     (item) => {
          //         showDeleteItem(item);
          //     },
          //     true
          // )
        ]}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                "kodePerusahaan.equals": companyId,
              };
              console.log(query);
              let getPage = await service.getLevelProduk(query);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              console.log(getPage);
              resolve(getPage);
            } catch (e) {
              reject(e);
              // ErrorService(e)
            }
          });
        }}
        // onFetchData={async query => {
        //     // let getPage = await service.getLevel(query)
        //     // service.getLevel(query).headers['x-pagination-count'] = service.getLevel(query).headers['x-total-count']
        //     console.log(query)
        //     return service.getLevel(query)
        // }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <Confirm
        visible={showDelete}
        onRequestClose={() => {
          setShowDelete(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          deleteItem(selectedItem);
          setShowDelete(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.levelDelete")}
      />
      {/* <CreateLevel
                visible={showForm}
                onRequestClose={() => {
                    setShowForm(false)
                    setSelectedItem(null)
                }}
                item={selectedItem}
            /> */}

      <AssignMateri
        visible={showEditForm}
        onRequestClose={() => {
          setEditShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />

      <LevelProdukForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />
    </>
  );
};

export default ViewLevelProduks;
