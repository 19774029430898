import { crud, http } from '../libs/react-mpk/services';

let dashboardService = new crud('/dashboard/user')
let serviceUrl = '/dashboard/user'

dashboardService.getUserLevel = async (levelProdukId,kelasId, companyId) => {
    let query = {
        "page": 0,
        "size": 9999,
        "levelProdukId.equals" : levelProdukId,
        "kelasId.equals" : kelasId,
        "kodePerusahaan.equals": companyId
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/level`,
        query
    })
}

dashboardService.getMaterial = async (levelProdukId, companyId) => {
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/materi_pembelajaran`,
        query: {
            'levelProdukId.equals': levelProdukId,
            "kodePerusahaan.equals": companyId
        }
    })
}

dashboardService.getScore = async (levelProdukId, companyId) => {
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/nilai_avg`,
        query: {
            'levelProdukId.equals': levelProdukId,
            "kodePerusahaan.equals": companyId

        }
    })
}

dashboardService.getProfile = async (levelProdukId) => {
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/profile`,
        query: {
            levelProdukId: levelProdukId,
        },
    })
}

export default dashboardService