import React, { useEffect, useState } from 'react'
import { Router, Switch, Route, Redirect } from "react-router-dom"
import Company from './Company.routes'
import Index from './Index.routes'
import { inject, observer } from 'mobx-react'
// import Homepage from '../../modules/Homepage/Homepage'
import Level from './Level.routes'
import LevelProduct from './LevelProduk.routes'
import LevelKelas from './Kelas.routes'
import Materi from './materi.routes'
import Dashboard from '../../modules/Dashboard/Dashboard'

const Product = ({ history, navigationStore }) => {
  let token = localStorage.getItem("accessToken")
  // console.log(token)

  if (window.location.pathname === '/product')
  setTimeout(() => navigationStore.redirectTo('/product/index'), 500)
    // if (token !== null) {
    // } else {
    //   setTimeout(() => navigationStore.redirectTo('/product/home-page'), 500)
    // }
  return (
    <Router history={history}>
      <Switch>
        {/* <Route
          path='/product/home-page'
          render={props => (
            <Homepage {...props} />
          )}
        /> */}
        <Route
          path='/product/index'
          render={props => (
            <Index {...props} />
          )}
        />
        <Route
          path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId/assign-nilai/:materiId'
          render={props => (
            <Materi {...props} />
          )}
        />
        
        <Route
          path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class/:kelasId'
          render={props => (
            <LevelKelas {...props} />
          )}
        />
        <Route
          path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId'
          render={props => (
            <LevelProduct {...props} />
          )}
        />
        <Route
          path='/product/company/:companyId/choose-level/:levelId'
          render={props => (
            <Level {...props} />
          )}
        />
        <Route
          path='/product/company/:companyId'
          render={props => (
            <Company {...props} />
          )}
        />

      </Switch>
    </Router>
  )
}

export default inject('navigationStore')(observer(Product))