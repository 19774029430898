import React from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { PageWrapper } from '../../libs/react-mpk/wrapper'
import { sso } from '../../libs/react-mpk/hocs'
import PageClass from '../menu/level/pageClass'
import { FontIcon, List, ListItem } from 'react-md'
import { Redirect } from 'react-router-dom/cjs/react-router-dom'
import { role as roles } from "../../constant/appConstant";

// let companyId = sessionStorage.getItem("companyId")

const LevelProduct = ({ history, ...props }) => {
    let role = localStorage.getItem("getRole")
    let companyId = props.match.params.companyId
    let levelId = props.match.params.levelId
    let productId = props.match.params.productId
    let chooseClass

    if (role === "ROLE_ADMIN") {
        chooseClass = [
            {
                label: "Pilih Kelas",
                children: [
                    {
                        label: "Choose Class",
                        index: "choose, choose",
                        resourceUri: "",
                        iconClassName: "mdi mdi mdi-view-dashboard",
                        path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class`,
                        childPath: [],
                    },
                ],
            },
        ]
    }
    else if (role === "ROLE_PENGAJAR") {
        chooseClass = [
            {
                label: "Pilih Kelas",
                children: [
                    {
                        label: "Choose Class",
                        index: "choose, choose",
                        resourceUri: "",
                        iconClassName: "mdi mdi mdi-view-dashboard",
                        path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class`,
                        childPath: [],
                    },
                ],
            },
        ]
    }
    else {
        chooseClass = [
            {
                label: "Pilih Kelas",
                children: [
                    {
                        label: "Choose Class",
                        index: "choose, choose",
                        resourceUri: "",
                        iconClassName: "mdi mdi mdi-view-dashboard",
                        path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class`,
                        childPath: [],
                    },
                ],
            }
        ]
    }
    return (
        <PageWrapper
            sidebarCollapsible={true}
            inverseTheme={true}
            // useSidebar={false}
            // sidebarMenu={chooseKelas}
            sidebarMenu={chooseClass}
            sidebarHeader={(
                <>
                    <List style={{ width: '100%', background: 'white', padding: '0px', height: '65px' }}>
                        <ListItem style={{ marginTop: '-3px' }} secondaryText={`Kembali`} rightAddon={<FontIcon onClick={() => {
                            window.location.href = `/product/company/${companyId}/choose-level/${levelId}/choose-product`
                        }} iconClassName="mdi mdi-arrow-left"></FontIcon>}>
                        </ListItem>
                    </List>
                </>
            )}

        >
            <Router history={history}>
                <Switch>
                    {role === roles.ROLE_ADMIN || role === roles.ROLE_PENGAJAR || role === roles.ROLE_PESERTA ? (
                        <Route
                            path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class'
                            exact={true}
                            render={props => (
                                <PageClass {...props} />
                            )}
                        />
                    ) : (
                        <Route path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class'>
                        <Redirect to={"/error"} />
                        </Route>
                    )} 
                </Switch>
            </Router>
        </PageWrapper>
    )
}

export default sso({
    basePath: '/product/company/:companyId',
    url: {
        me: `/api/sso/company/:companyId/me`
    }
})(LevelProduct)
