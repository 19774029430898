import React, { useState } from 'react'
import Modal from './Modal';
import { Button, TextIconSpacing } from 'react-md';
import LoadingButton from '../LoadingButton'
import t from 'counterpart';

function Confirm(props) {
  let {
    baseId          = 'react-mpk-modal-confirm',
    onRequestClose  = () => {console.warn('[REACT-MPK][MODAL-CONFIRM] on request close is not yet define')},
    onSubmit        = null,
    submitLabel     = null,
    cancelLabel     = null,
    showCancel      = true,
    ...dialogProps
  } = props;

  const [inProgress, setInProgress] = useState(false)

  const handleSubmit = (params) => {
    setInProgress(true)
    onSubmit((callback) => {
      setInProgress(false)
    })
  }

  const handleClose = (isCancel=false) => {
    onRequestClose(isCancel)
  }

  return (
    <Modal 
      style={{
        borderRadius: "5px"
      }}
      modal={true}
      onRequestClose={() => handleClose(true)}
      footer={(
        <>
          {showCancel && (
            <Button 
              id={`${baseId}-no`}
              onClick={() => handleClose(true)}
              themeType="outline"
              disabled={inProgress}
            >
              { cancelLabel || t.translate('mpk.column.no')}
            </Button>
          )}
          
          {onSubmit && (
            <LoadingButton 
              id={`${baseId}-yes`}
              onClick={handleSubmit}
              theme={ inProgress ? 'disabled' : 'primary'}
              themeType="contained"
              disabled={inProgress}
              loading={inProgress}
              style={{
                position: 'sticky',
                marginLeft: 9,
              }}
            >
              <TextIconSpacing>
                { submitLabel || t.translate('mpk.column.yes')}
              </TextIconSpacing>
            </LoadingButton>
          )}
        </>
      )}
      {...dialogProps}
    />
  )
}

export default Confirm;