import { crud, http } from "../libs/react-mpk/services";

let service = null;
let serviceUrl = "/soal";
service = new crud("/soal");

service.getSoal = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.getSoalById = async (Id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${Id}`,
  });
};

service.getSoalBytipe = async (query, tipeSoal) => {
  // let query = {
  //   page: 0,
  //   size: 9999,
  //   sort: "id,DESC",
  //   "namaTipeSoal.contains": tipeSoal,
  // };

  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.create = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

service.update = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

service.deleteById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

service.activateList = async (item) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/aktivasi`,
    data: item,
  });
};

service.uploadSoal = async (id, file) => {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    // params: {
    //   namaFile: fileGuid,
    //   totalCounter: chunkCount,
    //   nowCounter: counter
    // },
  };
  return http.upload(`${serviceUrl}/upload_soal/${id}`, file, {}, config);
};

service.downloadSoal = async (id, filename) => {
  return http.download(
    `${serviceUrl}/download_soal/${id}`,
    {},
    {},
    { fileName: filename, fileType: filename.split(".")[1] }
  );
};

export default service;
