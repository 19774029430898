import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep, find } from "lodash";
import t from "counterpart";
import service from "../../services/latihan.service";
import servicesUsers from "../../services/assignUser.service";
import serviceRole from "../../services/role.service";
import services from "../../services/levelProduk.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import moment from "moment";

const defaultData = () => ({
  id: "",
  materiPembelajaranId: "",
  materiPembelajaran: {},
  roles: "",
  userId: "",
  user: {},
  tanggalAkhirLatihan: "",
  jamMulaiLatihan: "",
  tanggalAwalLatihan: "",
  jamSelesaiLatihan: "",
  isPraktek: false,
  isPg: false,
  isEssay: false,
});
const CreateLatihanEssay = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [dataSubmit, setDataSubmit] = useState({
    id: "",
    materiPembelajaranId: "",
    userId: "",
    tanggalAkhirLatihan: "",
    tanggalAwalLatihan: "",
  });
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [role, setRole] = useState([]);
  let [users, setUsers] = useState([]);
  let [materiPembelajaran, setMateriPembelajaran] = useState([]);
  let [viewUsers, setViewUsers] = useState(false);
  let productId = props.match.params.productId;
  let companyId = props.match.params.companyId;
  let kelasId = props.match.params.kelasId;

  useEffect(() => {
    // serviceRole.getAllRole({ size: 1000 }).then((res) => {
    //     setRole(res.data);
    // });
    if(visible){
      if (props.type) {
        services
          .getUserMateri(productId, {
            size: 1000,
            metodePembelajaran: props.type,
          })
          .then((res) => {
            let temp = [];
            res.data.map((d) => {
              temp.push({
                value: d.id,
                label: d.namaMateri + " - (" + d.metodePembelajaran + ")",
              });
            });
            setMateriPembelajaran(temp);
            // setMateriPembelajaran(res.data);
          });
      }
  
      servicesUsers.getUserAssign("ROLE_PESERTA").then((res) => {
        let temp = [];
        res.data.map((d) => {
          temp.push({
            value: d.id,
            label: `${d.nama} - (${d.email})`,
          });
        });
        setUsers(temp);
        // setUsers(res.data);
      });
    }
    
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();
        // console.log(props)
        if (props.item) {
          try {
            let res = await service.getLatihanById(props.item.id);
            // console.log("res12", res.data)
            let { data } = res;
            setDataSubmit(data);
            console.log(data);
            // if (res.data.tipeSoal.namaTipe === "Multiple Choice") {
            //     console.log('ess');
            //     // setisEssay(true)
            //     setisMultiple(true)
            // }
            // else {
            //     // setisEssay(false)
            //     setisMultiple(false)
            // }
            selectedItem.materiPembelajaranId =
              data.materiPembelajaran.namaMateri;
            selectedItem.materiPembelajaran = data.materiPembelajaran.id;

            // selectedItem.userId = data

            selectedItem.id = data.id;
            selectedItem.tanggalAkhirLatihan =
              data.tanggalAkhirLatihan &&
              moment(new Date(data.tanggalAkhirLatihan)).format("YYYY-MM-DD");
            selectedItem.tanggalAwalLatihan =
              data.tanggalAwalLatihan &&
              moment(new Date(data.tanggalAwalLatihan)).format("YYYY-MM-DD");

            selectedItem.jamMulaiLatihan =
              data.tanggalAwalLatihan &&
              moment(new Date(data.tanggalAwalLatihan)).format("HH:mm");

            selectedItem.jamSelesaiLatihan =
              data.tanggalAkhirLatihan &&
              moment(new Date(data.tanggalAkhirLatihan)).format("HH:mm");
            // console.log(selectedItem)
          } catch (e) {
            // onRequestClose()
          }
        }
        setItem(selectedItem);
      } else {
        setItem(defaultData());
      }
      setReady(visible);
    }
    initData();
  }, [visible]);

  const onSubmit = async (data, callback) => {
    let reqData = dataSubmit;
    reqData.materiPembelajaranId = data.materiPembelajaran;
    reqData.userId = data.user;
    let temp = new Date(data.tanggalAwalLatihan + " " + data.jamMulaiLatihan);
    let temp2 = new Date(
      data.tanggalAkhirLatihan + " " + data.jamSelesaiLatihan
    );
    reqData.tanggalAwalLatihan = moment(temp).format("YYYY-MM-DD HH:mm:ss");
    reqData.tanggalAkhirLatihan = moment(temp2).format("YYYY-MM-DD HH:mm:ss");
    reqData.kodePerusahaan = companyId;
    reqData.kodeKelas = kelasId
    // console.log("reqData", reqData)

    if (reqData.id) {
      try {
        // delete reqData.materiPembelajaran
        // console.log(JSON.stringify(reqData))
        const resp = await service.update(reqData, data.id);
        TableWrapper.updateDataItem(props.tableId, { id: data.id }, data);
        TableWrapper.reload(props.tableId);
        callback("Edit Success", false);
      } catch (error) {
        // console.log(error.message)
        callback("Edit Error " + error.message, true);
      }
    } else {
      try {
        const resp = await service.createLatihan(reqData, data.user);

        TableWrapper.updateDataItem(props.tableId, { id: data.id }, data);
        TableWrapper.reload(props.tableId);
        callback("Create Success", false);
      } catch (error) {
        console.log(error);
        callback("Create Error " + error.message, true, false);
      }
    }
  };

  return (
    ready && (
      <DataForm
        baseId="create-soal-latihan"
        title={t.translate("modules.form.latihan")}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("table.namaUser"),
            key: "userId",
            labelKey: "label",
            valueKey: "label",
            data: users,
            // theme: "filled",
            // options:
            //   users !== null
            //     ? users.map((d, i) => ({
            //         value: d.id,
            //         children: d.nama,
            //       }))
            //     : null,
            validation: "required",
            validation: props.item ? null : "required",
            show: props.item ? false : true,
          },
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("table.nameMateri"),
            key: "materiPembelajaranId",
            labelKey: "label",
            valueKey: "label",
            data: materiPembelajaran,
            // theme: "filled",
            // options: materiPembelajaran.map((d) => ({
            //   value: d.id,
            //   children: d.namaMateri + " - (" + d.metodePembelajaran + ")",
            // })),
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.DATE,
            label: t.translate("table.tglMulai"),
            key: "tanggalAwalLatihan",
            // type: "date",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.jamMulaiUjian"),
            key: "jamMulaiLatihan",
            type: "time",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.DATE,
            label: t.translate("table.tglSelesai"),
            key: "tanggalAkhirLatihan",
            // type: "date",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.jamSelesai"),
            key: "jamSelesaiLatihan",
            type: "time",
            required: true,
          },
          // {
          //     inputType: DataForm.inputTypes.PRE_DATA
          // }
        ]}
        onChange={(data, key, value) => {
          // if (key === "roles") {
          //   let temp = find(role, { namaRole: value });
          //   setViewUsers(true);

          //   servicesUsers.getUserAssign(temp.namaRole).then((res) => {
          //     setUsers(res.data);
          //   });
          // }
          if (key == "userId") {
            let userDetail = find(users, { label: value });
            data.user = userDetail ? userDetail.value : null;
          }
          if (key == "materiPembelajaranId") {
            let materiPembelajaranDetail = find(materiPembelajaran, {
              label: value,
            });
            data.materiPembelajaran = materiPembelajaranDetail
              ? materiPembelajaranDetail.value
              : null;
          }
          return data;
        }}
        watchDefaultData={false}
        onSubmit={onSubmit}
      />
    )
  );
};

export default CreateLatihanEssay;
