import { crud, http } from '../libs/react-mpk/services';

let service = null
let serviceUrl = '/produk'
service = new crud('/produk');

service.getProduct = async(query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query
  })
}

service.getProductById = async(Id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${Id}`
  })
}

service.create = async(data)=> {
    return http.request({
      method: http.methods.POST,
      url: `${serviceUrl}`,
      data
    })
  }

  service.update = async(data, id)=> {
    return http.request({
      method: http.methods.PUT,
      url: `${serviceUrl}/${id}`,
      data
    })
  }
  
  service.deleteById = async (id)=>{
    return http.request({
      method: http.methods.DELETE,
      url: `${serviceUrl}/${id}`,
    })
  }
export default service;