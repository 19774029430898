import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import iziToast from "izitoast";
import http from "../../libs/react-mpk/services/http.service";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "react-md";

const defaultData = () => ({
  string: "",
  number: 0,
  boolean: false,
  multiCheckbox: [""],
});

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewPDF = ({ visible = false, onRequestClose = () => {}, ...props }) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(false);
  let [pdf, setPdf] = useState(null);
  let [type, setType] = useState("");
  let [scale, setScale] = useState(1.0);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const initItem = async () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        try {
          if (props.item.pathMateri) {
            let config = Object.assign({
              method: "get",
              responseType: "arraybuffer",
            });

            let options = Object.assign({
              fileType: null,
              fileName: props.item.pathMateri,
            });

            let res = await (config.method === "post"
              ? http.post(
                  `/materipembelajaran/pdf/${props.item.id}`,
                  item,
                  config
                )
              : http.get(
                  `/materipembelajaran/pdf/${props.item.id}`,
                  {
                    fileName: props.item.pathMateri
                  },
                  config
                ));
            let fileName =
              options.fileName ||
              res.headers["content-disposition"].split("filename=")[1];
            let type =
              options.fileType ||
              res.headers["content-type"].substring(
                0,
                res.headers["content-type"].indexOf(";")
              );

            let { data } = res;

            // let base64 = new Uint8Array(data);
            // let base64 = btoa(String.fromCharCode(...new Uint8Array(data)));
            const blob = new Blob([res.data], { type: type });

            let uri = URL.createObjectURL(blob);

            setPdf(uri);
            setType(type);
          }
        } catch (err) {
          iziToast.error({
            message: err.message,
          });
        }
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(true);
  };

  useEffect(() => {
    initItem();
  }, [visible]);

  return (
    visible && (
      <DataForm
        baseId="video-player-dialogue"
        // title={t.translate("modules.form.viewPdf")}
        title={(<div style={{ textTransform: "uppercase"}}>Materi Pembelajaran<hr></hr></div>)}
        visible={visible}
        // data={item}
        onRequestClose={() => {
          setPdf(null);
          setPageNumber(1);
          onRequestClose();
        }}
        asDialog={true}
        defaultData={props.item || defaultData()}
        style={{ maxWidth: "100%" }}
        definitions={[
          {
            render: ready ? (
              <div style={{ textAlign: "center" }}>
                <div className="controls" style={{marginBottom: "20px"}}>
                  <Button themeType="outline" onClick={prevPage} disabled={pageNumber === 1}
                    style={{marginRight: "10px"}}>
                    Prev
                  </Button>

                  <Button theme="primary" themeType={pageNumber === numPages ? "outline" : "contained"} onClick={nextPage} disabled={pageNumber === numPages}
                    style={{marginRight: "10px"}}>
                    Next
                  </Button>

                  <Button theme="secondary" themeType="outline" onClick={() => setScale(scale + 0.5)}
                    style={{marginRight: "10px"}}>
                    Zoom In
                  </Button>

                  <Button
                    theme="secondary"
                    themeType="outline"
                    onClick={() => setScale(scale - 0.5)}
                    disabled={scale == 0.5}
                  >
                    Zoom Out
                  </Button>
                </div>

                {pdf && (
                  <div style={{ border: "2px solid black"}}>
                    <Document
                      onLoadSuccess={onDocumentLoadSuccess}
                      file={pdf}
                      onContextMenu={(e) => e.preventDefault()}
                    >
                      <Page pageNumber={pageNumber} scale={scale}/>
                    </Document>
                  </div>
                  
                )}
                {/* <VideoPlayer
                  // src={}
                  poster={video.poster}
                  width="720"
                  height="420"
                  
                /> */}
              </div>
            ) : (
              <> Loading Data... </>
            ),
          },
        ]}
        onBeforeChange={(key, value) => {
          return value;
        }}
        onSubmit={null}
      />
    )
  );
};

export default ViewPDF;