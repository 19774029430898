import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import service from "../../services/remedy.service";
import servicesUsers from "../../services/assignUser.service";
import levelProdukService from "../../services/levelProduk.service";
import ujianService from "../../services/ujian.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import moment from "moment";

const defaultData = () => ({
  id: "",
  materiPembelajaranId: "",
  userId: "",
  durasiUjian: "",
  tanggalMulaiUjian: "",
});

const RemedyForm = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState(defaultData());
  let [ready, setReady] = useState(true);
  let [dataSubmit, setDataSubmit] = useState({
    id: "",
    materiPembelajaranId: "",
    userId: "",
    durasiUjian: "",
    tanggalMulaiUjian: "",
  });
  let [users, setUsers] = useState([]);
  let [materiPembelajaran, setMateriPembelajaran] = useState([]);
  // let productId = props && props.match.params.productId;
  let productId = props.match.params.productId;
  let companyId = props.match.params.companyId;
  let kelasId = props.match.params.kelasId;

  const initData = async () => {
    if (visible) {
      let selectedItem = defaultData();
      // console.log(props.productId)
      if (props.item) {
        try {
          if (props.type === "create") {
            let res = await ujianService.getUjianById(props.item.ujianId);

            let { data } = res;
            selectedItem.userId = props.item.userId;
            selectedItem.materiPembelajaranId = data.materiPembelajaranId;
            // console.log(data);
          } else {
            let res = await ujianService.getUjianById(props.item);
            let { data } = res;
            // console.log(data)

            selectedItem.id = data.id;
            selectedItem.userId = props.item.userId;
            selectedItem.materiPembelajaranId = data.materiPembelajaranId;
            selectedItem.durasiUjian = data.durasiUjian
            selectedItem.tanggalMulaiUjian = moment(
              data.tanggalMulaiUjian
            ).format("YYYY-MM-DD");
            selectedItem.jamMulaiUjian = moment(data.tanggalMulaiUjian).format(
              "HH:mm"
            );

            setDataSubmit(data);
          }
        } catch (err) {}
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    if(visible){
      servicesUsers.getUserAssign().then((res) => {
        setUsers(res.data);
      });
  
      if (props.metodePembelajaran){
        levelProdukService.getUserMateri(productId, { size: 1000, metodePembelajaran: props.metodePembelajaran }).then((res) => {
          setMateriPembelajaran(res.data);
        });
      }
    }
   
    initData();
  }, [visible]);

  const submitHandler = async (item, callback) => {

    let reqData = dataSubmit;
    
    reqData.durasiUjian = item.durasiUjian;
    let temp = new Date(item.tanggalMulaiUjian + " " + item.jamMulaiUjian);
    reqData.tanggalMulaiUjian = moment(temp).format("YYYY-MM-DD HH:mm:ss");
    reqData.materiPembelajaranId = item.materiPembelajaranId
    reqData.userId = item.userId
    reqData.companyId = companyId
    reqData.kodeKelas = kelasId

    try {
      if (props.type === "create") {
        let res = await service.createRemedy(reqData, props.item.ujianId);
        TableWrapper.updateDataItem(props.tableId, { id: item.id }, item);
        TableWrapper.reload(props.tableId);
        callback("success", false);
      } else {
        let res = await ujianService.update(reqData, item.id);

        TableWrapper.updateDataItem(props.tableId, { id: item.id }, item);
        TableWrapper.reload(props.tableId);
        callback("success", false);
      }
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  return (
    ready && (
      <DataForm
        baseId="remedy-form"
        title="Remedy Form"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("table.namaUser"),
            key: "userId",
            theme: "filled",
            options:
              users !== null
                ? users.map((d, i) => ({
                    value: d.id,
                    children: d.nama,
                  }))
                : null,
            validation: props.item ? null : "required",
            disabled: true,
            show: props.item && props.item.userId ? true : false
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("table.nameMateri"),
            key: "materiPembelajaranId",
            theme: "filled",
            options: materiPembelajaran.map((d) => ({
              value: d.id,
              children: d.namaMateri + " - (" + d.metodePembelajaran + ")",
            })),
            validation: props.item ? null : "required",
            disabled: true,
            show: props.item && props.item.userId ? true : false

          },
          {
            inputType: DataForm.inputTypes.DATE,
            label: t.translate("table.tanggalMulaiUjian"),
            key: "tanggalMulaiUjian",
            // type: "date",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.jamMulaiUjian"),
            key: "jamMulaiUjian",
            type: "time",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("table.durasiUjian"),
            key: "durasiUjian",
            required: true,
          },
        ]}
        onChange={(data, key, value) => {
          return value;
        }}
        onSubmit={submitHandler}
      />
    )
  );
};

export default RemedyForm;
