import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import { TabsWrapper } from "../../libs/react-mpk/wrapper";
import ExamTheory from "./ExamTheory";
import ExamPractice from "./ExamPractice";

const ContainerUjian = ({
  className = "",
  navigationStore,
  envStore,
  ...props
}) => {
  let companyId = props.match.params.companyId
    let levelId = props.match.params.levelId
    let productId = props.match.params.productId
    let kelasId = props.match.params.kelasId
  return (
    <TabsWrapper
        title={"UJIAN PESERTA"}
      baseId="view-soal"
      className={className}
      tabs={[
        {
          label: t.translate("tab.teori"),
          key: "view-multiple",
          render: <ExamTheory showCommandbar={false} {...props}/>,
        },
        {
          label: t.translate("tab.praktek"),
          key: "view-praktek",
          render: <ExamPractice showCommandbar={false} {...props}/>,
        },
      ]}
      onChange={(tab, callback) => {
        // navigationStore.redirectTo(`/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/ujian`);
        callback();
      }}
    />
  );
};

export default inject(
  "navigationStore",
  "envStore"
)(observer(ContainerUjian));
