import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import { cloneDeep, find } from 'lodash'
import t from 'counterpart'
import service from '../../services/kelas.service';
import services from '../../services/levelService';
import { TableWrapper } from '../../libs/react-mpk/wrapper';

const defaultData = () => ({
    id: '',
    namaKelas: '',
    lokasiPelatihan: '',
    perusahaanId: '',
    levelProdukId: '',
})
const CreateKelas = ({
    visible = false,
    onRequestClose = () => { },
    ...props
}) => {
    const lokasi = [
        { id: "Jakarta", nama: "Jakarta" },
        { id: "Bandung", nama: "Bandung" },
        { id: "Denpasar", nama: "Denpasar" }
    ];
    let [dataSubmit, setDataSubmit] = useState({
        id: '',
        namaKelas: '',
        lokasiPelatihan: '',
        perusahaanId: '',
        levelId: '',
        levelProdukId: '',
    })
    let [item, setItem] = useState(null)
    let [ready, setReady] = useState(true)
    let [level, setLevel] = useState([]);
    let [produks, setProduk] = useState([]);
    let [viewProduk, setViewProduk] = useState(false)
    let companyId = props.match.params.companyId

    useEffect(() => {
        services.getAllLevel({ size: 1000, "kodePerusahaan.equals": props.match.params.companyId }).then((res) => {
            console.log(res)
            setLevel(res.data);
        });
        async function initData() {
            if (visible) {
                let selectedItem = defaultData();
                console.log(props)
                if (props.item) {
                    // console.log(props.item)
                    try {
                        service.getKelasById(props.item.id).then((res) => {
                            console.log("abcd",res.data)
                            if(res.data.levelId){
                                setViewProduk(true)
                                services.getLevelById(res.data.levelId, props.match.params.companyId).then((res) => {
                                    setProduk(res.data.levelProduks);
                                })
                            }
                            setDataSubmit(res);
                        }).catch((e) => {
                            // console.log(e)
                        })
                        selectedItem = cloneDeep(props.item)
                        selectedItem.number = String(selectedItem.number)
                    } catch (e) {
                        // onRequestClose()
                    }
                }
                setItem(selectedItem)
            } else {
                setItem(defaultData())
            }
            setReady(visible)
        }
        initData()
    }, [visible])
    
    
    const onSubmit = async (data, callback) => {
        // console.log(data)
        let reqData = dataSubmit;
        data.kodePerusahaan = companyId
        if (reqData && reqData.data && reqData.data.id) {
            try {
                const resp = await service.update(data, data.id)
                // console.log(resp)
                TableWrapper.updateDataItem(
                    "view-kelas",
                    { id: data.id },
                    data
                );
                TableWrapper.reload("view-kelas");
                callback('Edit Success', false)
            } catch (error) {
                // console.log(error.message)
                callback('Edit Error ' + error.message, true)
            }
        }
        else {
            // console.log(data)
            try {
                const resp = await service.create(data)
                // console.log(resp)
                TableWrapper.updateDataItem(
                    "view-kelas",
                    { id: data.id },
                    data
                );
                TableWrapper.reload("view-kelas");
                callback('Create Success', false)
            } catch (error) {
                // console.log(error.message)
                callback('Create Error ' + error.message, true)
            }
        }
    }

    return ready && (
        <DataForm
            baseId="create-kelas"
            title={t.translate('modules.form.kelas')}
            visible={visible}
            style={{width: "60vh"}}
            // data={item}
            onRequestClose={onRequestClose}
            asDialog={true}
            defaultData={props.item || defaultData()}
            definitions={[
                {
                    inputType: DataForm.inputTypes.INPUT,
                    label: t.translate("table.namaKelas"),
                    key: 'namaKelas',
                    required: true,
                    validation : 'required'
                },
                {
                    inputType: DataForm.inputTypes.SELECT,
                    label: t.translate("table.lokasiPelatihan"),
                    key: 'lokasiPelatihan',
                    // theme: "filled",
                    options: lokasi.map((d) => ({
                        value: d.id,
                        children: d.nama,
                    })),
                    required: true,
                    validation: 'required'
                },
                // {
                //     inputType: DataForm.inputTypes.INPUT,
                //     label: t.translate("table.companyName"),
                //     key: 'perusahaanId',
                //     validation: 'required'
                // },
                {
                    inputType: DataForm.inputTypes.SELECT,
                    label: t.translate("table.namaLevel"),
                    key: 'levelId',
                    // theme: "filled",
                    options: level.map((d) => ({
                        value: d.id,
                        children: d.namaLevel,
                    })),
                    required: true,
                    validation: 'required'
                },
                {
                    inputType: DataForm.inputTypes.SELECT,
                    label: t.translate("table.namaProduk"),
                    key: 'levelProdukId',
                    // theme: "filled",
                    options: produks !== null ? produks.map((d) => ({
                        value: d.id,
                        children: d.namaProduk,
                    })) : null,
                    show: (viewProduk),
                    required: true,
                    validation: 'required'
                },
                // {
                //     inputType: DataForm.inputTypes.PRE_DATA
                // }
            ]}
            onChange={(data, key, value) => {
                if (key === "levelId") {
                    let temp = find(level, { id: value });
                    setViewProduk(true);

                    services.getLevelById(temp.id, props.match.params.companyId).then((res) => {
                        setProduk(res.data.levelProduks);
                    })

                }
                return data;
            }}
            watchDefaultData={false}
            onSubmit={onSubmit}
        />
    )
}

export default CreateKelas