import React, { useEffect, useState } from "react";
import { CompanyCard, Masonry } from "../../../libs/react-mpk/components";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import httpService from "../../../libs/react-mpk/services/http.service";
import { Button } from "@react-md/button";
import service from "../../../services/levelService";
import CustomCompanyCard from "../../../modules/Card/CustomCompanyCard";
import userLevelService from "../../../services/assignUserLevel.service";
import iziToast from "izitoast";
import { Link } from "react-router-dom";

const PageLevel = ({ authStore, envStore, modalStore, navigationStore, ...props }) => {
  let [isHover, setIsHover] = useState(false);

  let companyId = props.match.params.companyId;
  let userId = localStorage.getItem("getUserId");

  // let socket = new WebSocket("ws://localhost:8010/websocket");

  const generateCertificate = async () => {
    try {
      let res = await userLevelService.checkSertifikasiLulus(userId);

      let { data } = res;
      // console.log(data)
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  const showGenerateCertificate = () => {
    modalStore.showConfirm({
      title: t.translate("form.downloadConfirmation"),
      children: t.translate("message.generateCertificate"),
      onSubmit: (callback) => {
        generateCertificate();
        callback();
      },
    });
  };

  const handleHover = () => {
    setIsHover(isHover => !isHover);
    console.log(isHover);
  }

  const handleOut = () => {
    handleHover();
  }

  // socket.onopen = (e) => {
  //   console.log("[open] Connection established");
  //   console.log("Sending to server");
  //   socket.send("My name is John");
  // };

  // socket.onerror = function (error) {
  //   console.log(error);
  //   // alert(`[error] ${error.message}`);
  // };

  // socket.onmessage = function (event) {
  //   console.log(`[message] Data received from server: ${event.data}`);
  // };

  return (
    <TableWrapper
      // actions={[
      //   new TableWrapper.action(
      //     "Generate Certificate",
      //     "mdi mdi-download",
      //     () => {
      //       showGenerateCertificate();
      //     },
      //     true
      //   ),
      // ]}
      title={t.translate("menu.level")}
      baseId="mod-companies-level"
      useFilter={authStore.user ? authStore.user.isSupport : false}
      // render={(data) => (
      //   <>
      //     <div className="mpk-padding-N padding-all mpk-animation slide-in mpk-full full-width">
      //       <Masonry
      //         className="mpk-full full-width"
      //         baseId="mod-companies-masonry"
      //         items={data.map((d) => (
      //           // <Button onClick={e => setAllData(e, d)}>
      //           <CustomCompanyCard
      //             key={`company-${d.id}`}
      //             value={d}
      //             href={`/product/company/${companyId}/choose-level/${d.id}/choose-product`}
      //           />
      //           // </Button>
      //         ))}
      //       />
      //     </div>
      //   </>
      // )}
      columns={[
        {
          label: t.translate("column.name"),
          searchable: true,
          key: "namaLevel",
          // render: (item) => item.namaLevel,
          render: (item) => (
            <>
              <a 
                style={{
                  color: "blue",
                  textDecoration: isHover ? "underline" : "underline"
                }}
                onMouseOver={handleHover} 
                // onMouseOut={handleOut}
                onClick={() => {
                  // navigationStore.redirectTo(`/product/company/${companyId}/choose-level/${item.id}/choose-product`)
                  window.location.href = `/product/company/${companyId}/choose-level/${item.id}/choose-product`
                }}
              >
                {item.namaLevel}
              </a>
              {/* <Link style={{ textDecoration: 'none', color: 'black' }}>
              {item.namaLevel}
              </Link> */}
            </>
          )
        },
      ]}
      itemActions={[
        new TableWrapper.action(
          "Pilih",
          "mdi mdi-details",
          (item) => {
            // navigationStore.redirectTo(`/product/company/${companyId}/choose-level/${item.id}/choose-product`)
            window.location.href = `/product/company/${companyId}/choose-level/${item.id}/choose-product`
          },
        ),
      ]}
      onFetchData={(query) => {
        return new Promise(async (resolve, reject) => {
          try {
            let res = await service.getUserLevel(companyId);
            resolve(res);
          } catch (err) {
            reject(err);
          }
        });

        // return (
        //   new Promise(async (resolve, reject) => {
        //     try {
        //       // query = {
        //       //   ...search,
        //       //   ...query
        //       // }
        //       // console.log(search)
        //       let getPage = await service.getUserLevel()
        //       // console.log(getPage)
        //       // getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
        //       resolve(getPage)
        //     } catch (e) {
        //       reject(e)
        //       // ErrorService(e)
        //     }
        //   })
        // )

        // return authStore.user.isSupport
        //   ? httpService.stream(`${envStore.env.sso.host}/api/user_console/company_support`, query, {}, {
        //     useDefaultHost: false,
        //     useDefaultBaseUrl: false,
        //   })
        //   : new Promise(resolve => {
        //     let { companies } = authStore.user
        //     resolve({
        //       data: companies,
        //       headers: {
        //         'x-pagination-count': companies.length,
        //       }
        //     })
        //   })
      }}
    />
  );
};

export default inject(
  "authStore",
  "envStore",
  "modalStore",
  "navigationStore"
)(observer(PageLevel));
