import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "../../services/userLatihan.service";
import moment from "moment";
import Confirm from "../Confrimation/Confirm";
import InputNilai from "./InputNilai";
import { Checkbox } from "react-md";
import iziToast from "izitoast";
import { inject, observer } from "mobx-react";
import InputNilaiTemp from "./InputNilaiTemp";
import ViewPilgan from "./ViewPilgan";
import ViewUserPraktek from "./ViewUserPraktek";

const ViewUserLatihan = ({
  className = "",
  showCommandbar = true,
  modalStore,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setEditShowForm] = useState(false);
  const [showInputForm, setInputShowForm] = useState(false);
  const [showPilganForm, setPilganShowForm] = useState(false);
  const [showPraktekForm, setPraktekShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  let [search, setSearch] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [releaseList, setReleaseList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [itemList, setItemList] = useState([]);
  const kelasId = props.match.params.kelasId;
  const materiId = props.match.params.materiId;
  let companyId = props.match.params.companyId;

  const showDeleteItem = (data) => {
    setShowDelete(true);
    setSelectedItem(data);
  };
  const deleteItem = async (item) => {
    try {
      let res = await service.deleteById(item.id);
      TableWrapper.updateDataItem("view-user-latihan", { id: item.id }, item);
      TableWrapper.reload("view-user-latihan");
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };
  const isRilis = async (item) => {
    item.id = item.id;
    item.isRilis = !item.isRilis;
    item.nilaiLatihan = item.nilaiLatihan;
    item.latihanId = item.latihanId;
    item.judulLatihan = item.judulLatihan;
    item.userId = item.userId;
    item.namaUser = item.namaUser;
    try {
      const resp = await service.update(item, item.id);
      // console.log(resp)
      TableWrapper.updateDataItem("view-user-latihan", { id: item.id }, item);
      TableWrapper.reload("view-user-latihan");
      // callback('Update Success', false)
    } catch (error) {
      // console.log(error.message)
      // callback('Update Error ' + error.message, true)
    }
  };

  const addItem = (e, item) => {
    item.isRilis = !item.isRilis;
    let temp = [];

    if (!item.isRilis) {
      setCheckAll(true);
    }

    itemList.map((d) => {
      if (d.isRilis) {
        temp.push(d);
      }
    });

    if (temp.length == itemList.length) {
      setCheckAll(false);
    }

    setReleaseList([...releaseList, temp]);
  };

  const scoreRelease = async (releaseList) => {
    try {
      let res = await service.rilisNilai(releaseList);
      let { data, status } = res;

      if (status == 200) {
        TableWrapper.updateDataItem("view-user-latihan", { id: data.id }, data);
        TableWrapper.reload("view-user-latihan");
        iziToast.success({
          message: "success",
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  const scoreReleaseConfirm = () => {
    modalStore.showConfirm({
      title: t.translate("form.scoreReleaseConfirm"),
      children: t.translate("message.confirmExerciseScoreRelease"),
      onSubmit: (callback) => {
        scoreRelease(itemList);
        callback();
      },
    });
  };

  const addAllItem = (e) => {
    setCheckAll(!checkAll);
    let item = [];
    if (checkAll) {
      itemList.map((d) => {
        d.isRilis = true;
        item.push(d);
      });
      setReleaseList(item);
    } else {
      itemList.map((d) => {
        d.isRilis = false;
      });
      setReleaseList(itemList);
    }
  };
  return (
    <>
      <TableWrapper
        baseId="view-user-latihan"
        title={"PENILAIAN LATIHAN PESERTA"}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="id"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        showFilter={false}
        // extraFilter={<div>Test</div>}
        // multiColumnFilter={true}
        columns={[
          {
            label: (
              <Checkbox
                label={"Status Rilis"}
                value={checkAll}
                checked={!checkAll}
                onChange={(e) => addAllItem(e)}
              />
            ),
            searchable: false,
            key: "checkbox",
            render: (item) => (
              <Checkbox
                label={
                  <TableWrapper.Status
                    type={
                      item.isRilis
                        ? TableWrapper.Status.types.SUCCESS
                        : TableWrapper.Status.types.ERROR
                    }
                  >
                    {item.isRilis ? "Sudah Rilis" : "Belum Rilis"}
                  </TableWrapper.Status>
                }
                value={item}
                checked={item.isRilis}
                onChange={(e) => addItem(e, item)}
              />
            ),
          },
          {
            label: t.translate("table.statusLatihan"),
            searchable: true,
            key: "dikerjakan",
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.dikerjakan == 1
                    ? TableWrapper.Status.types.IDLE
                    : item.dikerjakan == 2
                    ? TableWrapper.Status.types.PROGRESS
                    : TableWrapper.Status.types.SUCCESS
                }
              >
                {item.dikerjakan == 1
                  ? "Belum Dimulai"
                  : item.dikerjakan == 2
                  ? "Sedang Berjalan"
                  : "Selesai"}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Nama Peserta",
            searchable: true,
            key: "namaUser",
            render: (item) => item.namaUser,
          },
          // {
          //   label: t.translate("table.email"),
          //   searchable: true,
          //   key: "emailUser",
          //   render: (item) => item.emailUser,
          // },
          {
            label: t.translate("table.nilaiLatihan"),
            key: "nilaiLatihan",
            render: (item) => item.nilaiLatihan != null ? item.nilaiLatihan : "N/A",
          },
          {
            label: t.translate("table.judulLatihan"),
            searchable: true,
            key: "namaLatihan",
            render: (item) => item.judulLatihan,
          },
          
        ]}
        actions={[
          new TableWrapper.action(
            "Rilis Nilai",
            "mdi mdi-check",
            () => {
              scoreReleaseConfirm();
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Rilis Nilai",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setEditShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            "View Jawaban Pilihan Ganda",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setPilganShowForm(true);
            },
            // (item) => (item.isPg && item.dikerjakan == 3)
            (item) => (item.isPg )
          ),
          new TableWrapper.action(
            "Input Nilai Essay",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setInputShowForm(true);
            },
            // (item) => !item.isRilis
            (item) => (item.isEssay)
          ),
          new TableWrapper.action(
            "Input Nilai Praktek",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setPraktekShowForm(true);
            },
            (item) => (item.isPraktek)
          ),
          // new TableWrapper.action(
          //   "Delete",
          //   "mdi mdi-delete",
          //   (item) => {
          //     showDeleteItem(item);
          //   },
          //   true
          // ),
          // new TableWrapper.action('Delete', 'mdi mdi-delete', (item) =>
          //     service.deleteById(item.name)
          // )
        ]}
        onFetchData={(query) => {
          let item = [];
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...search,
                ...query,
                materiPembelajaranId: materiId,
                kelasId: kelasId,
                "kodePerusahaan.equals": companyId
              };
              let getPage = await service.userMateriLatihan(query);
              // let getPage = await service.getUserLatihan(query);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              console.log(getPage);

              getPage.data.map((d) => {
                if (d.isRilis) {
                  item.push(d);
                }
              });

              if (item.length == getPage.data.length) {
                setCheckAll(false);
              } else {
                setCheckAll(true);
              }

              setItemList(getPage.data);

              resolve(getPage);
            } catch (e) {
              reject(e);
              // ErrorService(e)
            }
          });
        }}
        // onFetchData={async query => {
        //   return service.getData()
        // }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <Confirm
        visible={showDelete}
        onRequestClose={() => {
          setShowDelete(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          deleteItem(selectedItem);
          setShowDelete(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.userLatihanDelete")}
      />
      <Confirm
        visible={showEditForm}
        onRequestClose={() => {
          setEditShowForm(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          isRilis(selectedItem);
          setEditShowForm(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.isRilis")}
      />

      <InputNilaiTemp
        visible={showInputForm}
        onRequestClose={() => {
          setInputShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

      <ViewPilgan
        visible={showPilganForm}
        onRequestClose={() => {
          setPilganShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

      <ViewUserPraktek
        visible={showPraktekForm}
        onRequestClose={() => {
          setPraktekShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />
    </>
  );
};

export default inject("modalStore")(observer(ViewUserLatihan));
