import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "../../services/assignUser.service";
import levelService from "../../services/assignUserLevel.service";
import Confirm from "../Confrimation/Confirm";
import moment from "moment";
import CreateAssignUser from "./CreateAssignUser";
import ImportAssignUser from "./ImportAssignUser";
import { inject, observer } from "mobx-react";
import { role } from "../../constant/appConstant";

const ViewAssignUser = ({
  className = "",
  showCommandbar = true,
  modalStore,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setEditShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  let [search, setSearch] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showActive, setShowActive] = useState(false);

  const [showUpload, setShowUpload] = useState(false);

  const showDeleteItem = (data) => {
    setShowDelete(true);
    setSelectedItem(data);
  };
  const showActiveItem = (data) => {
    setShowActive(true);
    setSelectedItem(data);
  };

  const deleteItem = (item) => {
    service.deleteById(item.id).then((res) => {
      TableWrapper.updateDataItem("view-assign-user", { id: item.id }, item);
      TableWrapper.reload("view-assign-user");
    });
  };

  const deActivateaccount = (item) => {
    service.activation(item.id).then((res) => {
      TableWrapper.updateDataItem("view-assign-user", { id: item.id }, item);
      TableWrapper.reload("view-assign-user");
    });
  };

  const exportUser = async (callback) => {
    try {
      let res = await service.exportUser();
      callback("success", false);
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  const exportHandler = () => {
    modalStore.showConfirm({
      title: t.translate("form.exportUser"),
      children: t.translate("message.exportData"),
      onSubmit: (callback) => {
        exportUser(callback);
        callback();
      },
    });
  };

  const exportReport = async (callback) => {
    try {
      let res = await levelService.exportReport(props.match.params.companyId);
      callback("success");
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  const fileExportHandler = () => {
    modalStore.showConfirm({
      title: t.translate("form.exportUser"),
      children: t.translate("message.exportData"),
      onSubmit: (callback) => {
        exportReport(callback);
        callback();
      },
    });
  };
  return (
    <>
      <TableWrapper
        baseId="view-assign-user"
        title={t.translate("modules.table.assign-user")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        // multiColumnFilter={true}
        columns={[
          {
            label: t.translate("table.status"),
            key: "isAktif",
            searchable: true,
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.isAktif == 1
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.isAktif == true ? "Aktif" : "Tidak Aktif"}
              </TableWrapper.Status>
            ),
          },
          {
            label: t.translate("table.role"),
            key: "namaRole",
            searchable: true,
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.authorities && item.authorities[0] === role.ROLE_ADMIN
                    ? TableWrapper.Status.types.ERROR
                    : item.authorities && item.authorities[0] === role.ROLE_PENGAJAR
                    ? TableWrapper.Status.types.PROGRESS
                    : TableWrapper.Status.types.SUCCESS
                }
              >
                {item.authorities && item.authorities[0] === role.ROLE_ADMIN 
                ? role.ROLE_ADMIN 
                : item.authorities && item.authorities[0] === role.ROLE_PENGAJAR
                ? role.ROLE_PENGAJAR : role.ROLE_PESERTA
              }
              </TableWrapper.Status>
            ),
          },
                    {
            label: t.translate("table.jenisKelamin"),
            searchable: false,
            key: "jenisKelamin",
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.jenisKelamin && item.jenisKelamin.toUpperCase() === "PEREMPUAN"
                    ? TableWrapper.Status.types.ERROR
                    : item.jenisKelamin && item.jenisKelamin.toUpperCase() === "LAKI-LAKI"
                    ? TableWrapper.Status.types.PROGRESS
                    : TableWrapper.Status.types.IDLE
                }
              >
                {item.jenisKelamin && item.jenisKelamin.toUpperCase() === "PEREMPUAN"
                ? "PEREMPUAN"
                : item.jenisKelamin && item.jenisKelamin.toUpperCase() === "LAKI-LAKI"
                ? "LAKI-LAKI" : "-"
              }
              </TableWrapper.Status>
            )
          },
          {
            label: t.translate("table.npwp"),
            searchable: true,
            key: "npwp",
            mask: "##.###.###.#-###.###",
            render: (item) => item.npwp?.replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/, '$1.$2.$3.$4-$5.$6'),
          },
          {
            label: t.translate("table.nik"),
            searchable: true,
            key: "nik",
            render: (item) => item.nik,
          },
          {
            label: t.translate("table.nama"),
            searchable: true,
            key: "nama",
            render: (item) => item.nama,
          },
          {
            label: t.translate("table.username"),
            searchable: true,
            key: "username",
            render: (item) => item.username,
          },
          {
            label: t.translate("table.email"),
            searchable: true,
            key: "email",
            render: (item) => item.email,
          },
          {
            label: t.translate("table.birthDate"),
            searchable: false,
            key: "tanggalLahir",
            render: (item) => item.tanggalLahir ? moment(item.tanggalLahir).format("DD MMMM YYYY") : "",
          },
          {
            label: t.translate("table.birthPlace"),
            searchable: false,
            key: "tempatLahir",
            render: (item) => item.tempatLahir,
          },
          {
            label: t.translate("table.alamat"),
            searchable: false,
            key: "alamat",
            render: (item) => item.alamat,
          },
          {
            label: t.translate("table.kontak"),
            searchable: false,
            key: "kontak",
            render: (item) => item.kontak,
          },
          // {
          //     label: t.translate("table.companyName"),
          //     searchable: true,
          //     key: "companyName",
          //     render: (item) => item.companyName,
          // },
          // {
          //     label: t.translate("table.subscriptionName"),
          //     searchable: true,
          //     key: "subscriptionName",
          //     render: (item) => item.subscriptionName,
          // },
          {
            label: t.translate("table.dibuatOleh"),
            searchable: false,
            key: "dibuatOleh",
            render: (item) => item.dibuatOleh,
          },
          {
            label: t.translate("table.tglDibuat"),
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.tanggalDibuat).format("lll"),
          },
          {
            label: t.translate("table.diperbaharui"),
            key: "diperbaharuiOleh",
            searchable: false,
            render: (item) => item.diperbaharuiOleh,
          },
          {
            label: t.translate("table.tglDiperbaharui"),
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.tanggalDiperbaharui).format("lll"),
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            false
          ),
          new TableWrapper.action(
            "Import Data Pengguna",
            "mdi mdi-microsoft-excel",
            () => {
              setSelectedItem(null);
              setShowUpload(true);
            },
            true
          ),
          new TableWrapper.action(
            "Dowload Pengguna",
            "mdi mdi-download",
            () => {
              exportHandler();
            },
            true
          ),
          new TableWrapper.action(
            "Download Level Pengguna",
            "mdi mdi-download",
            () => {
              fileExportHandler();
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setEditShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            "Activate/De-activate Account",
            "mdi mdi-account",
            (item) => {
              showActiveItem(item);
            },
            true
          ),
        ]}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...search,
                ...query,
              };
              let getPage = await service.getAssign(query);

              getPage.headers["x-pagination-count"] = getPage.headers["x-total-count"];
              resolve(getPage);
            } catch (e) {
              reject(e);
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />

      <Confirm
        visible={showDelete}
        onRequestClose={() => {
          setShowDelete(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          deleteItem(selectedItem);
          setShowDelete(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.levelDelete")}
      />

      <Confirm
        visible={showActive}
        onRequestClose={() => {
          setShowActive(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          deActivateaccount(selectedItem);
          setShowActive(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.activation")}
      />

      <CreateAssignUser
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

      <CreateAssignUser
        visible={showEditForm}
        onRequestClose={() => {
          setEditShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

      <ImportAssignUser
        visible={showUpload}
        onRequestClose={() => {
          setShowUpload(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        type={"assignUser"}
      />
    </>
  );
};

export default inject("modalStore")(observer(ViewAssignUser));
