import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "../sample/sample.service";
import moment from "moment";
import { inject, observer } from "mobx-react";
import CompanyGroupForm from "./CompanyGroupForm";
import { companyGroupService } from "../../services";

const CompanyGroup = ({ className = "", showCommandbar = true, ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <>
      <TableWrapper
        baseId={className}
        title={t.translate("table.company")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        multiColumnFilter={false}
        columns={[
          {
            label: t.translate("table.groupName"),
            searchable: true,
            key: "namaGrup",
            render: (item) => item.namaGrup,
          },
          {
            label: t.translate("table.groupCode"),
            searchable: false,
            key: "kodeGrup",
            render: (item) => item.kodeGrup,
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setShowForm(true);
            },
            true
          ),
        ]}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              // query = {
              //   ...search,
              //   ...query
              // }
              // console.log(search)
              let res = await companyGroupService.get(query);
              // getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              resolve(res);
            } catch (e) {
              reject(e);
              // ErrorService(e)
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />

      <CompanyGroupForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        tableId={className}
      />
    </>
  );
};

export default inject("modalStore")(observer(CompanyGroup));
