import React, { useEffect, useState } from "react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find } from "lodash";
import iziToast from "izitoast";
import service from "../../services/sign.service";

const UploadSign = ({ envStore }) => {
  const { inputTypes, definition } = FormWrapper;
  const [currImage, setCurrImage] = useState(null);
  const reader = new FileReader()
  const initData = async () => {
    try {
      let res = await service.getSign();
      let { data } = res;
      const base64String = btoa(String.fromCharCode(...new Uint8Array(data)));
      setCurrImage(`data:image/jpeg;base64, ${base64String}`);
    } catch (err) {
      var arr = new Uint8Array(err.message);
      var str = String.fromCharCode.apply(String, arr);
      let msg = JSON.parse(str);
      console.log(msg)
      iziToast.error({
        message: msg.message,
      });
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const onSubmit = async (item, callback) => {
    try {
      const resp = await service.uploadSign(item.file);

      initData()
      callback("success", false);
    } catch (error) {
      callback(error.message, true, false);
    }
  };
  return (
    <FormWrapper
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
          onClick: () => {
            initData();
          },
        },
      ]}
      //   backTo={`/${envStore.env.applicationType}/table`}
      baseId="mod-form-sample"
      title={t.translate("form.uploadSign")}
      //   hintMessage={t.translate("modules.form.hint.message")}
      hintShowIcon={false}
      defaultData={{ file: null }}
      definitions={[
        // =====
        // CONTOH MENGGUNAKAN CLASS FORM DEFINITION
        {
          render: (
            <>
              {currImage && (
                <div style={{ marginBottom: "5%" }}>
                  <img src={currImage} alt='sign'/>
                </div>
              )}
            </>
          ),
        },
        {
          inputType: inputTypes.FILE_INPUT,
          label: "File",
          key: "file",
          required: true,
          accept: "image/*",
        },
        // -----
      ]}
      //   onBeforeChange={async (key, value) => {
      //     await new Promise((resolve) => {
      //       setTimeout(resolve, 2000);
      //     });
      //     return value;
      //   }}
      onChange={(data, key, value) => {
        if (key == 'file'){
          setCurrImage(URL.createObjectURL(value))
        }

        return data;
      }}
      submitLabel={"Submit"}
      onSubmit={onSubmit}
    />
  );
};

export default inject("envStore")(observer(UploadSign));
