import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  FileInput,
  Text,
  TextField,
  CircularProgress,
  TextIconSpacing,
  DialogHeader,
  FontIcon,
  Card,
} from "react-md";
import service from "../../../services/latihan.service";
import soalService from "../../../services/soal.service";
import t from "counterpart";
import http from "../../../libs/react-mpk/services/http.service";
import iziToast from "izitoast";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import ViewPDF from "./ViewPDF";
import FileSaver from "file-saver";
import { inject, observer } from "mobx-react";
import UploadFileLatihan from "./UploadFileLatihan";

const ViewSoalPraktek = ({
  visible = false,
  onRequestClose = () => {},
  modalStore,
  ...props
}) => {
  const calculateTimeLeft = () => {
    if(props?.item?.tanggalAkhirLatihan){
      const difference = new Date(props.item.tanggalAkhirLatihan) - new Date();
      return difference > 0 ? Math.floor(difference / 1000) : 0;
    }
    else return 0;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());


  const [ready, setReady] = useState(false);
  const [item, setItem] = useState([]);
  const [exam, setExam] = useState({});
  const [itemExcel, setItemExcel] = useState([]);
  const [showExcel, setShowExcel] = useState([]);
  const [showPdf, setShowPdf] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedItemExcel, setSelectedItemExcel] = useState(false);
  const [name, setName] = useState({});
  let [getDate, setGetDate] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const chunkSize = 1048576 * 2;
  const [loading, setLoading] = useState(false)

  const initItem = async () => {
    if (props.item) {
      console.log(props.item)
      setName(props.item.judulLatihan);
      let finishTime = new Date(props.item.tanggalAkhirLatihan).getTime();
        let now = new Date().getTime();
        let getTime = finishTime - now;
        let days = Math.floor(getTime / (1000 * 60 * 60 * 24));
        setGetDate(days);
      try {
        let res = await service.getSoalLatihan(props.item.id, "Praktek");
        setShowPdf(false);
        let { data, status } = res;
        data
          .sort((a, b) =>
            parseInt(a.urutanSoal) > parseInt(b.urutanSoal)
              ? 1
              : parseInt(a.urutanSoal) < parseInt(b.urutanSoal)
              ? -1
              : 0
          )
          .map((d) => {
            d.disabled = false;
            d.counter = 1;
            d.beginingOfTheChunk = 0;
            d.endOfTheChunk = chunkSize;
            d.fileGuid = "";
            d.fileSize = 0;
            d.progress = 0;
            d.fileToBeUpload = {};
            d.chunkCount = 0;
          });

        setItem(data);
        setItemExcel(data);
      } catch (err) {
        iziToast.error({
          message: err.message,
        });
      }

      setExam({
        id: props.item.id
      })
      // try {
      //   let res = await service.getLatihanById(props.item.id);
      //   let { data } = res;
      //   setExam(data);
      // } catch (err) {
      //   iziToast.error({
      //     message: err.message,
      //   });
      // }
    }
  };

  useEffect(() => {}, [item]);

  useEffect(() => {
    if (visible) {
      initItem();
    }
    setReady(visible);
  }, [visible]);

  useEffect(() => {
    if (visible) {
      if(props?.item?.tanggalAkhirLatihan){
        // console.log(calculateTimeLeft());
        // console.log(props.item.dikerjakan);
        if (calculateTimeLeft() > 0 && props.item.dikerjakan != 3) {
          const timerId = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
            console.log("SEDANG BERJALAN")
          }, 1000);
          return () => clearInterval(timerId);
        } else if(props.item.dikerjakan != 3) {
          console.log("SUBMIT OROMATIS")
          submitHandler();
        }
        else {
          console.log("SUDAH SELESAI , CUMA VIEW")
        }
      }
    }
    setReady(visible);
  }, [visible, timeLeft]);

  const closeForm = () => {
    setItem([]);
    onRequestClose();
  };
  const downloadAttach = (e, i) => {
    modalStore.showConfirm({
      title: t.translate("form.downloadConfirmation"),
      children: t.translate("message.download"),
      onSubmit: (callback) => {
        getExcelItem(item[i]);
        callback();
      },
    });
  };

  const getExcelItem = (item) => {
    // console.log(item)

    let config = Object.assign({
      method: "get",
      responseType: "arraybuffer",
    });

    let options = Object.assign({
      fileType: null,
      fileName: item.jawabanExcelPraktek,
    });

    return new Promise(async (resolve, reject) => {
      try {
        let res = await (config.method === "post"
          ? http.post(`/latihan/praktek/${item.id}`, item, config)
          : http.get(
              `/latihan/praktek/${item.id}`,
              {
                file: "excel",
              },
              config
            ));
        let fileName =
          options.fileName ||
          res.headers["content-disposition"].split("filename=")[1];
        let type =
          options.fileType ||
          res.headers["content-type"].substring(
            0,
            res.headers["content-type"].indexOf(";")
          );
        let { data } = res;
        const blob = new Blob([res.data], { type: type });
        FileSaver.saveAs(blob, fileName);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  const changeItem = (e, i) => {
    let temp = [...item];

    const [file] = Array.from(e.currentTarget.files || [null]);

    if (file) {
      temp[i].counter = 1;
      temp[i].beginingOfTheChunk = 0;
      temp[i].endOfTheChunk = chunkSize;
      temp[i].pdf = file;
      temp[i].fileSize = temp[i].pdf.size;
      const _totalCount =
        temp[i].pdf.size % chunkSize == 0
          ? temp[i].pdf.size / chunkSize
          : Math.floor(temp[i].pdf.size / chunkSize) + 1;
      temp[i].chunkCount = _totalCount;
      temp[i].fileGuid = temp[i].pdf.name;
    }
    // if (file) {
    //   temp[i].pdf = file;
    // }
    setItem(temp);

    // const [file] = Array.from(e.currentTarget.files || [null]);
    // if (file) {
    //   temp[i].pdf = file;
    // }
    // setItem(temp);
  };

  const changeItemExcel = (e, i) => {
    let temp = [...item];
    const [file] = Array.from(e.currentTarget.files || [null]);
    if (file) {
      temp[i].excel = file;
    }
    setItem(temp);
  };

  const openPDF = async (e, i) => {
    setShowPdf(true);
    setSelectedItem(item[i]);
  };

  const uploadFileExcel = async (e, i) => {
    let items = [...item];
    if (items[i].excel || items[i].pdf) {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      // let filePdf = items[i].pdf;
      let fileExcel = items[i].excel;

      // delete items[i].pdf;
      delete items[i].excel;

      try {
        let res = await service.uploadJawaban(
          `/latihan/uploadjawaban/${exam.id}/${items[i].soalId}`,
          // filePdf,
          fileExcel,
          {},
          config
        );
        let { data, status } = res;
        // console.log(data);
        if (status == 200) {
          items[i].disabled = false;
          setItem(items);

          TableWrapper.updateDataItem(
            props.tableId,
            { id: items.id },
            items
          );
          TableWrapper.reload("view-essay-latihan");
          iziToast.success({
            message: "Success",
          });
          onRequestClose();
        }
      } catch (err) {
        items[i].disabled = false;
        setItem(items);
        iziToast.error({
          message: err.message,
        });
        // onRequestClose();
      }
    }
  };

  const uploadData = async (e, i) => {
    let temp = [...item];
    uploadFileExcel(i);

    temp[i].counter = temp[i].counter + 1;
    if (temp[i].counter <= temp[i].chunkCount) {
      var chunk = temp[i].pdf.slice(
        temp[i].beginingOfTheChunk,
        temp[i].endOfTheChunk
      );
      try {
        uploadChunk(i, chunk);
        //   callback("success", false);
      } catch (err) {
        iziToast.error({
          message: err.message,
        });
      }
    }
  };

  const uploadChunk = async (i, chunk) => {
    let temp = [...item];
    try {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          namaFile: temp[i].fileGuid,
          totalCounter: temp[i].chunkCount,
          nowCounter: temp[i].counter,
        },
      };
      const response = await http.upload(
        `/latihan/uploadjawaban/pdf/${exam.id}/${temp[i].soalId}`,
        chunk,
        {},
        config
      );
      const data = response.data;
      console.log(response);
      //   if (data.isSuccess) {
      // setBeginingOfTheChunk(endOfTheChunk);
      // setEndOfTheChunk(endOfTheChunk + chunkSize);

      temp[i].beginingOfTheChunk = temp[i].endOfTheChunk;
      temp[i].endOfTheChunk = temp[i].endOfTheChunk + chunkSize;
      if (temp[i].counter == temp[i].chunkCount) {
        console.log("Process is complete, counter", temp[i].counter);
        // await uploadCompleted(item);
        // setShowProgress(false);
        iziToast.success({
          message: "success",
        });
      } else {
        var percentage = (temp[i].counter / temp[i].chunkCount) * 100;
        temp[i].progress = percentage;
      }

      setItem(temp);
      //   }
    } catch (error) {
      iziToast.error({
        message: error.message,
      });
      // setShowProgress(false);
      // resetData();
      console.log("error", error);
    }
  };

  const downloadQuestionHandler = async (e, item) => {
    try {
      let res = await soalService.downloadSoal(item.soalId, item.filename);
      iziToast.success({
        message: "success",
      });
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  const submitHandler = async () => {
    try {
      setLoading(true)
      // let res = await service.submitPraktek(exam.id, item);

      setLoading(false)
      TableWrapper.updateDataItem(props.tableId, { id: exam.id }, exam);
      TableWrapper.reload(props.tableId);

      onRequestClose();
    } catch (err) {
      setLoading(false)
      iziToast.error({
        message: err.message,
      });
    }
  };

  const submitEndHandler = async () => {
    try {
      setLoading(true)
      let res = await service.submitPraktek(exam.id, item);

      setLoading(false)
      TableWrapper.updateDataItem(props.tableId, { id: exam.id }, exam);
      TableWrapper.reload(props.tableId);

      onRequestClose();
    } catch (err) {
      setLoading(false)
      iziToast.error({
        message: err.message,
      });
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    ready && (
      <>
        <Dialog
          visible={visible}
          modal
          onRequestClose={() => closeForm()}
          disableEscapeClose
          style={{
            width: "1100px",
            height: "95vh",
            borderRadius: "5px"
          }}
        >
           <DialogHeader style={{ paddingBottom: "0px" }}>
            <div style={{ width: "100%" }}>
                  <div style={{ color: "blue", fontWeight: "bold", fontSize: "25px", width: "100%" }}>{name?.toUpperCase()}</div>
                  <div style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
                    SISA WAKTU : {formatTime(calculateTimeLeft())}
                  </div>
                  <hr></hr>
              </div>
          </DialogHeader>

          <DialogContent style={{ paddingTop: "5px" }}>
            {/* <Text
              style={{
                textAlign: "center",
                lineHeight: "30px",
                fontSize: "35px",
              }}
            >
              {name}
              <hr></hr>
            </Text> */}

            <div>
              {item.map((d, i) => (
                <>
                  <Card
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      background: "#f2f2f2",
                      padding: "15px 20px"
                    }}
                  >
                  <Text style={{ fontSize: "20px", textAlign: "justify" }}>
                    <b>{i + 1}. {d.soalPertanyaan}</b>
                  </Text>

                  <>
                    <Button
                     theme="secondary"
                      themeType="outline"
                      onClick={(e) => {
                        setShowUpload(true);
                        setSelectedItem(item[i]);
                      }}
                      disabled={
                        calculateTimeLeft() <= 0
                      }
                    >
                       <TextIconSpacing
                          // iconAfter={true}
                          icon={
                            <FontIcon iconClassName="mdi mdi-upload"/>
                          }
                        >
                          UPLOAD JAWABAN
                        </TextIconSpacing>
                    </Button>

                    {d.filename && (
                      <Button
                        theme="primary"
                        themeType="contained"
                        onClick={(e) => downloadQuestionHandler(e, item[i])}
                        style={{ marginLeft: "20px" }}
                      >
                        <TextIconSpacing
                          // iconAfter={true}
                          icon={
                            <FontIcon iconClassName="mdi mdi-download"/>
                          }
                        >
                          Download SOAL
                        </TextIconSpacing>
                      </Button>
                    )}
                  </>
                  {/* <Text>Upload File PDF (Optional)</Text>
                  <div id="pdf" name="pdf" style={{ display: "flex", flexDirection: "row" }}>
                    <FileInput
                      id={`answerPDF-${i}`}
                      buttonType="text"
                      theme="primary"
                      themeType="contained"
                      onChange={(e) => changeItem(e, i)}
                      accept="application/pdf"
                    >
                      Jawaban
                    </FileInput>

                    <TextField
                      id={`textPDF-${i}`}
                      value={d.pdf ? d.pdf.name : ""}
                      style={{ flexGrow: "2" }}
                    />
                  </div> */}

                  {/* <div id="pdf" name="pdf" style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    id={`btnPDF-${i}`}
                    style={{ marginTop: "2%" }}
                    theme="primary"
                    themeType="contained"
                    onClick={(e) => uploadFile(e, i)}
                    disabled={d.disabled}
                  >
                    <TextIconSpacing
                      iconAfter={true}
                      icon={
                        d.disabled ? (
                          <CircularProgress
                            id={`progressPDF-${i}`}
                            centered={false}
                          />
                        ) : null}
                    >
                      Upload
                    </TextIconSpacing>
                  </Button>
                </div> */}

                  {/* <Text>Upload File Excel</Text>
                  <div id="excel" name="excel" style={{ display: "flex", flexDirection: "row" }}>
                    <FileInput
                      id={`answerExcel-${i}`}
                      buttonType="text"
                      theme="primary"
                      themeType="contained"
                      onChange={(e) => changeItemExcel(e, i)}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    >
                      Jawaban
                    </FileInput>
                    <TextField
                      id={`textExcel-${i}`}
                      value={d.excel ? d.excel.name : ""}
                      style={{ flexGrow: "2" }}
                    />
                  </div> */}

                  <div
                    id="excel"
                    name="excel"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    {d.jawabanPdfPraktek && (
                      <Button
                        id={`btnExcel-${i}`}
                        style={{ marginTop: "2%" }}
                        theme="primary"
                        themeType="contained"
                        onClick={(e) => openPDF(e, i)}
                        disabled={d.disabled}
                      >
                        <TextIconSpacing
                          // iconAfter={true}
                          icon={
                            d.disabled ? (
                              <CircularProgress
                                id={`progressExcel-${i}`}
                                centered={false}
                              />
                            ) : <FontIcon iconClassName="mdi mdi-eye"/>
                          }
                        >
                          LIHAT JAWABAN (.PDF)
                        </TextIconSpacing>
                      </Button>
                    )}

                    {d.jawabanExcelPraktek && (
                      <Button
                        id={`btnExcel-${i}`}
                        style={{ marginTop: "2%", marginLeft: "15px" }}
                        theme="primary"
                        themeType="contained"
                        onClick={(e) => downloadAttach(e, i)}
                        disabled={d.disabled}
                      >
                        <TextIconSpacing
                          // iconAfter={true}
                          icon={
                            d.disabled ? (
                              <CircularProgress
                                id={`progressExcel-${i}`}
                                centered={false}
                              />
                            ) : null
                          }
                        >
                          <TextIconSpacing
                            // iconAfter={true}
                            icon={
                              d.disabled ? (
                                <CircularProgress
                                  id={`progressExcel-${i}`}
                                  centered={false}
                                />
                              ) : <FontIcon iconClassName="mdi mdi-download"/>
                            }
                          >
                            Download JAwabaN (.xlsx)
                          </TextIconSpacing>
                        </TextIconSpacing>
                      </Button>
                    )}
                    {/* <Button
                      id={`btnExcel-${i}`}
                      style={{ marginTop: "2%" }}
                      theme="primary"
                      themeType="contained"
                      onClick={(e) => uploadData(e, i)}
                      disabled={d.disabled}
                    >
                      <TextIconSpacing
                        iconAfter={true}
                        icon={
                          d.disabled ? (
                            <CircularProgress
                              id={`progress-${i}`}
                              centered={false}
                            />
                          ) : null}
                      >
                        Upload
                      </TextIconSpacing>
                    </Button> */}
                  </div>
                    
                  </Card>
                  
                </>
              ))}
            </div>
          </DialogContent>

          <DialogFooter style={{ paddingTop: "10px", paddingBottom: "10px"}}>
            <Button
              theme="secondary"
              themeType="outline"
              style={{ margin: "5px" }}
              onClick={() => {
                setItem([]);
                onRequestClose();
              }}
            >
              <TextIconSpacing
                icon={<FontIcon iconClassName="mdi mdi-close" />}
              >
                BATAL
              </TextIconSpacing>
            </Button>

            <Button
              onClick={() => {
                submitHandler();
              }}
              style={{ margin: "5px", marginRight: "12px" }}
              theme="primary"
              themeType={calculateTimeLeft() <= 0 ? "outline" : "contained"}
              disabled={
                calculateTimeLeft() <= 0 ? true : false
              }
            >
              <TextIconSpacing
                icon={<FontIcon iconClassName="mdi mdi-check-all" />}
              >
                SIMPAN JAWABAN
              </TextIconSpacing>
            </Button>

            {/* <Button
              onClick={() => {
                submitEndHandler();
              }}
              style={{ margin: "5px", marginRight: "12px" }}
              theme="primary"
              themeType={calculateTimeLeft() <= 0 ? "outline" : "contained"}
              disabled={
                calculateTimeLeft() <= 0 ? true : false
              }
            >
              <TextIconSpacing
                icon={<FontIcon iconClassName="mdi mdi-check-all" />}
              >
                SUBMIT JAWABAN
              </TextIconSpacing>
            </Button> */}
          </DialogFooter>
        </Dialog>
        <ViewPDF
          visible={showPdf}
          onRequestClose={() => {
            setShowPdf(false);
            setSelectedItem(null);
          }}
          item={selectedItem}
        />

        <UploadFileLatihan
          visible={showUpload}
          onRequestClose={() => {
            setShowUpload(false);
            setSelectedItem(null);
          }}
          onRequestSubmit={initItem}
          item={selectedItem}
          exam={exam}
        />
        {/* <Confirm
          visible={showExcel}
          onRequestClose={() => {
            setShowExcel(false);
            setSelectedItemExcel(null);
          }}
          onSubmitRequest={() => {
            getExcelItem(selectedItem);
            setShowExcel(false);
            setSelectedItemExcel(null);
          }}
          message={t.translate("message.materiExcel")}
        /> */}
      </>
    )
  );
};

export default inject("modalStore")(observer(ViewSoalPraktek));
