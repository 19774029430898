import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import { TabsWrapper } from "../../libs/react-mpk/wrapper";
import TheoryRemedial from "./TheoryRemedial";
import PracticeRemedial from "./PracticeRemedial";

const RemedialContainer = ({
  className = "",
  navigationStore,
  envStore,
  ...props
}) => {
  let companyId = props.match.params.companyId;
  let levelId = props.match.params.levelId;
  let productId = props.match.params.productId;
  let kelasId = props.match.params.kelasId;
  return (
    <TabsWrapper
        title={"REMEDIAL PESERTA"}
      baseId="view-soal"
      className={className}
      tabs={[
        {
          label: t.translate("tab.teori"),
          key: "tab-theory-remedy",
          render: <TheoryRemedial showCommandbar={false} {...props} />,
        },
        {
          label: t.translate("tab.praktek"),
          key: "tab-practice-remedy",
          render: <PracticeRemedial showCommandbar={false} {...props} />,
        },
        // {
        //   label: t.translate("tab.essay"),
        //   key: "view-essay",
        //   render: <ViewEssay showCommandbar={true} />,
        // },
        // {
        //   label: t.translate("tab.praktek"),
        //   key: "view-praktek",
        //   render: <ViewPraktek showCommandbar={true} />,
        // },
      ]}
      onChange={(tab, callback) => {
        // navigationStore.redirectTo(`/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/exam`);
        callback();
      }}
    />
  );
};

export default inject(
  "navigationStore",
  "envStore"
)(observer(RemedialContainer));
