import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "../../services/userUjian.service";
import gradeService from "../../services/grade.service";
import moment from "moment";
import Confirm from "../Confrimation/Confirm";
import InputNilai from "./InputNilai";
import { Checkbox } from "react-md";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";
import InputNilaiTemp from "./InputNilaiTemp";
import ViewPilgan from "./ViewPilgan";
import ViewUserPraktek from "./ViewUserPraktek";
import RemedyForm from "../Remedial/RemedyForm";
import FileSaver from "file-saver";
import { http } from "../../libs/react-mpk/services";

const ViewUserUjian = ({
  className = "",
  showCommandbar = true,
  modalStore,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setEditShowForm] = useState(false);
  const [showInputForm, setInputShowForm] = useState(false);
  const [showPilganForm, setPilganShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  let [search, setSearch] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [releaseList, setReleaseList] = useState([]);
  const kelasId = props.match.params.kelasId;
  const materiId = props.match.params.materiId;
  const [showPraktekForm, setPraktekShowForm] = useState(false);
  const [grade, setGrade] = useState({});
  const [showRemedial, setShowRemedial] = useState(false);
  const [metodePembelajaran, setMetodePembelajaran] = useState("")

  let companyId = props.match.params.companyId;
  let levelProdukId = props.match.params.productId

  const showDeleteItem = (data) => {
    setShowDelete(true);
    setSelectedItem(data);
  };
  const deleteItem = async (item) => {
    try {
      let res = await service.deleteById(item.id);
      iziToast.success({
        message: "success",
      });
      TableWrapper.updateDataItem("view-user-ujian", { id: item.id }, item);
      TableWrapper.reload("view-user-ujian");
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };
  const isRilis = async (item) => {
    item.id = item.id;
    item.isRilis = !item.isRilis;
    item.nilaiUjian = item.nilaiUjian;
    item.ujianId = item.ujianId;
    item.judulUjian = item.judulUjian;
    item.userId = item.userId;
    item.namaUser = item.namaUser;
    try {
      const resp = await service.update(item, item.id);
      // console.log(resp)
      TableWrapper.updateDataItem("view-user-ujian", { id: item.id }, item);
      TableWrapper.reload("view-user-ujian");
      // callback('Update Success', false)
    } catch (error) {
      // console.log(error.message)
      // callback('Update Error ' + error.message, true)
    }
  };

  const addItem = (e, item) => {
    item.isRilis = !item.isRilis;
    let temp = [];

    if (!item.isRilis) {
      setCheckAll(true);
    }

    itemList.map((d) => {
      if (d.isRilis) {
        temp.push(d);
      }
    });

    if (temp.length == itemList.length) {
      setCheckAll(false);
    }

    // setReleaseList([...releaseList, temp]);
    // console.log(itemList)
  };

  const scoreRelease = async (releaseList) => {
    try {
      let res = await service.rilisNilai(releaseList);
      let { data, status } = res;

      if (status == 200) {
        TableWrapper.updateDataItem("view-user-ujian", { id: data.id }, data);
        TableWrapper.reload("view-user-ujian");
        iziToast.success({
          message: "success",
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  const scoreReleaseConfirm = () => {
    modalStore.showConfirm({
      title: t.translate("form.scoreReleaseConfirm"),
      children: t.translate("message.confirmExamScoreRelease"),
      onSubmit: (callback) => {
        scoreRelease(itemList);
        callback();
      },
    });
  };

  const addAllItem = (e) => {
    setCheckAll(!checkAll);
    let item = [];
    if (checkAll) {
      itemList.map((d) => {
        d.isRilis = true;
        item.push(d);
      });
      setItemList(itemList);
      setReleaseList(item);
    } else {
      itemList.map((d) => {
        d.isRilis = false;
      });
      setReleaseList(itemList);
    }
  };

  const scoreExport = async (callback) => {

    let config = Object.assign({
      method: "get",
      responseType: "arraybuffer",
    });

    let options = Object.assign({
      fileType: null,
      fileName: "nilai_peserta.xlsx",
    });

    return new Promise(async (resolve, reject) => {
      try {
        let res = await (config.method === "post"
          ? http.post(`/userujian/export/ujian`, {}, config)
          : http.get(
              `/userujian/export/ujian`,
              {
                levelProdukId: levelProdukId,
                kodePerusahaan: companyId
              },
              config
            ));
        let fileName =
          options.fileName ||
          res.headers["content-disposition"].split("filename=")[1];
        let type =
          options.fileType ||
          res.headers["content-type"].substring(
            0,
            res.headers["content-type"].indexOf(";")
          );
        let { data } = res;
        const blob = new Blob([res.data], { type: type });
        FileSaver.saveAs(blob, fileName);
        resolve(res)
      } catch (error) {
        reject(error);
      }
    });


    // try {
    //   let res = await service.exportNilai({
    //     kodePerusahaan: companyId,
    //     levelProdukId: levelProdukId
    //   });
    //   let { data, status } = res;

    //   let type = res.headers["content-type"].substring(
    //     0,
    //     res.headers["content-type"].indexOf(";")
    //   );
    //   const blob = new Blob([res.data], { type: type });
    //   FileSaver.saveAs(blob, data);
    // } catch (err) {
    //   var arr = new Uint8Array(err.message);
    //   var str = String.fromCharCode.apply(String, arr);
    //   let msg = JSON.parse(str);
    //   callback(msg.message, true);
    // }
  };

  const exportScoreConfirm = () => {
    modalStore.showConfirm({
      title: t.translate("form.scoreReleaseConfirm"),
      children: t.translate("message.exportData"),
      onSubmit: (callback) => {
        scoreExport(callback);
        callback();
      },
    });
  };

  // const remedialExam = async (item, callback) => {
  //   console.log(item);
  //   callback("success", false);
  // };

  // const remedialHandler = (item) => {
  //   modalStore.showConfirm({
  //     title: t.translate("form.remedial"),
  //     children: t.translate("message.remedialMessage"),
  //     onSubmit: (callback) => {
  //       remedialExam(item, callback);
  //       callback();
  //     },
  //   });
  // };

  return (
    <>
      <TableWrapper
        baseId="view-user-ujian"
        title={"PENILAIAN UJIAN PESERTA"}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="id"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        showFilter={false}
        // extraFilter={<div>Test</div>}
        // multiColumnFilter={true}
        columns={[
          {
            label: (
              <Checkbox
                label={"Status Rilis"}
                value={checkAll}
                checked={!checkAll}
                onChange={(e) => addAllItem(e)}
              />
            ),
            searchable: false,
            key: "checkbox",
            render: (item) => (
              <Checkbox
                label={
                  <TableWrapper.Status
                    type={
                      item.isRilis
                        ? TableWrapper.Status.types.SUCCESS
                        : TableWrapper.Status.types.ERROR
                    }
                  >
                    {item.isRilis ? "Sudah Rilis" : "Belum Rilis"}
                  </TableWrapper.Status>
                }
                value={item}
                checked={item.isRilis}
                onChange={(e) => addItem(e, item)}
              />
            ),
          },
          {
            label: "Status Ujian",
            searchable: true,
            key: "dikerjakan",
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.dikerjakan == 1
                    ? TableWrapper.Status.types.IDLE
                    : item.dikerjakan == 2
                    ? TableWrapper.Status.types.PROGRESS
                    : TableWrapper.Status.types.SUCCESS
                }
              >
                {item.dikerjakan == 1
                  ? "Belum Dimulai"
                  : item.dikerjakan == 2
                  ? "Sedang Berjalan"
                  : "Selesai"}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Status Lulus",
            searchable: true,
            key: "isRemedial",
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.isRemedial && item.isRilis
                    ? TableWrapper.Status.types.ERROR
                    : !item.isRemedial && item.isRilis
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.IDLE
                }
              >
                {item.isRemedial && item.isRilis
                  ? "TIDAK LULUS"
                  : !item.isRemedial && item.isRilis
                  ? "LULUS"
                : "-"}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Nama Peserta",
            searchable: true,
            key: "namaUser",
            render: (item) => item.namaUser,
          },
          {
            label: "Nilai Ujian",
            key: "nilaiAwal",
            render: (item) => item.nilaiAwal,
          },
          {
            label: "Nilai Remedial",
            key: "nilaiUjian",
            render: (item) => item.nilaiUjian,
          },
          {
            label: t.translate("table.judulUjian"),
            searchable: true,
            key: "namaUjian",
            render: (item) => item.judulUjian,
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Rilis Nilai",
            "mdi mdi-check",
            () => {
              scoreReleaseConfirm();
            },
            true
          ),
          new TableWrapper.action(
            "Export Nilai",
            "mdi mdi-download",
            () => {
              exportScoreConfirm();
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Rilis Nilai",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setEditShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            "Buat Remedial",
            "mdi mdi-checkbox-marked-circle-outline",
            (item) => {
              setSelectedItem(item);
              setShowRemedial(true);

              if (item.isPg || item.isEssay){
                setMetodePembelajaran("Teori")
              }
              else{
                setMetodePembelajaran("Praktek")
              }

            },
            // (item) => item.isRemedial && item.isRilis
            (item) => item.isRemedial
          ),
          new TableWrapper.action(
            "Lihat Jawaban Pilihan Ganda",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setPilganShowForm(true);
            },
            (item) => (item.isPg && item.dikerjakan === 3 ? true : false)
          ),
          new TableWrapper.action(
            "Input Nilai Essay",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setInputShowForm(true);
            },
            (item) =>
              !item.isRilis && item.isEssay && item.dikerjakan === 3
                ? true
                : false
          ),
          new TableWrapper.action(
            "Input Nilai Praktek",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setPraktekShowForm(true);
            },
            (item) => (item.isPraktek ? true : false)
          ),
          // new TableWrapper.action(
          //   "Delete",
          //   "mdi mdi-delete",
          //   (item) => {
          //     showDeleteItem(item);
          //   },
          //   true
          // ),
          // new TableWrapper.action('Delete', 'mdi mdi-delete', (item) =>
          //     service.deleteById(item.name)
          // )
        ]}
        onFetchData={(query) => {
          let item = [];
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...search,
                ...query,
                materiPembelajaranId: materiId,
                kelasId: kelasId,
                "kodePerusahaan.equals": companyId,
              };
              let getPage = await service.userMateriUjian(query);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];

              getPage.data.map((d) => {
                if (d.isRilis) {
                  item.push(d);
                }
              });

              if (item.length == getPage.data.length) {
                setCheckAll(false);
              } else {
                setCheckAll(true);
              }

              setItemList(getPage.data);
              // console.log(getPage)

              resolve(getPage);
            } catch (e) {
              reject(e);
              // ErrorService(e)
            }
          });
        }}
        // onFetchData={async query => {
        //   return service.getData()
        // }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <Confirm
        visible={showDelete}
        onRequestClose={() => {
          setShowDelete(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          deleteItem(selectedItem);
          setShowDelete(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.userLatihanDelete")}
      />
      <Confirm
        visible={showEditForm}
        onRequestClose={() => {
          setEditShowForm(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          isRilis(selectedItem);
          setEditShowForm(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.isRilis")}
      />

      <InputNilaiTemp
        visible={showInputForm}
        onRequestClose={() => {
          setInputShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

      <ViewPilgan
        visible={showPilganForm}
        onRequestClose={() => {
          setPilganShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />
      <ViewUserPraktek
        visible={showPraktekForm}
        onRequestClose={() => {
          setPraktekShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />

      <RemedyForm
        visible={showRemedial}
        onRequestClose={() => {
          setShowRemedial(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        type={"create"}
        // productId={props.match.params.productId}
        tableId={"view-user-ujian"}
        metodePembelajaran={metodePembelajaran}
        {...props}
      />
    </>
  );
};

export default inject("modalStore")(observer(ViewUserUjian));
