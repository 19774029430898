import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import VideoPlayer from "react-video-js-player";
import videoTemp from "./video.mkv";
import img from "./img.jpg";
import t from "counterpart";
import service from "../../services/materi.service";
import iziToast from "izitoast";
import http from "../../libs/react-mpk/services/http.service";
import ReactPlayer from "react-player";
import FileSaver from "file-saver";
import { CircularProgress } from "react-md";

const defaultData = () => ({
  string: "",
  number: 0,
  boolean: false,
  multiCheckbox: [""],
});

const VideoPlayerDialogue = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [video, setVideo] = useState(null);
  let [type, setType] = useState("");
  let [id, setId] = useState("")

  const initItem = async () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        try {
          if (props.item.pathVideo) {
            setId(props.item.id)
            // let config = Object.assign({
            //   method: "get",
            //   // responseType: "stream",
            //   headers: {
            //     Range: 10**6
            //   }
            // });

            // let options = Object.assign({
            //   fileType: null,
            //   fileName: props.item.pathVideo,
            // });

            // let res = await (config.method === "post"
            //   ? http.post(
            //       `/materipembelajaran/video/${props.item.id}`,
            //       item,
            //       config
            //     )
            //   : http.get(
            //       `/materipembelajaran/video/${props.item.id}`,
            //       {},
            //       config
            //     ));
            // let fileName =
            //   options.fileName ||
            //   res.headers["content-disposition"].split("filename=")[1];
            // let type =
            //   options.fileType ||
            //   res.headers["content-type"].substring(
            //     0,
            //     res.headers["content-type"].indexOf(";")
            //   );

            // let { data } = res;
            // console.log(res)

            // // let base64 = btoa(String.fromCharCode(...new Uint8Array(data)));
            // const blob = new Blob([res.data], { type: type });
            // const fileURL = URL.createObjectURL(blob);
            // //Open the URL on new Window
            // // const pdfWindow = window.open();
            // // pdfWindow.location.href = fileURL;
            // // console.log(blob);
            // // console.log(type);

            // setVideo(fileURL);
            // setType(type);
          }
        } catch (err) {
          console.log(err);
          iziToast.error({
            message: err.message,
          });
        }
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    initItem();
  }, [visible]);

  return (
    ready && (
      <DataForm
        baseId="video-player-dialogue"
        title={(<div>{t.translate("modules.form.learningMaterial").toUpperCase()}<hr></hr></div>)}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        style={{maxWidth: "100%"}}
        definitions={[
          {
            render: (
              <div>
                {id && (
                  <video onContextMenu={e => e.preventDefault()} width={1080} controls controlsList="nodownload">
                    {/* <source src="https://drive.google.com/u/0/uc?id=1XshjcCJA7XqehoKeVXQJRlFc1Yo-Zt0g&export=download" /> */}
                    <source src={`https://api-simulasipajak.staging.pajakku.com/api/v1/materipembelajaran/video/${id}`}/>
                    <source src={`${video}`} type={"video/mp4"} />
                  </video>
                )}
                {/* <VideoPlayer
                  // src={}
                  poster={video.poster}
                  width="720"
                  height="420"
                  
                /> */}
              </div>
            ),
          },
        ]}
        onBeforeChange={(key, value) => {
          console.log(key, value);
          return value;
        }}
        onSubmit={null}
      />
    )
  );
};

export default VideoPlayerDialogue;
