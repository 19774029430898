import { crud, http } from '../libs/react-mpk/services';

let service = null
let serviceUrl = '/kelas'
service = new crud('/kelas');

service.getKelas = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query
  })
}

service.getKelasById = async (levelId) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${levelId}`,
  })
}

service.create = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data
  })
}

service.update = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data
  })
}

service.assignPengajar = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/assign_pengajar/${id}`,
    data
  })
}
service.assignPeserta = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/assign_peserta/${id}`,
    data
  })
}

service.deleteById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  })
}


service.getPeserta = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/list/peserta/${id}`,
  })
}

service.getPengajar = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/list/pengajar/${id}`,
  })
}

service.importPengajar = async (companyId, file) => {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return http.upload(
    `${serviceUrl}/import_pengajar_peserta/${companyId}`,
    file, 
    {},
    config
  )
}
export default service;