import React, { useEffect, useState } from "react";
import { CompanyCard, Masonry } from "../../../libs/react-mpk/components";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import service from "../../../services/kelas.service";
import services from "../../../services/assignUser.service";
import serviceUser from "../../../services/assignUserLevel.service";
import CustomKelas from "../../../modules/Card/CustomKelas";
import { FontIcon, List, ListItem } from "react-md";
import userLevelService from "../../../services/assignUserLevel.service";
import iziToast from "izitoast";
import FileSaver from "file-saver";

const PageClass = ({ authStore, envStore, modalStore, navigationStore, ...props }) => {
  // console.log("props",id)
  let companyId = props.match.params.companyId;
  let levelId = props.match.params.levelId;
  let productId = props.match.params.productId;
  let userId = localStorage.getItem("getUserId");
  let role = localStorage.getItem("getRole");
  const [showFileName, setFileName] = useState("");

  useEffect(() => {
    userLevelService
      .getFileName(userId, productId, { size: 1000 })
      .then((res) => {
        console.log("res12", res.data);
        setFileName(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const generateCertificate = async (callback) => {
    try {
      let res = await userLevelService.generateSertifikasiLulus(
        userId,
        productId
      );
      let { data } = res;
    //   console.log(res.headers);
      let type = res.headers["content-type"].substring(
        0,
        res.headers["content-type"].indexOf(";")
      );
      const blob = new Blob([res.data], { type: type });
      FileSaver.saveAs(blob, showFileName);
    } catch (err) {
      var arr = new Uint8Array(err.message);
      var str = String.fromCharCode.apply(String, arr);
      let msg = JSON.parse(str);
      callback(msg.message, true);
      // iziToast.error({
      //     message: err.message
      // })
    }
  };

  const showGenerateCertificate = () => {
    modalStore.showConfirm({
      title: t.translate("form.downloadConfirmation"),
      children: t.translate("message.generateCertificate"),
      onSubmit: (callback) => {
        generateCertificate(callback);
        callback();
      },
    });
  };

  return (
    <TableWrapper
      actions={[
        new TableWrapper.action(
          "Download Certificate",
          "mdi mdi-download",
          () => {
            showGenerateCertificate();
          },
          role.includes("ADMIN") || role.includes("PESERTA")
        ),
      ]}
      title={t.translate("menu.class")}
      baseId="mod-companies-class"
      useFilter={authStore.user ? authStore.user.isSupport : false}
      // render={(data) => (
      //   <div className="mpk-padding-N padding-all mpk-animation slide-in mpk-full full-width">
      //     <Masonry
      //       className="mpk-full full-width"
      //       baseId="mod-companies-masonry"
      //       items={data.map((d) => (
      //         <CustomKelas
      //           key={`company-${d.id}`}
      //           value={d}
      //           href={`/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${d.id}`}
      //         />
      //       ))}
      //     />
      //   </div>
      // )}
      columns={[
        {
          label: t.translate("column.name"),
          searchable: true,
          key: "namaKelas",
          render: (item) => item.namaKelas,
        },
      ]}
      itemActions={[
        new TableWrapper.action(
          "Pilih",
          "mdi mdi-details",
          (item) => {
            // navigationStore.redirectTo(`/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${item.id}`)
            window.location.href = `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${item.id}/dashboard`;
          },
        ),
      ]}
      onFetchData={(query) => {
        return new Promise(async (resolve, reject) => {
          try {
            // query = {
            //   ...search,
            //   ...query
            // }
            // console.log(search)
            let getPage = await services.getUserKelas(productId, companyId);
            console.log(getPage);
            // getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
            resolve(getPage);
          } catch (e) {
            reject(e);
            // ErrorService(e)
          }
        });
        // return authStore.user.isSupport
        //   ? httpService.stream(`${envStore.env.sso.host}/api/user_console/company_support`, query, {}, {
        //     useDefaultHost: false,
        //     useDefaultBaseUrl: false,
        //   })
        //   : new Promise(resolve => {
        //     let { companies } = authStore.user
        //     resolve({
        //       data: companies,
        //       headers: {
        //         'x-pagination-count': companies.length,
        //       }
        //     })
        //   })
      }}
    />
  );
};

export default inject(
  "authStore",
  "envStore",
  "modalStore",
  'navigationStore'
)(observer(PageClass));
