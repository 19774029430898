import React, { useEffect } from 'react'
import { CompanyCard, Masonry } from '../../../libs/react-mpk/components'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import httpService from '../../../libs/react-mpk/services/http.service'
import CustomProduct from '../../../modules/Card/CustomProduct'
import services from "../../../services/levelProduk.service"
import CustomMateri from '../../../modules/Card/CustomMateri'

const PageMateriPembelajaran = ({ authStore, envStore, navigationStore, ...props }) => {
    let companyId = props.match.params.companyId
    let levelId = props.match.params.levelId
    let productId = props.match.params.productId
    let kelasId = props.match.params.kelasId
    return (
        <TableWrapper
            title={t.translate('menu.materi')}
            baseId="mod-companies-materi "
            useFilter={authStore.user ? authStore.user.isSupport : false}
            // render={(data) => (
            //     // <>
            //     //     {JSON.stringify(data)}
            //     // </>
            //     <div className="mpk-padding-N padding-all mpk-animation slide-in mpk-full full-width">
            //         <Masonry
            //             className="mpk-full full-width"
            //             baseId="mod-companies-masonry"
            //             items={
            //                 data.map(a => (
            //                     <CustomMateri
            //                         key={`company-${a.id}`}
            //                         value={a}
            //                         href={`/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/assign-nilai/${a.id}`}
            //                     />
            //                 ))
            //             }
            //         />
            //     </div>
            // )}
            columns={[
                {
                  label: t.translate("column.name"),
                  searchable: true,
                  key: "namaMateri",
                  render: (item) => item.namaMateri,
                },
              ]}
              itemActions={[
                new TableWrapper.action("Pilih", "mdi mdi-details", (item) => {
                  navigationStore.redirectTo(
                    `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/assign-nilai/${item.id}`
                  );
                }),
              ]}
              showFilter={false}
            onFetchData={(query) => {
                return (
                    new Promise(async (resolve, reject) => {
                        try {
                            // query = {
                            //   ...search,
                            //   ...query
                            // }
                            // console.log(search)
                            let getPage = await services.getUserMateri(productId, {})
                            console.log(getPage, "getPage")
                            // getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                            resolve(getPage)
                        } catch (e) {
                            reject(e)
                            // ErrorService(e)
                        }
                    })
                )
                // return authStore.user.isSupport
                //   ? httpService.stream(`${envStore.env.sso.host}/api/user_console/company_support`, query, {}, {
                //     useDefaultHost: false,
                //     useDefaultBaseUrl: false,
                //   })
                //   : new Promise(resolve => {
                //     let { companies } = authStore.user
                //     resolve({
                //       data: companies,
                //       headers: {
                //         'x-pagination-count': companies.length,
                //       }
                //     })
                //   })
            }}
        />
    )
}

export default inject('authStore', 'envStore', "navigationStore")(observer(PageMateriPembelajaran))
