import t from "counterpart";

export const role = {
    ROLE_ADMIN : "ROLE_ADMIN",
    ROLE_PENGAJAR : "ROLE_PENGAJAR",
    ROLE_PESERTA : "ROLE_PESERTA"
}

export const metodePembelajaranType = {
    TEORI: "Teori",
    PRAKTEK: "Praktek"
};