import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  FileInput,
  Text,
  TextField,
  CircularProgress,
  TextIconSpacing,
} from "react-md";
import service from "../../services/ujian.service";
import userUjianService from "../../services/userUjian.service";
import t from "counterpart";
import iziToast from "izitoast";
import { TableWrapper } from "../../libs/react-mpk/wrapper";

const InputNilaiTemp = ({
  visible = false,
  onRequestClose = () => { },
  ...props
}) => {
  const [ready, setReady] = useState(false);
  const [item, setItem] = useState([]);
  const [exam, setExam] = useState({});

  const initItem = async () => {
    if (visible) {
      if (props.item) {
        try {
          let res = await service.getSoalUjian(props.item.ujianId, "Essay");

          let { data, status } = res;
          data.map((d) => {
            d.pointSoal = d.pointSoal ? d.pointSoal : "";
          });

          console.log(data);
          setItem(data);
        } catch (err) {
          iziToast.error({
            message: err.message,
          });
        }

        // try {
        //   let res = await userUjianService.getUserUjianById(props.item.id);
        //   let { data } = res;
        //   setExam(data);
        // } catch (err) {
        //   iziToast.error({
        //     message: err.message,
        //   });
        // }
      }
    } else {
    }
    setReady(visible);
  };

  useEffect(() => { }, [item]);

  useEffect(() => {
    initItem();
  }, [visible]);

  const closeForm = () => {
    setItem([]);
    onRequestClose();
  };

  const changePoint = (e, i) => {
    let temp = [...item];

    temp[i].pointSoal = e.target.value;
    // setItem(temp);
    console.log(e.target.value)
    // console.log(temp[i].batasPoin.toString().length - 1)
    // console.log(typeof (temp[i].batasPoin.toString()))
    if (temp[i].pointSoal > temp[i].batasPoin) {
      temp[i].pointSoal = ""
      console.log(temp[i].pointSoal)
      // temp[i].pointSoal.substring(1, temp[i].batasPoin.toString().length - 1)
      // iziToast.success({
      //   message: "success",
      // });
      alert("Point Soal TIdak boleh lebih besar dari batas nilai")
      // console.log(a)
    }
    // else{
    // temp[i].pointSoal.substring(1, temp[i].batasPoin.toString().length - 1)
    // setItem(temp);
    // }
    setItem(temp);
  };

  const submit = async () => {
    try {
      let res = await service.assignScore(props.item.ujianId, item);

      iziToast.success({
        message: "success",
      });

      TableWrapper.updateDataItem("view-user-ujian", { id: item.id }, item);
      TableWrapper.reload("view-user-ujian");

      onRequestClose();
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  return (
    ready && (
      <Dialog
        visible={visible}
        onRequestClose={() => closeForm()}
        style={{
          width: "800px",
          height: "600px",
        }}
      >
        <DialogContent>
          <Text
            style={{
              textAlign: "center",
              fontSize: "35px",
            }}
          >
            {t.translate("table.aturPoint")}
          </Text>

          {item.map((d, i) => (
            <>
              <Text type="headline-5">{d.soalPertanyaan}</Text>
              <Text>Jawaban : {d.jawabanUjian}</Text>
              <Text style={{ color: "red" }}>Batas point input nilai : {d.batasPoin}</Text>
              <Text type="body-1">{d.jawabanUjian}</Text>
              <TextField
                placeholder={t.translate("form.nilai")}
                type="number"
                max={d.batasPoin}
                // maxLength={d.batasPoin.length}
                min={0}
                id={`examPoint-${i}`}
                value={d.pointSoal}
                onChange={(e) => changePoint(e, i)}
              />
            </>
          ))}
        </DialogContent>

        <DialogFooter>
          <Button
            onClick={() => {
              onRequestClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              submit();
            }}
            theme="primary"
            themeType="contained"
          >
            Submit
          </Button>
        </DialogFooter>
      </Dialog>
    )
  );
};

export default InputNilaiTemp;
