import React from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { PageWrapper } from '../../libs/react-mpk/wrapper'
import { sso } from '../../libs/react-mpk/hocs'
import PageProduct from '../menu/level/pageProduct';
import { FontIcon, List, ListItem } from 'react-md'
import { role as roles } from "../../constant/appConstant";
import { Redirect } from 'react-router-dom/cjs/react-router-dom'

const Level = ({ history, ...props }) => {
  let role = localStorage.getItem("getRole")
  let companyId = props.match.params.companyId
  let levelId = props.match.params.levelId
  let chooseProduct

  if (role === "ROLE_ADMIN") {
    chooseProduct = [
      {
        label: "Level Product",
        children: [
          {
            label: "Choose Product",
            index: "choose, choose",
            resourceUri: "",
            iconClassName: "mdi mdi mdi-view-dashboard",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product`,
            childPath: [],
          },
        ],
      },
    ]
  }
  else if (role === "ROLE_PENGAJAR") {
    chooseProduct = [
      {
        label: "Level Product",
        children: [
          {
            label: "Choose Product",
            index: "choose, choose",
            resourceUri: "",
            iconClassName: "mdi mdi mdi-view-dashboard",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product`,
            childPath: [],
          },
        ],
      },
    ]
  }
  else {
    chooseProduct = [
      {
        label: "Level Product",
        children: [
          {
            label: "Choose Product",
            index: "choose, choose",
            resourceUri: "",
            iconClassName: "mdi mdi mdi-view-dashboard",
            path: `/product/company/${companyId}/choose-level/${levelId}/choose-product`,
            childPath: [],
          },
        ],
      }
    ]
  }
  return (
    <PageWrapper
      sidebarCollapsible={true}
      inverseTheme={true}
      sidebarMenu={chooseProduct}
      sidebarHeader={(
        <>
          <List style={{ width: '100%', background: 'white', padding: '0px', height: '65px' }}>
            <ListItem style={{ marginTop: '-3px' }} secondaryText={`Kembali`} 
            // rightAddon={<FontIcon onClick={() => {
            //   window.location.href = `/product/company/${companyId}/choose-level`
            // }} iconClassName="mdi mdi-arrow-left"></FontIcon>}
            rightAddon={
              <FontIcon
                onClick={() => {
                  window.location.href = `/product/company/${companyId}/choose-level`;
                }}
                iconClassName="mdi mdi-arrow-left"
              ></FontIcon>
            }
            >

            </ListItem>
          </List>
        </>
      )}
    >
      <Router history={history}>
        <Switch>
          {role === roles.ROLE_ADMIN || role === roles.ROLE_PENGAJAR || role === roles.ROLE_PESERTA ? (
            <Route
              path='/product/company/:companyId/choose-level/:levelId/choose-product'
              exact={true}
              render={props => (
                <PageProduct {...props} />
              )}
            />
          ) : (
            <Route path='/product/company/:companyId/choose-level/:levelId/choose-product'>
               <Redirect to={"/error"} />
            </Route>
          )} 
        </Switch>
      </Router>
    </PageWrapper>
  )
}

export default sso({
  basePath: '/product/company/:companyId',
  url: {
    me: `/api/sso/company/:companyId/me`
  }
})(Level)
