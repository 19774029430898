import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  Text,
  Radio,
  Card,
  DialogHeader,
  TextIconSpacing,
  FontIcon,
} from "react-md";
import service from "../../../services/latihan.service";
import iziToast from "izitoast";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";

const ViewSoalMultiple = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  const calculateTimeLeft = () => {
    if(props?.item?.tanggalAkhirLatihan){
      const difference = new Date(props.item.tanggalAkhirLatihan) - new Date();
      return difference > 0 ? Math.floor(difference / 1000) : 0;
    }
    else return 0;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());


  const [ready, setReady] = useState(false);
  const [item, setItem] = useState([]);
  const [name, setName] = useState("");
  let [getDate, setGetDate] = useState("");

  const initItem = async () => {
    if (visible) {
      if (props.item) {
        setName(props.item.judulLatihan);
        let finishTime = new Date(props.item.tanggalAkhirLatihan).getTime();
        let now = new Date().getTime();
        let getTime = finishTime - now;
        let days = Math.floor(getTime / (1000 * 60 * 60 * 24));
        setGetDate(days);
        try {
          let res = await service.getSoalLatihan(
            props.item.id,
            "Multiple Choice"
          );

          let { data, status } = res;
          data.map((d) => {
            d.jawabanLatihan = d.jawabanLatihan ? d.jawabanLatihan : "";
            d.options = [];
            d.options.push(
              {
                id: "A",
                title: d.jawabanA,
              },
              {
                id: "B",
                title: d.jawabanB,
              },
              {
                id: "C",
                title: d.jawabanC,
              },
              {
                id: "D",
                title: d.jawabanD,
              }
            );
            // console.log(res);
          });
          setItem(data);
        } catch (err) {
          iziToast.error({
            message: err.message,
          });
        }

        // try {
        //   let res = await userUjianService.getUserUjianById(props.item);
        //   let { data } = res;
        //   setExam(data);
        //   console.log(data)
        // } catch (err) {
        //   iziToast.error({
        //     message: err.message,
        //   });
        // }
      }
    } else {
    }
    setReady(visible);
  };


  useEffect(() => {
    if (visible) {
      if(props?.item?.tanggalAkhirLatihan){
        // console.log(calculateTimeLeft());
        // console.log(props.item.dikerjakan);
        if (calculateTimeLeft() > 0 && props.item.dikerjakan != 3) {
          const timerId = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
            console.log("SEDANG BERJALAN")
          }, 1000);
          return () => clearInterval(timerId);
        } else if(props.item.dikerjakan != 3) {
          console.log("SUBMIT OROMATIS")
          submit();
        }
        else {
          console.log("SUDAH SELESAI , CUMA VIEW")
        }
      }
    }
    setReady(visible);
  }, [visible, timeLeft]);

//   useEffect(() => {}, [item]);

  useEffect(() => {
    initItem();
  }, [visible]);

  const closeForm = () => {
    setItem([]);
    onRequestClose();
  };

  const submit = async () => {
    // console.log(item);
    try {
      let res = await service.answerSubmit(props.item.id, item);

      iziToast.success({
        message: "success",
      });

      TableWrapper.updateDataItem(props.tableId, { id: item.id }, item);
      TableWrapper.reload(props.tableId);

      onRequestClose();
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };
  const changeAnswer = (e, idx) => {
    let temp = [...item];
    temp[idx].jawabanUjian = e.target.value;

    setItem(temp);
  };

  const selectOption = (e, idx, items) => {
    let temp = [...item];
    temp[idx].jawabanLatihan = items;
    setItem(temp);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    ready && (
      <Dialog
        visible={visible}
        modal
        disableEscapeClose
        onRequestClose={() => closeForm()}
        style={{
          width: "1100px",
          height: "95vh",
          borderRadius: "5px"
        }}
      >
        {/* <DialogHeader>Durasi Pengerjaan Sisa {getDate} Hari</DialogHeader> */}
        <DialogHeader style={{ paddingBottom: "0px" }}>
          <div style={{ width: "100%" }}>
                <div style={{ color: "blue", fontWeight: "bold", fontSize: "25px", width: "100%" }}>{name?.toUpperCase()}</div>
                <div style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
                  SISA WAKTU : {formatTime(calculateTimeLeft())}
                </div>
                <hr></hr>
            </div>
        </DialogHeader>
        <DialogContent style={{ paddingTop: "5px" }}>
          {/* <Text
            style={{
              textAlign: "center",
              fontSize: "35px",
            }}
          >
            {name}
            <hr></hr>
          </Text> */}

          {item.map((d, i) => (
            <>
              <Card
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  padding: "10px 20px",
                  background: "#f2f2f2",
                }}
              >
                <Text style={{ fontSize: "20px", textAlign: "justify" }}>
                  <b>{i+1}. {d.soalPertanyaan}</b>
                  {d.options.map((item, j) => (
                    <Radio
                      id={`multipleChoice${d.id}`}
                      name={`multipleChoice${d.id}`}
                      // label={`Nama ${item.title}, ID ${item.id}, Index ${j}`}
                      label={item.title}
                      value={item.title}
                      onChange={(e) => selectOption(e, i, item.title)}
                      checked={item.title == d.jawabanLatihan}
                      disabled={
                        calculateTimeLeft() <= 0
                      }
                    />
                  ))}
                </Text>
              </Card>
            </>
          ))}
        </DialogContent>

        <DialogFooter style={{ paddingTop: "10px", paddingBottom: "10px"}}>
        <Button
            theme="secondary"
            themeType="outline"
            style={{ margin: "5px" }}
            onClick={() => {
              onRequestClose();
            }}
          >
            <TextIconSpacing
              icon={<FontIcon iconClassName="mdi mdi-close" />}
            >
              BATAL
            </TextIconSpacing>
          </Button>
          <Button
            onClick={() => {
              submit();
            }}
            style={{ margin: "5px", marginRight: "12px" }}
            theme="primary"
            themeType={calculateTimeLeft() <= 0 ? "outline" : "contained"}
            disabled={
              calculateTimeLeft() <= 0 ? true : false
            }
          >
             <TextIconSpacing
              icon={<FontIcon iconClassName="mdi mdi-check-all" />}
            >
              SUBMIT JAWABAN
            </TextIconSpacing>
          </Button>
        </DialogFooter>
      </Dialog>
    )
  );
};

export default ViewSoalMultiple;
