import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import { cloneDeep } from 'lodash'
import t from 'counterpart'
import service from '../../services/grade.service';
import { TableWrapper } from '../../libs/react-mpk/wrapper';

const defaultData = () => ({
    id: '',
    grade: '',
    batasNilaiAtas: '',
    batasNilaiBawah: '',
    predikat: '',
})
const CreateGrade = ({
    visible = false,
    onRequestClose = () => { },
    ...props
}) => {

    const grade = [
        { id: "A", nama: "A" },
        { id: "B", nama: "B" },
        { id: "C", nama: "C" },
        { id: "D", nama: "D" },
        { id: "F", nama: "F" },
    ];

    const predikat = [
        { id: "SANGAT BAIK", nama: "SANGAT BAIK" },
        { id: "BAIK", nama: "BAIK" },
        { id: "CUKUP", nama: "CUKUP" },
        { id: "KURANG", nama: "KURANG" },
    ];
    let [dataSubmit, setDataSubmit] = useState({
        id: '',
        grade: '',
        batasNilaiAtas: '',
        batasNilaiBawah: '',
        predikat: ''
    })
    let [item, setItem] = useState(null)
    let [ready, setReady] = useState(true)

    useEffect(() => {
        async function initData() {
            if (visible) {
                let selectedItem = defaultData();
                console.log(props)
                if (props.item) {
                    // console.log(props.item)
                    try {
                        service.getGradeById(props.item.id).then((res) => {
                            // console.log(res)
                            setDataSubmit(res);
                        }).catch((e) => {
                            // console.log(e)
                        })
                        selectedItem = cloneDeep(props.item)
                        selectedItem.number = String(selectedItem.number)
                    } catch (e) {
                        // onRequestClose()
                    }
                }
                setItem(selectedItem)
            } else {
                setItem(defaultData())
            }
            setReady(visible)
        }
        initData()
    }, [visible])


    const onSubmit = async (data, callback) => {
        // console.log(data)
        let reqData = dataSubmit;
        // console.log(reqData)
        if (reqData && reqData.data && reqData.data.id) {
            try {
                const resp = await service.update(data, data.id)
                // console.log(resp)
                TableWrapper.updateDataItem(
                    "view-grade",
                    { id: data.id },
                    data
                );
                TableWrapper.reload("view-grade");
                callback('Edit Success', false)
            } catch (error) {
                // console.log(error.message)
                callback('Edit Error ' + error.message, false)
            }
        }
        else {
            try {
                const resp = await service.createGrade(data)
                // console.log(resp)
                TableWrapper.updateDataItem(
                    "view-grade",
                    { id: data.id },
                    data
                );
                TableWrapper.reload("view-grade");
                callback('Create Success', false)
            } catch (error) {
                // console.log(error.message)
                callback('Create Error ' + error.message, false)
            }
        }
    }

    return ready && (
        <DataForm
            baseId="create-grade"
            title={t.translate('modules.form.grade')}
            visible={visible}
            // data={item}
            onRequestClose={onRequestClose}
            asDialog={true}
            defaultData={props.item || defaultData()}
            definitions={[
                {
                    inputType: DataForm.inputTypes.SELECT,
                    label: t.translate("table.grade"),
                    key: "grade",
                    theme: "filled",
                    options: grade.map((d) => ({
                        value: d.id,
                        children: d.nama,
                    })),
                    validation: "required",
                },
                {
                    inputType: DataForm.inputTypes.SELECT,
                    label: t.translate("table.predikat"),
                    key: "predikat",
                    theme: "filled",
                    options: predikat.map((d) => ({
                        value: d.id,
                        children: d.nama,
                    })),
                    validation: "required",
                },
                {
                    inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
                    // type:"number",
                    label: t.translate("table.batasNilaiBawah"),
                    key: 'batasNilaiBawah',
                    min: 0,
                    max: 100,
                    validation: 'required'
                },
                {
                    inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
                    // type:"number",
                    label: t.translate("table.batasNilaiAtas"),
                    key: 'batasNilaiAtas',
                    min: 0,
                    max: 100,
                    validation: 'required'
                },
                // {
                //     inputType: DataForm.inputTypes.PRE_DATA
                // }
            ]}
            onBeforeChange={(key, value) => {
                // console.log(key, value)
                return value
            }}
            onSubmit={onSubmit}
        />
    )
}

export default CreateGrade