import iziToast from "izitoast";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  FileInput,
  Text,
  TextField,
  TextIconSpacing,
} from "react-md";
import { http } from "../../libs/react-mpk/services";
import { TableWrapper } from "../../libs/react-mpk/wrapper";

const UploadPdf = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  const [ready, setReady] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [item, setItem] = useState({});
  const chunkSize = 1048576 * 2;
  const [counter, setCounter] = useState(1);
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  const [fileGuid, setFileGuid] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [progress, setProgress] = useState(0);
  const [fileToBeUpload, setFileToBeUpload] = useState({});
  const [chunkCount, setChunkCount] = useState(0);

  const initData = async () => {
    if (visible) {
      let selectedItem = {};
      if (props.item) {
        selectedItem = cloneDeep(props.item);
      }
      setItem(selectedItem);
    } else {
      setItem({});
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  useEffect(() => {
    if (fileSize > 0) {
      uploadFile(counter);
    }
  }, [item, progress]);

  const getFileContext = (e) => {
    resetChunkProperties();
    const _file = e.target.files[0];
    setFileSize(_file.size);
    const _totalCount =
      _file.size % chunkSize == 0
        ? _file.size / chunkSize
        : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount);
    setFileToBeUpload(_file);
    const _fileID = _file.name;
    setFileGuid(_fileID);
  };

  const resetChunkProperties = () => {
    setCounter(1);
    setBeginingOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
  };

  const uploadFile = async () => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      try {
        uploadChunk(chunk);
        //   callback("success", false);
      } catch (err) {
        iziToast.error({
          message: err.message,
        });
      }
    }
  };

  const uploadChunk = async (chunk) => {
    setShowProgress(true);
    try {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          namaFile: fileGuid,
          totalCounter: chunkCount,
          nowCounter: counter
        },
      };
      const response = await http.upload(
        `/materipembelajaran/upload/pdf/${item.id}`,
        chunk,
        {},
        config
      );
      const data = response.data;
      //   if (data.isSuccess) {
      setBeginingOfTheChunk(endOfTheChunk);
      setEndOfTheChunk(endOfTheChunk + chunkSize);
      if (counter == chunkCount) {
        console.log("Process is complete, counter", counter);
        // await uploadCompleted(item);
        setShowProgress(false);
        onRequestClose();
        resetData();

        TableWrapper.updateDataItem("view-materi", { id: item.id }, item);
        TableWrapper.reload("view-materi");
        iziToast.success({
          message: "success",
        });
      } else {
        var percentage = (counter / chunkCount) * 100;
        setProgress(percentage);
      }
      //   }
    } catch (error) {
      iziToast.error({
        message: error.message,
      });
      setShowProgress(false);
      resetData();
      console.log("error", error);
    }
  };

  const resetData = () => {
    setShowProgress(false);
    // setItem({});
    setCounter(1);
    setBeginingOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
    setFileGuid("");
    setFileSize(0);
    setProgress(0);
    setFileToBeUpload({});
    setChunkCount(0);
  };

  return (
    ready && (
      <div>
        <Dialog visible={visible} onRequestClose={onRequestClose}>
          <DialogHeader>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 type="headline-4">Upload PDF</h1>
              {showProgress && progress.toString().split(".")[0] + " %"}
            </div>
          </DialogHeader>

          <DialogContent style={{ marginBottom: "10%" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FileInput
                id={`videoFile`}
                buttonType="text"
                theme="primary"
                themeType="contained"
                onChange={(e) => getFileContext(e)}
                accept="application/pdf"
              >
                File PDF
              </FileInput>

              <TextField
                id={`videoName`}
                value={fileGuid}
                style={{ flexGrow: "2" }}
              />
            </div>
          </DialogContent>

          <DialogFooter>
            <Button
              onClick={() => {
                resetData();
                onRequestClose();
              }}
              disabled={showProgress}
            >
              Cancel
            </Button>

            <Button
              id={`btnExcel`}
              style={{ marginTop: "2%" }}
              theme="primary"
              themeType="contained"
              onClick={(e) => uploadFile(e)}
              disabled={showProgress}
            >
              <TextIconSpacing
                iconAfter={true}
                icon={
                  showProgress ? (
                    <CircularProgress id={`progressExcel`} centered={false} />
                  ) : null
                }
              >
                Upload
              </TextIconSpacing>
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    )
  );
};

export default UploadPdf;
