import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import service from "../../services/remedy.service";
import ujianService from "../../services/ujian.service";
import { inject, observer } from "mobx-react";
import ViewSoalUjianEssay from "../Ujian/Essay/ViewSoalUjianEssay";
import ViewSoalUjianPraktek from "../Ujian/Praktek/ViewSoalUjianPraktek";
import iziToast from "izitoast";
import ViewSoalUjianpg from "../Ujian/Multiple/ViewSoalUjianpg";
import RemedyForm from "./RemedyForm";

const TheoryRemedial = ({
  className = "",
  showCommandbar = true,
  modalStore,
  ...props
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showEssay, setShowEssay] = useState(false);
  const [showPilgan, setShowPilgan] = useState(false);
  const [showPraktek, setShowPraktek] = useState(false);
  const [duration, setDuration] = useState(0);

  const [showImport, setShowImport] = useState(false);

  let productId = props.match.params.productId;
  let companyId = props.match.params.companyId;
  let kelasId = props.match.params.kelasId

  let userId = localStorage.getItem("getUserId");
  const role = localStorage.getItem("getRole");
  let act;

  const deleteExam = async (item, callback) => {
    try {
      let res = await service.deleteRemedy(item.id);

      let { data, status } = res;

      if (status == 200) {
        callback("success", false);
        TableWrapper.updateDataItem("exam-table", { id: item.id }, item);
        TableWrapper.reload("exam-table");
      }
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  const showDeleteExam = (item) => {
    modalStore.showConfirm({
      title: t.translate("form.deleteConfirm"),
      children: t.translate("message.ujianDelete"),
      onSubmit: (callback) => {
        deleteExam(item, callback);
        callback();
      },
    });
  };

  return (
    <>
      <TableWrapper
        baseId="theory-remedial-table"
        title={t.translate("tab.remedy")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="judulUjian"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        multiColumnFilter={false}
        showFilter={false}
        columns={[
          {
            label: "Status",
            searchable: true,
            key: "dikerjakan",
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.dikerjakan == 1
                    ? TableWrapper.Status.types.IDLE
                    : item.dikerjakan == 2
                    ? TableWrapper.Status.types.PROGRESS
                    : TableWrapper.Status.types.SUCCESS
                }
              >
                {item.dikerjakan == 1
                  ? "Belum Dimulai"
                  : item.dikerjakan == 2
                  ? "Sedang Berjalan"
                  : "Selesai"}
              </TableWrapper.Status>
            ),
          },
          // {
          //   label: t.translate("table.judulRemedial"),
          //   searchable: true,
          //   key: "namaUjian",
          //   render: (item) => item.judulUjian,
          // },
          {
            label: "Nama Peserta",
            searchable: true,
            key: "namaPengguna",
            render: (item) => item.namaPengguna,
          },
          {
            label: t.translate("table.durasiUjian") + " (Menit)",
            searchable: false,
            key: "durasiUjian",
            render: (item) => item.durasiUjian,
          },
          {
            label: t.translate("table.tglMulai"),
            type: TableWrapper.dataTypes.DATE,
            key: "tanggalMulaiUjian",
            render: (item) => moment(item.tanggalMulaiUjian).format("DD MMMM YYYY, HH:mm:ss"),
          },
          {
            label: t.translate("table.tglSelesai"),
            type: TableWrapper.dataTypes.DATE,
            key: "tanggalAkhirUjian",
            render: (item) => moment(item.tanggalAkhirUjian).format("DD MMMM YYYY, HH:mm:ss"),
          },
          {
            label: t.translate("table.namaMateri"),
            searchable: true,
            key: "namaMateri",
            render: (item) => item.namaMateri,
          },
        ]}
        // actions={act}
        actions={[
          // new TableWrapper.action(
          //   "Add",
          //   "mdi mdi-plus",
          //   () => {
          //     setSelectedItem(null);
          //     setShowForm(true);
          //   },
          //   role.includes("ADMIN")
          // ),
          // new TableWrapper.action(
          //   "Import",
          //   "mdi mdi-upload",
          //   () => {
          //     setSelectedItem(null);
          //     setShowImport(true);
          //   },
          //   true
          // ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item.id);
              setShowForm(true);
            },
            role.includes("ADMIN")
          ),
          new TableWrapper.action(
            "Kerjakan Soal Remedial Essay",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setDuration(item.durasiUjian);
              setShowEssay(true);
            },
            (item) => ((role.includes("ADMIN") && item.isEssay) || (item.isEssay && item.dikerjakan === 2) ? true : false)
            // (item) => role.includes("ADMIN") || item.isEssay
          ),
          new TableWrapper.action(
            "Kerjakan Soal Remedial Pilihan Ganda",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setDuration(item.durasiUjian);
              setShowPilgan(true);
            },
            // true
            // (item) => role.includes("ADMIN") || item.isPg
            (item) => ((role.includes("ADMIN") && item.isPg) || (item.isPg && item.dikerjakan === 2) ? true : false)
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => showDeleteExam(item),
            role.includes("ADMIN")
          ),
          // new TableWrapper.action(
          //   "Kerjakan Soal Pilihan Ganda",
          //   "mdi mdi-pencil",
          //   (item) => {
          //     setSelectedItem(item);
          //     setDuration(item.durasiUjian);
          //     setShowPilgan(true);
          //   },
          //   (item) => (item.isPg ? true : false)
          // ),
          // new TableWrapper.action(
          //   "Upload Jawaban Praktek",
          //   "mdi mdi-upload",
          //   (item) => {
          //     setSelectedItem(item.id);
          //     setShowPraktek(true);
          //   },
          //   (item) => (item.isPraktek && item.dikerjakan === 2 ? true : false)
          // ),
        ]}
        onFetchData={(query) => {
          query["isPg.equals"] = true;
          query["isEssay.equals"] = true;
          // query["userId.equals"] = userId;
          query["levelProdukId.equals"] = productId;
          query["kodePerusahaan.equals"] = companyId;
          query["kodeKelas.equals"] = kelasId
          // query['dikerjakan.greaterThan'] = 1
          return new Promise(async (resolve, reject) => {
            try {
              let res = await service.getRemedy(query);
              res.headers["x-pagination-count"] = res.headers["x-total-count"];
              // console.log(res);
              resolve(res);
            } catch (e) {
              reject(e);
              // ErrorService(e)
            }
          });
        }}
        // onFetchData={ async query => {
        //   return service.getData()
        // }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />

      <ViewSoalUjianEssay
        visible={showEssay}
        onRequestClose={() => {
          setShowEssay(false);
          setDuration(0);
          setSelectedItem(null);
        }}
        item={selectedItem}
        duration={duration}
        tableId={"theory-remedial-table"}
      />
      <ViewSoalUjianpg
        visible={showPilgan}
        onRequestClose={() => {
          setShowPilgan(false);
          setDuration(0);
          setSelectedItem(null);
        }}
        item={selectedItem}
        duration={duration}
        tableId={"theory-remedial-table"}

      />

      <ViewSoalUjianPraktek
        visible={showPraktek}
        onRequestClose={() => {
          setShowPraktek(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        tableId={"theory-remedial-table"}

      />
      <RemedyForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        type={"update"}
        // productId={props.match.params.productId}
        tableId={"theory-remedial-table"}
        metodePembelajaran={"teori"}
        {...props}
      />
    </>
  );
};

export default inject("modalStore")(observer(TheoryRemedial));
