import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import latihanService from "../../services/latihan.service";

const defaultData = () => ({
  file: null,
});

const ImportAssignment = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  // useEffect(() => {
  // 	if (visible) {
  // 		let selectedItem = defaultData();
  // 		if (props.item) {
  // 			selectedItem = cloneDeep(props.item)
  // 			selectedItem.number = String(selectedItem.number)
  // 		}
  // 		setItem(selectedItem)
  // 	} else {
  // 		setItem(defaultData())
  // 	}
  // 	setReady(visible)
  // }, [visible])
  let companyId = props.match.params.companyId
  let kelasId = props.match.params.kelasId

  const submitHandler = async (item, callback) => {
    try {
        let res = await latihanService.importAssignment(item.file, companyId, kelasId);

        TableWrapper.updateDataItem(
          props.tableId,
          { id: item.id },
          item
        );
        TableWrapper.reload(props.tableId);
        callback("success", false);
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  return (
    ready && (
      <DataForm
        baseId="import-exam"
        title={t.translate("form.importExcercise")}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.FILE_INPUT,
            label: "File",
            key: "file",
            accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
          },
        ]}
        onChange={(data, key, value) => {
          return data;
        }}
        onSubmit={submitHandler}
      />
    )
  );
};

export default ImportAssignment;
