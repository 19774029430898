import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    FontIcon,
    Radio,
    Text,
    TextIconSpacing,
} from "react-md";
import service from "../../services/ujian.service";
import t from "counterpart";
import iziToast from "izitoast";

const ViewPilgan = ({
    visible = false,
    onRequestClose = () => { },
    ...props
}) => {
    const [ready, setReady] = useState(false);
    const [item, setItem] = useState([]);
    const [exam, setExam] = useState({});

    const initItem = async () => {
        if (visible) {
            if (props.item) {
                try {
                    let res = await service.getSoalUjian(props.item.ujianId, "Multiple Choice");

                    let { data, status } = res;
                    data.map((d) => {
                        d.jawabanUjian = d.jawabanUjian ? d.jawabanUjian : "";
                        d.options = []
                        d.options.push(
                            {
                                id: "A",
                                title: d.jawabanA,
                            },
                            {
                                id: "B",
                                title: d.jawabanB,
                            },
                            {
                                id: "C",
                                title: d.jawabanC,
                            },
                            {
                                id: "D",
                                title: d.jawabanD,
                            },
                        )
                    });
                    console.log(data);
                    setItem(data);
                } catch (err) {
                    iziToast.error({
                        message: err.message,
                    });
                }
            }
        }
        setReady(visible);
    };

    useEffect(() => { }, [item]);

    useEffect(() => {
        initItem();
    }, [visible]);

    const closeForm = () => {
        setItem([]);
        onRequestClose();
    };
    const selectOption = (e, idx, items) => {
        let temp = [...item]
        temp[idx].jawabanUjian = items
        setItem(temp)

    }
    return (
        ready && (
            <Dialog
                visible={visible}
                onRequestClose={() => closeForm()}
                style={{
                    width: "1100px",
                    height: "95vh",
                    borderRadius: "5px"
                }}
            >
                <DialogHeader style={{ paddingBottom: "0px" }}>
                    <div style={{ width: "100%" }}>
                        <div style={{ color: "blue", fontWeight: "bold", fontSize: "25px", width: "100%" }}>{props.item?.judulUjian?.toUpperCase()}</div>
                        <div style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
                            NAMA PESERTA : {props.item?.namaUser}
                        </div>
                        <hr></hr>
                    </div>
                </DialogHeader>
                <DialogContent>
                    {item.map((d, i) => (
                        <>
                            <Card
                                style={{
                                width: "100%",
                                // marginTop: "5px",
                                marginBottom: "10px",
                                background: "#f2f2f2",
                                padding: "15px 20px"
                                }}
                            >
                                <Text style={{ fontSize: "20px", textAlign: "justify" }}>
                                    <b>{i + 1}. {d.soalPertanyaan}</b>
                                </Text>
                                {d.options.map((item, j) => (
                                    <Radio
                                        id={`multipleChoice${d.id}`}
                                        name={`multipleChoice${d.id}`}
                                        // label={`Nama ${item.title}, ID ${item.id}, Index ${j}`}
                                        label={item.title}
                                        value={item.title}
                                        checked={item.title == d.jawabanUjian}
                                        disabled={true}
                                    />
                                ))}
                            </Card>
                        </>
                    ))}
                </DialogContent>

                <DialogFooter>
                    <Button
                        theme="secondary"
                        themeType="outline"
                        onClick={() => {
                            onRequestClose();
                        }}
                        style={{ marginRight: "12px" }}
                    >
                        <TextIconSpacing
                            icon={<FontIcon iconClassName="mdi mdi-close" />}
                            >
                            BATAL
                        </TextIconSpacing>
                    </Button>
                </DialogFooter>
            </Dialog>
        )
    );
};

export default ViewPilgan;
