import React, { useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import service from "../../../services/soal.service";
import CreateSoal from "../CreateSoal";
import Confirm from "../../Confrimation/Confirm";
import { http } from "../../../libs/react-mpk/services";
import FileSaver from "file-saver";
import { inject, observer } from "mobx-react";
import { Checkbox, Text } from "react-md";
import UploadExcelSoal from "../UploadExcelSoal";

const ViewPraktek = ({ className = "", showCommandbar = true, modalStore }) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemExcel, setSelectedItemExcel] = useState(false);
  const [showExcel, setShowExcel] = useState(false);

  const [checkAll, setCheckAll] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [activatedList, setActivatedList] = useState([]);

  const [showUpload, setShowUpload] = useState(false);
  const getRole = localStorage.getItem("getRole");

  const getExcelItem = (item) => {
    // console.log(item)

    let config = Object.assign({
      method: "get",
      responseType: "arraybuffer",
    });

    let options = Object.assign({
      fileType: null,
      fileName: item.kunciJawabanPraktek,
    });
    console.log(options);
    return new Promise(async (resolve, reject) => {
      try {
        let res = await (config.method === "post"
          ? http.post(`/soal/download/${item.id}`, item, config)
          : http.get(
              `/soal/download/${item.id}`,
              {
                file: "excel",
              },
              config
            ));
        let fileName =
          options.fileName ||
          res.headers["content-disposition"].split("filename=")[1];
        let type =
          options.fileType ||
          res.headers["content-type"].substring(
            0,
            res.headers["content-type"].indexOf(";")
          );
        let { data } = res;
        const blob = new Blob([res.data], { type: type });
        FileSaver.saveAs(blob, fileName);
        resolve(res)
      } catch (error) {
        reject(error);
      }
    });
  };

  const deleteHandler = async (item, callback) => {
    try {
      let res = await service.deleteById(item.id);

      TableWrapper.updateDataItem("soal-table", { id: item.id }, item);
      TableWrapper.reload("soal-table");

      callback("success", false);
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  const showDeleteConfirmation = (item) => {
    modalStore.showConfirm({
      title: t.translate("form.downloadConfirmation"),
      children: t.translate("message.download"),
      onSubmit: (callback) => {
        deleteHandler(item, callback);
      },
    });
  };

  const activateHandler = async (item, callback) => {
    item.isAktif = !item.isAktif;

    try {
      let res = await service.update(item, item.id);

      let { data } = res;

      callback("success", false);

      TableWrapper.updateDataItem("soal-table", { id: item.id }, item);
      TableWrapper.reload("soal-table");
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  const activateQuestion = (item) => {
    modalStore.showConfirm({
      title: t.translate("form.activateConfirmation"),
      children: t.translate("message.questionActivation"),
      onSubmit: (callback) => {
        activateHandler(item, callback);
        callback();
      },
    });
  };

  const addItem = (e, item) => {
    item.isAktif = !item.isAktif;
    let temp = [];

    if (!item.isAktif) {
      setCheckAll(true);
    }

    itemList.map((d) => {
      if (d.isAktif) {
        temp.push(d);
      }
    });

    if (temp.length == itemList.length) {
      setCheckAll(false);
    }

    setActivatedList([...activatedList, item]);
  };

  const addAllItem = (e) => {
    setCheckAll(!checkAll);
    let item = [];
    if (checkAll) {
      itemList.map((d) => {
        d.isAktif = true;
        item.push(d);
      });
      setActivatedList(item);
    } else {
      itemList.map((d) => {
        d.isAktif = false;
      });
      setActivatedList(itemList);
    }
    
  };

  const activateQuestionList = async (activatedList, callback) => {
    try {
      let res = await service.activateList(activatedList);
      let { data, status } = res;

      if (status == 200) {
        TableWrapper.updateDataItem("soal-table", { id: data.id }, data);
        TableWrapper.reload("soal-table");
        callback("success", false);
      }
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  const activateQuestionListConfirm = () => {
    modalStore.showConfirm({
      title: t.translate("form.scoreReleaseConfirm"),
      children: t.translate("message.confirmExamScoreRelease"),
      onSubmit: (callback) => {
        activateQuestionList(itemList, callback);
        callback();
      },
    });
  };

  const downloadQuestion = async (item, callback) => {
    try {
      let res = await service.downloadSoal(item.id, item.filename);
      callback("success", false);
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  const downloadHandler = (item) => {
    modalStore.showConfirm({
      title: t.translate("form.downloadConfirmation"),
      children: t.translate("message.download"),
      onSubmit: (callback) => {
        downloadQuestion(item, callback);
        callback();
      },
    });
  };

  return (
    <>
      <TableWrapper
        baseId="soal-table"
        title={t.translate("modules.table.praktek")}
        className={className}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        useFilter={true}
        // multiColumnFilter={true}
        columns={[
          {
            label: (
              <Checkbox
                value={checkAll}
                checked={!checkAll}
                onChange={(e) => addAllItem(e)}
              />
            ),
            searchable: false,
            type: TableWrapper.dataTypes.BOOLEAN,
            key: "boolean",
            render: (item) => (
              <Checkbox
                value={item}
                checked={item.isAktif}
                onChange={(e) => addItem(e, item)}
              />
            ),
          },
          {
            label: t.translate("table.status"),
            type: TableWrapper.dataTypes.BOOLEAN,
            searchable: false,
            key: "isAktif",
            definition: {
              inputType: TableWrapper.inputTypes.CHECKBOX,
              defaultValue: false,
              criterias: ["equals", "notEquals"],
            },
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.isAktif
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.isAktif
                  ? t.translate("table.active")
                  : t.translate("table.inActive")}
              </TableWrapper.Status>
            ),
          },
          {
            label: t.translate("table.questionCategory"),
            searchable: true,
            key: "kategoriSoal",
            render: (item) => item.kategoriSoal,
          },
          {
            label: t.translate("table.pertanyaan"),
            searchable: true,
            key: "pertanyaan",
            render: (item) => (<Text>{item.pertanyaan}</Text>),
            lineWrap: true,
            style:{maxWidth: "70vh", minWidth: '70vh'}
          },
          {
            label: t.translate("table.nameType"),
            searchable: false,
            key: "namaTipe",
            render: (item) => item.namaTipe,
          },
          {
            label: t.translate("table.nameMateri"),
            searchable: true,
            key: "namaMateri",
            render: (item) => item.namaMateri,
          },
          // {
          //     label: t.translate("table.jawaban"),
          //     searchable: true,
          //     key: "jawaban",
          //     render: (item) => item.jawaban,
          // },
          {
            label: t.translate("table.dibuatOleh"),
            key: "dibuatOleh",
            searchable: false,
            render: (item) => item.dibuatOleh,
          },
          {
            label: t.translate("table.tglDibuat"),
            type: TableWrapper.dataTypes.DATE,
            key: "tanggalDibuat",
            searchable: false,
            render: (item) => moment(item.tanggalDibuat).format("lll"),
          },
          {
            label: t.translate("table.diperbaharui"),
            key: "diperbaharuiOleh",
            searchable: false,
            render: (item) => item.diperbaharuiOleh,
          },
          {
            label: t.translate("table.tglDiperbaharui"),
            type: TableWrapper.dataTypes.DATE,
            searchable: false,
            render: (item) => moment(item.tanggalDiperbaharui).format("lll"),
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            getRole.includes("ADMIN")
          ),
          // new TableWrapper.action(
          //   "Upload Soal",
          //   "mdi mdi-upload",
          //   () => {
          //     setSelectedItem(null);
          //     setShowUpload(true);
          //   },
          //   getRole.includes("ADMIN")
          // ),
          new TableWrapper.action(
            "Activate",
            "mdi mdi-check",
            () => {
              setSelectedItem(null);
              activateQuestionListConfirm();
            },
            getRole.includes("ADMIN")
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setShowForm(true);
            },
            getRole.includes("ADMIN")
          ),
          new TableWrapper.action(
            "View Kunci Jawaban",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItemExcel(item);
              setShowExcel(true);
            },
            (item) => item.kunciJawabanPraktek
          ),
          new TableWrapper.action(
            "Upload Soal",
            "mdi mdi-upload",
            (item) => {
              setSelectedItem(item);
              setShowUpload(true);
            },
            getRole.includes("ADMIN")
          ),
          new TableWrapper.action(
            "Download Soal",
            "mdi mdi-download",
            (item) => {
              downloadHandler(item);
            },
            (item) => getRole.includes("ADMIN") && item.filename
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => showDeleteConfirmation(item),
            getRole.includes('ADMIN')
          ),
          new TableWrapper.action(
            (item) => (item.isAktif ? "De-activate" : "Activate"),
            (item) => (item.isAktif ? "mdi mdi-close" : "mdi mdi-check"),
            (item) => {
              activateQuestion(item);
            },
            getRole.includes("ADMIN")
          ),
        ]}
        onFetchData={(query) => {
          query["namaTipeSoal.contains"] = "Praktek";
          let item = [];
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
              };
              let getPage = await service.getSoalBytipe(query);
              // console.log(query);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];

              getPage.data.map((d) => {
                if (d.isAktif) {
                  item.push(d);
                }
              });

              if (item.length == getPage.data.length) {
                setCheckAll(false);
              } else {
                setCheckAll(true);
              }

              setItemList(getPage.data);
              resolve(getPage);
            } catch (e) {
              reject(e);
              // ErrorService(e)
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />

      <CreateSoal
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        type={'Praktek'}
        item={selectedItem}
      />
      <Confirm
        visible={showExcel}
        onRequestClose={() => {
          setShowExcel(false);
          setSelectedItemExcel(null);
        }}
        onSubmitRequest={() => {
          getExcelItem(selectedItemExcel);
          setShowExcel(false);
          setSelectedItemExcel(null);
        }}
        message={t.translate("message.materiExcel")}
      />

      <UploadExcelSoal
        visible={showUpload}
        onRequestClose={() => {
          setShowUpload(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        type={"Praktek"}
      />
    </>
  );
};

export default inject("modalStore")(observer(ViewPraktek));
