import React, { useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "../../../services/soal.service";
import moment from "moment";
import Confirm from "../../Confrimation/Confirm";
import CreateSoal from "../CreateSoal";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";
import { Checkbox, Text } from "react-md";

const ViewMultiple = ({
  className = "",
  showCommandbar = true,
  modalStore,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setEditShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  let [search, setSearch] = useState({});
  const [showDelete, setShowDelete] = useState(false);

  const [checkAll, setCheckAll] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [activatedList, setActivatedList] = useState([]);
  const [activationSearch, setActivationSearch] = useState(false);
  const getRole = localStorage.getItem("getRole");

  const showDeleteItem = (data) => {
    setShowDelete(true);
    setSelectedItem(data);
  };
  const deleteItem = async (item) => {
    try {
      let res = await service.deleteById(item.id);
      TableWrapper.updateDataItem("soal-table", { id: item.id }, item);
      TableWrapper.reload("soal-table");
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  const activateHandler = async (item, callback) => {
    item.isAktif = !item.isAktif;

    try {
      let res = await service.update(item, item.id);

      let { data } = res;

      callback("success", false);

      TableWrapper.updateDataItem("soal-table", { id: item.id }, item);
      TableWrapper.reload("soal-table");
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  const activateQuestion = (item) => {
    modalStore.showConfirm({
      title: t.translate("form.activateConfirmation"),
      children: t.translate("message.questionActivation"),
      onSubmit: (callback) => {
        activateHandler(item, callback);
        callback();
      },
    });
  };

  const addItem = (e, item) => {
    item.isAktif = !item.isAktif;
    let temp = [];

    if (!item.isAktif) {
      setCheckAll(true);
    }

    itemList.map((d) => {
      if (d.isAktif) {
        temp.push(d);
      }
    });

    if (temp.length == itemList.length) {
      setCheckAll(false);
    }

    setActivatedList([...activatedList, item]);
  };

  const addAllItem = (e) => {
    setCheckAll(!checkAll);
    let item = [];
    if (checkAll) {
      itemList.map((d) => {
        d.isAktif = true;
        item.push(d);
      });
      setActivatedList(item);
    } else {
      itemList.map((d) => {
        d.isAktif = false;
      });
      setActivatedList(itemList);
    }
  };

  const activateQuestionList = async (activatedList, callback) => {
    try {
      let res = await service.activateList(activatedList);
      let { data, status } = res;

      if (status == 200) {
        TableWrapper.updateDataItem("soal-table", { id: data.id }, data);
        TableWrapper.reload("soal-table");
        callback("success", false);
      }
    } catch (err) {
      callback(err.message, true, false);
    }
  };

  const activateQuestionListConfirm = () => {
    modalStore.showConfirm({
      title: t.translate("form.scoreReleaseConfirm"),
      children: t.translate("message.confirmExamScoreRelease"),
      onSubmit: (callback) => {
        activateQuestionList(itemList, callback);
        callback();
      },
    });
  };

  const searchQuestion = async (e) => {
    setActivationSearch(!activationSearch);
    let query = JSON.parse(localStorage.getItem("soal-table-query"));
    query["isAktif.equals"] = activationSearch;
    try {
      let res = await service.getSoal(query);
      let { data } = res;
      console.log(data);
    } catch (err) {}
  };

  return (
    <>
      <TableWrapper
        baseId="soal-table"
        title={t.translate("modules.table.multiple")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // multiColumnFilter={true}
        columns={[
          {
            label: (
              <Checkbox
                value={checkAll}
                checked={!checkAll}
                onChange={(e) => addAllItem(e)}
              />
            ),
            searchable: false,
            type: TableWrapper.dataTypes.BOOLEAN,
            key: "boolean",
            render: (item) => (
              <Checkbox
                value={item}
                checked={item.isAktif}
                onChange={(e) => addItem(e, item)}
              />
            ),
          },
          {
            label: t.translate("table.status"),
            type: TableWrapper.dataTypes.BOOLEAN,
            searchable: false,
            key: "isAktif",
            definition: {
              inputType: TableWrapper.inputTypes.CHECKBOX,
              defaultValue: false,
              criterias: ["equals", "notEquals"],
            },
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.isAktif
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.isAktif
                  ? t.translate("table.active")
                  : t.translate("table.inActive")}
              </TableWrapper.Status>
            ),
          },
          // {
          //   label: (
          //     <Checkbox
          //       label={t.translate("table.status")}
          //       value={checkAll}
          //       checked={!checkAll}
          //       onChange={(e) => addAllItem(e)}
          //     />
          //   ),
          //   searchable: false,
          //   key: "isAktif",
          //   render: (item) => (
          //     <Checkbox
          //       label={
          //         <TableWrapper.Status
          //           type={
          //             item.isAktif
          //               ? TableWrapper.Status.types.SUCCESS
          //               : TableWrapper.Status.types.ERROR
          //           }
          //         >
          //           {item.isAktif
          //             ? t.translate("table.active")
          //             : t.translate("table.inActive")}
          //         </TableWrapper.Status>
          //       }
          //       value={item}
          //       checked={item.isAktif}
          //       onChange={(e) => addItem(e, item)}
          //     />
          //   ),
          // },
          {
            label: t.translate("table.pertanyaan"),
            searchable: true,
            key: "pertanyaan",
            render: (item) => (<Text>{item.pertanyaan}</Text>),
            lineWrap: true,
            style:{maxWidth: "45vh", minWidth: '45vh'}
          },
          {
            label: t.translate("table.nameType"),
            searchable: false,
            key: "namaTipe",
            render: (item) => item.namaTipe,
          },
          {
            label: t.translate("table.nameMateri"),
            searchable: true,
            key: "namaMateri",
            render: (item) => item.namaMateri,
          },
          // {
          //     label: t.translate("table.jawaban"),
          //     searchable: true,
          //     key: "jawaban",
          //     render: (item) => item.jawaban,
          // },
          {
            label: t.translate("table.dibuatOleh"),
            key: "dibuatOleh",
            searchable: false,
            render: (item) => item.dibuatOleh,
          },
          {
            label: t.translate("table.tglDibuat"),
            type: TableWrapper.dataTypes.DATE,
            key: "tanggalDibuat",
            searchable: false,
            render: (item) => moment(item.tanggalDibuat).format("lll"),
          },
          {
            label: t.translate("table.diperbaharui"),
            key: "diperbaharuiOleh",
            searchable: false,
            render: (item) => item.diperbaharuiOleh,
          },
          {
            label: t.translate("table.tglDiperbaharui"),
            type: TableWrapper.dataTypes.DATE,
            searchable: false,
            render: (item) => moment(item.tanggalDiperbaharui).format("lll"),
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            getRole.includes("ADMIN")
          ),
          new TableWrapper.action(
            "Activate",
            "mdi mdi-check",
            () => {
              setSelectedItem(null);
              activateQuestionListConfirm();
            },
            getRole.includes("ADMIN")
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setEditShowForm(true);
            },
            getRole.includes("ADMIN")
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              showDeleteItem(item);
            },
            getRole.includes("ADMIN")
          ),
          new TableWrapper.action(
            (item) => (item.isAktif ? "De-activate" : "Activate"),
            (item) => (item.isAktif ? "mdi mdi-close" : "mdi mdi-check"),
            (item) => {
              activateQuestion(item);
            },
            getRole.includes("ADMIN")
          ),
          // new TableWrapper.action('Delete', 'mdi mdi-delete', (item) =>
          //     service.deleteById(item.name)
          // )
        ]}
        onFetchData={(query) => {
          let item = [];
          query["namaTipeSoal.contains"] = "Multiple Choice";
          // query["isAktif.equals"] = false;
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...search,
                ...query,
              };
              let getPage = await service.getSoalBytipe(query);
              console.log(query);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];

              getPage.data.map((d) => {
                if (d.isAktif) {
                  item.push(d);
                }
              });

              if (item.length == getPage.data.length) {
                setCheckAll(false);
              } else {
                setCheckAll(true);
              }

              setItemList(getPage.data);
              resolve(getPage);
            } catch (e) {
              reject(e);
              // ErrorService(e)
            }
          });
        }}
        // onFetchData={async query => {
        //   return service.getData()
        // }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <Confirm
        visible={showDelete}
        onRequestClose={() => {
          setShowDelete(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          deleteItem(selectedItem);
          setShowDelete(false);
          setSelectedItem(null);
        }}
        message={t.translate("message.soalDelete")}
      />
      <CreateSoal
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        type={'Multiple Choice'}
        item={selectedItem}
      />

      <CreateSoal
        visible={showEditForm}
        onRequestClose={() => {
          setEditShowForm(false);
          setSelectedItem(null);
        }}
        type={'Multiple Choice'}
        item={selectedItem}
      />
    </>
  );
};

export default inject("modalStore")(observer(ViewMultiple));
