import React, { useEffect, useState } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import { cloneDeep, find } from "lodash";
import t from "counterpart";
import service from "../../../services/assignUserLevel.service";
import services from "../../../services/assignUser.service";
import service_levelProduk from "../../../services/levelProduk.service";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import moment from "moment";

const defaultData = () => ({
  userId: "",
  user: "",
  levelProdukId: "",
  levelProduk: "",
  tanggalAwalPembelajaran: "",
  tanggalAkhirPembelajaran: "",
});
const CreateAssignLevel = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [dataSubmit, setDataSubmit] = useState({
    userId: "",
    levelProdukId: "",
    tanggalAwalPembelajaran: "",
    tanggalAkhirPembelajaran: "",
  });
  let [item, setItem] = useState(defaultData());
  let [ready, setReady] = useState(true);
  let [user, setUser] = useState(null);
  let [levelProduk, setLevelProduk] = useState(null);

  const initData = async () => {
    services
      .getAllUser({
        size: 200,
        ["namaRole.equals"]: "ROLE_PESERTA",
        ["companyId.equals"]: props.match.params.companyId,
        ["isAktif.equals"]: true,
      })
      .then((res) => {
        let temp = [];
        res.data.map((d) => {
          temp.push({
            value: d.id,
            label: `${d.nama} - ${d.email}`,
          });
        });
        setUser(temp);
      });
    service_levelProduk
      .getAllLevelProduk({
        size: 200,
        "kodePerusahaan.equals": props.match.params.companyId,
      })
      .then((res) => {
        let temp = [];
        res.data.map((d) => {
          temp.push({
            value: d.id,
            label: d.namaLevel + " - " + d.namaProduk,
          });
        });
        setLevelProduk(temp);
      });
    if (visible) {
      let selectedItem = defaultData();


      if (props.item) {
        try {
          let res = await service.getAssignLevelById(props.item.id);
          let { data } = res;
          setDataSubmit(data);
          // console.log(data)
          selectedItem.id = data.id;
          selectedItem.userId = data.username;
          selectedItem.user = data.userId;
          selectedItem.levelProduk = data.levelProdukId;
          selectedItem.levelProdukId = data.namaLevel;
          selectedItem.tanggalAwalPembelajaran =
            data.tanggalAwalPembelajaran &&
            moment(new Date(data.tanggalAwalPembelajaran)).format("YYYY-MM-DD");
          selectedItem.tanggalAkhirPembelajaran =
            data.tanggalAkhirPembelajaran &&
            moment(new Date(data.tanggalAkhirPembelajaran)).format(
              "YYYY-MM-DD"
            );
        } catch (e) {
          // onRequestClose()
        }
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const replaceChar = (origin, replace) => {
    let index = origin.indexOf(" ");
    let firstPart = origin.substr(0, index);

    let lastPart = origin.substr(index + 1);

    let newString = firstPart + replace + lastPart;

    return newString;
  };

  const onSubmit = async (data, callback) => {
    // console.log(data);
    let reqData = dataSubmit;
    reqData.userId = data.user;
    reqData.levelProdukId = data.levelProduk;
    reqData.tanggalAwalPembelajaran = moment(
      new Date(data.tanggalAwalPembelajaran + " 00:00:00")
    ).format("YYYY-MM-DD HH:mm:ss");
    reqData.tanggalAkhirPembelajaran = moment(
      new Date(data.tanggalAkhirPembelajaran + " 23:59:59")
    ).format("YYYY-MM-DD HH:mm:ss");

    reqData.kodePerusahaan = props.match.params.companyId;
    console.log(reqData);

    if (reqData.id) {
      try {
        const resp = await service.update(reqData, data.id);
        // console.log(resp)
        TableWrapper.updateDataItem(
          "view-assign-user-level",
          { id: data.id },
          data
        );
        TableWrapper.reload("view-assign-user-level");
        callback("Edit Success", false);
      } catch (error) {
        // console.log(error.message)
        callback("Edit Error " + error.message, true, false);
      }
    } else {
      try {
        // reqData.tanggalAwalPembelajaran = replaceChar(moment(
        //   new Date(data.tanggalAwalPembelajaran + ' 00:00:00')
        // ).format("YYYY-MM-DD HH:mm:ss"), 'T');
        // reqData.tanggalAkhirPembelajaran = replaceChar(moment(
        //   new Date(data.tanggalAkhirPembelajaran + ' 23:59:59')
        // ).format("YYYY-MM-DD HH:mm:ss"), 'T');

        const resp = await service.assign(
          reqData.userId,
          reqData.levelProdukId,
          reqData.tanggalAwalPembelajaran,
          reqData.tanggalAkhirPembelajaran,
          props.match.params.companyId
        );
        TableWrapper.updateDataItem(
          "view-assign-user-level",
          { id: data.id },
          data
        );
        TableWrapper.reload("view-assign-user-level");
        callback("Create Success", false);
      } catch (error) {
        // console.log(error.message)
        callback("Create Error " + error.message, true, false);
      }
    }
  };

  return (
    ready && (
      <DataForm
        baseId="create-assign-user-level"
        title={t.translate("modules.form.assign-user-level")}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.REACT_SELECT,
            label: t.translate("table.user"),
            key: "user",
            // data: user,
            options:
              user !== null
                ? user.map((d, i) => ({
                    value: d.value,
                    label: d.label,
                  }))
                : null,
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.REACT_SELECT,
            label: t.translate("table.levelProduk"),
            key: "levelProduk",
            // data: levelProduk,
            options:
              levelProduk !== null
                ? levelProduk.map((d, i) => ({
                    value: d.value,
                    label: d.label,
                  }))
                : null,
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.DATE,
            label: t.translate("table.startTraining"),
            key: "tanggalAwalPembelajaran",
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.DATE,
            label: t.translate("table.endTraining"),
            key: "tanggalAkhirPembelajaran",
            validation: "required",
          },
          // {
          //     inputType: DataForm.inputTypes.PRE_DATA
          // }
        ]}
        onChange={(data, key, value) => {
          // if (key == "userId") {
          //   let userDetail = find(user, { label: value });
          //   data.user = userDetail ? userDetail.value : null;
          // }
          // if (key == "levelProdukId") {
          //   let levelProdukDetail = find(levelProduk, { label: value });
          //   data.levelProduk = levelProdukDetail
          //     ? levelProdukDetail.value
          //     : null;
          // }

          // console.log(key, value)
          return data;
        }}
        onSubmit={onSubmit}
      />
    )
  );
};

export default CreateAssignLevel;
