import { crud, http } from '../libs/react-mpk/services';

let service = null
let serviceUrl = '/latihan'
service = new crud('/latihan');
let role = localStorage.getItem('getRole')
let userId = localStorage.getItem('getUserId')

service.getLatihan = async (query) => {

    if (role.includes('PESERTA')){
        query = {
            // 'dikerjakan.greaterThan': 1,
            "userId.equals": userId,
            ...query
        }
    }

    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}`,
        query
    })
}

service.getFilterLatihan = async (metodePembelajaran) => {
    let query = {
        page: 0,
        size: 9999,
        sort: "id,DESC",
        "metodePembelajaran.contains": metodePembelajaran,
    };

    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}`,
        query
    })
};

service.createLatihan = async (data, userId) => {
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}/${userId}`,
        data
    })
}
// service.createLatihan = async (materiPembelajaranId, userId) => {
//     return http.request({
//         method: http.methods.POST,
//         url: `${serviceUrl}`,
//         config: {
//             params: {
//                 materiPembelajaranId: materiPembelajaranId,
//                 userId: userId
//             }
//         }
//     })
// }

service.getLatihanById = async (Id) => {
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/${Id}`
    })
}

service.create = async (data) => {
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}`,
        data
    })
}

service.update = async (data, id) => {
    return http.request({
        method: http.methods.PUT,
        url: `${serviceUrl}/${id}`,
        data
    })
}

service.deleteById = async (id) => {
    return http.request({
        method: http.methods.DELETE,
        url: `${serviceUrl}/${id}`,
    })
}

service.getSoalLatihan = async (id, tipeSoal) => {
    console.log(id)
    let query = {
        page: 0,
        size: 50,
        sort: "id,DESC",
        "latihanId.equals": id,
        // "namaTipe.contains": tipeSoal
    };

    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/detail`,
        query
    })
}

const fileToFormData = (
    // pdf,
    excel,
    meta = {},
    // pdfLabel = "file",
    // pdfName,
    excelLabel = "file",
    excelName
) => {
    const data = new FormData();

    // if (pdf) {
    //     data.append(pdfLabel, pdf, pdfName || pdf.name);
    // }

    if (excel) {
        data.append(excelLabel, excel, excelName || excel.name);
    }
    const keys = Object.keys(meta);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        data.append(key, meta[key]);
    }
    return data;
};

service.uploadJawaban = async (
    url,
    // pdf,
    excel,
    data,
    config = {},
    options = {}
) => {
    let opt = Object.assign(
        {
            // pdfLabel: "filePdf",
            excelLabel: "fileExcel",
            // pdfName: null,
            excelName: null,
        },
        options
    );
    let formData = fileToFormData(
        // pdf,
        excel,
        data,
        // opt.pdfLabel,
        // opt.pdfName,
        opt.excelLabel,
        opt.excelName
    );

    return http.request({
        method: http.methods.POST,
        url,
        data: formData,
        config,
        options,
    });
};


service.answerSubmit = async (id, data) => {
    return http.request({
        method: http.methods.PUT,
        url: `${serviceUrl}/detail/${id}`,
        data
    })
}

service.assignScore = async (id, data) => {
    return http.request({
        method: http.methods.PUT,
        url: `${serviceUrl}/nilai_essay/${id}`,
        data
    })
}

service.submitPraktek = async(id, data) => {
    return http.request({
      method: http.methods.PUT,
      url: `${serviceUrl}/submit_praktek/${id}`,
      data
    })
}

service.importAssignment = async (file, companyId, kelasId) => {
    let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
            kodeKelas: kelasId
        }
      };
      return http.upload(
        `${serviceUrl}/import/${companyId}`,
        file,
        {},
        config
      )
}

export default service;