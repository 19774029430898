import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep, find } from "lodash";
import t from "counterpart";
import service from "../../services/ujian.service";
import servicesUsers from "../../services/assignUser.service";
import serviceRole from "../../services/role.service";
import serviceMateri from "../../services/materi.service";
import serviceType from "../../services/typeSoal.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import moment from "moment";
import iziToast from "izitoast";
import services from "../../services/levelProduk.service";

const defaultData = () => ({
  id: "",
  materiPembelajaranId: "",
  // roles: "",
  materiPembelajaran: {},
  userId: "",
  user: {},
  durasiUjian: "",
  tanggalMulaiUjian: "",
  jamMulaiUjian: "",
});
const CreateUjianEssay = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [dataSubmit, setDataSubmit] = useState({
    id: "",
    materiPembelajaranId: "",
    userId: "",
    durasiUjian: "",
    tanggalMulaiUjian: "",
  });
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [users, setUsers] = useState([]);
  let [materiPembelajaran, setMateriPembelajaran] = useState([]);
  let productId = props.match.params.productId;
  let companyId = props.match.params.companyId;
  let kelasId = props.match.params.kelasId;
  // let [viewUsers, setViewUsers] = useState(false);
  // let [role, setRole] = useState([]);
  // let [type, setType] = useState([]);
  useEffect(() => {
    //   serviceType.getAllTypeSoal({ size: 1000 }).then((res) => {
    //     setType(res.data);
    //   });
    // serviceRole.getAllRole({ size: 1000 }).then((res) => {
    //   setRole(res.data);
    // });
    if(visible){
      if (props.type){
        services.getUserMateri(productId, { size: 1000, metodePembelajaran: props.type }).then((res) => {
          console.log(res);
          let temp = [];
          res.data.map((d) => {
            temp.push({
              value: d.id,
              label: d.namaMateri + " - (" + d.metodePembelajaran + ")",
            });
          });
          setMateriPembelajaran(temp);
        });
      }
  
      servicesUsers.getUserAssign("ROLE_PESERTA").then((res) => {
        let temp = [];
        res.data.map((d) => {
          temp.push({
            value: d.id,
            label: `${d.nama} - (${d.email})`,
          });
        });
        setUsers(temp);
      });
    }
    
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();
        // console.log(props)

        if (props.item) {
          try {

            let res = await service.getUjianById(props.item.id);
            let { data } = res;
            setDataSubmit(data);
            selectedItem.id = data.id;
            selectedItem.durasiUjian = data.durasiUjian;
            selectedItem.materiPembelajaranId = data.materiPembelajaranId;

            selectedItem.tanggalMulaiUjian = moment(
              data.tanggalMulaiUjian
            ).format("YYYY-MM-DD");
            selectedItem.jamMulaiUjian = moment(data.tanggalMulaiUjian).format(
              "HH:mm"
            );
            // selectedItem.tanggalMulaiUjian = new Date(data.tanggalMulaiUjian);
            // selectedItem.jamMulaiUjian = new Date(data.tanggalMulaiUjian)
          } catch (e) {
            // onRequestClose()
            iziToast.error({
              message: e.message,
            });
          }
        }
        setItem(selectedItem);
      } else {
        setItem(defaultData());
      }
      setReady(visible);
    }
    initData();
  }, [visible]);

  const onSubmit = async (data, callback) => {
    let reqData = dataSubmit;
    reqData.durasiUjian = data.durasiUjian;
    let temp = new Date(data.tanggalMulaiUjian + " " + data.jamMulaiUjian);
    reqData.tanggalMulaiUjian = moment(temp).format("YYYY-MM-DD HH:mm:ss");
    reqData.kodePerusahaan = companyId
    reqData.kodeKelas = kelasId

    if (data.id) {
      try {
        const resp = await service.update(reqData, data.id);
        // console.log(resp)
        TableWrapper.updateDataItem("exam-table", { id: data.id }, data);
        TableWrapper.reload("exam-table");
        callback("Edit Success", false);
      } catch (error) {
        // console.log(error.message)
        callback("Edit Error " + error.message, true, false);
      }
    } else {
      reqData.materiPembelajaranId = data.materiPembelajaran;
      reqData.userId = data.user;
      try {
        const resp = await service.createUjian(reqData, data.user);
        TableWrapper.updateDataItem("exam-table", { id: data.id }, data);
        TableWrapper.reload("exam-table");
        callback("Create Success", false);
      } catch (error) {
        console.log(error);
        callback("Create Error " + error.message, true, false);
      }
    }
  };

  const closeForm = (e) => {
    setItem(defaultData());
    onRequestClose();
  };

  return (
    ready && (
      <DataForm
        baseId="create-soal-ujian"
        title={t.translate("modules.form.ujian")}
        visible={visible}
        // data={item}
        onRequestClose={(e) => closeForm(e)}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("table.namaUser"),
            key: "userId",
            labelKey: "label",
            valueKey: "label",
            data: users,
            // options:
            //   users !== null
            //     ? users.map((d, i) => ({
            //         value: d.id,
            //         children: d.nama,
            //       }))
            //     : null,
            show: props.item ? false : true,
            validation: props.item ? null : "required",
          },
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("table.nameMateri"),
            key: "materiPembelajaranId",
            labelKey: "label",
            valueKey: "label",
            data: materiPembelajaran,
            // options: materiPembelajaran.map((d) => ({
            //   value: d.id,
            //   children: d.namaMateri + " - (" + d.metodePembelajaran + ")",
            // })),
            show: props.item ? false : true,
            validation: props.item ? null : "required",
          },
          {
            inputType: DataForm.inputTypes.DATE,
            label: t.translate("table.tanggalMulaiUjian"),
            key: "tanggalMulaiUjian",
            // type: "date",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.jamMulaiUjian"),
            key: "jamMulaiUjian",
            type: "time",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("table.durasiUjian"),
            key: "durasiUjian",
            required: true,
          },
          // {
          //     inputType: DataForm.inputTypes.PRE_DATA
          // }
        ]}
        onChange={(data, key, value) => {
          if (key == "userId") {
            let userDetail = find(users, { label: value });
            data.user = userDetail ? userDetail.value : null;
          }
          if (key == "materiPembelajaranId") {
            let materiPembelajaranDetail = find(materiPembelajaran, {
              label: value,
            });
            data.materiPembelajaran = materiPembelajaranDetail
              ? materiPembelajaranDetail.value
              : null;
          }
          // if (key === "roles") {
          //   let temp = find(role, { namaRole: value });
          //   setViewUsers(true);

          //   servicesUsers.getUserAssign(temp.namaRole).then((res) => {
          //     setUsers(res.data);
          //   });
          // }
          return data;
        }}
        watchDefaultData={false}
        onSubmit={onSubmit}
      />
    )
  );
};

export default CreateUjianEssay;
