import { crud, http } from "../libs/react-mpk/services";

let service = null;
let serviceUrl = "/user";
service = new crud("/user");

service.getAssign = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.getUserAssign = async (roleName) => {
  let query = {
    page: 0,
    size: 200,
    sort: "tanggalDibuat,DESC",
    "namaRole.equals": roleName,
    "isAktif.equals": true,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.getAllUser = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query
  });
};

service.getUserKelas = async (levelProdukId, companyId) => {
  let query = {
    page: 0,
    size: 200,
    "levelProdukId.equals": levelProdukId,
    "kodePerusahaan.equals": companyId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/list/kelas`,
    query,
  });
};

service.getAssignById = async (Id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${Id}`,
  });
};

service.create = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

service.login = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/login`,
    data,
  });
};

service.update = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

service.activation = async (id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/activate/${id}`,
  });
};

service.deleteById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

service.exportUser = async () => {
  return http.download(
    `${serviceUrl}/export/all`,
    {},
    {},
    { fileName: "userdata.xlsx" }
  );
};

service.importUser = async (file) => {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return http.upload(`${serviceUrl}/import`, file, {}, config);
};

export default service;
