import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import { TabsWrapper } from "../../libs/react-mpk/wrapper";
import ViewNilai from "./ViewNilai";
import ViewNilaiUjian from "./ViewNilaiUjian";
import ViewPraktekNilai from "./ViewPraktek";
import ViewNilaiRemedial from "./ViewNilaiRemedial";

const ContainerLatihan = ({
  className = "",
  navigationStore,
  envStore,
  ...props
}) => {
  let companyId = props.match.params.companyId
  let levelId = props.match.params.levelId
  let productId = props.match.params.productId
  let kelasId = props.match.params.kelasId
  return (
    <TabsWrapper
      title={"DAFTAR NILAI PESERTA"}
      baseId="view-nilai"
      className={className}
      tabs={[
        {
          label: t.translate("tab.latihan"),
          key: "view-latihan-nilai",
          render: <ViewNilai showCommandbar={false} {...props}/>,
        },
        {
          label: t.translate("tab.ujian"),
          key: "view-ujian-nilai",
          render: <ViewNilaiUjian showCommandbar={false} {...props}/>,
        },
        {
          label: t.translate("tab.remedy"),
          key: "view-praktek-nilai",
          render: <ViewNilaiRemedial showCommandbar={false} {...props}/>,
        },
      ]}
      onChange={(tab, callback) => {
        // navigationStore.redirectTo(`/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class/${kelasId}/nilai`);
        callback();
      }}
    />
    );
};

export default inject(
  "navigationStore",
  "envStore"
)(observer(ContainerLatihan));
