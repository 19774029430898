import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { http } from "../libs/react-mpk/services";
import { PageWrapper } from '../libs/react-mpk/wrapper';
import { TableSample, FormSample, DetailsSample, DashboardSample } from '../modules';
const Internal = ({ history }) => {
  
  const [isLoading, setIsLoading] = useState(true);
  let token = localStorage.getItem("basic-token");
  useEffect(() => {
    checkingAuth();
  }, []);

  const checkingAuth = () => {
    if (token) {
      http.setRequestInterceptor((config) => {
        config.headers = config.headers || {};
        config.headers.Authorization = `Basic ${token}`;
        return config;
      });
      // getUser();
      setIsLoading(false);
    } else {
      window.location = "/main";
    }
  };

let role = "user"

  return (
    <PageWrapper
      sidebarCollapsible={true}
      inverseTheme={true}
      // sidebarMenu={role === "admin" ? menuAdmin : role === "user" ? menu : []}
    >
      <Router history={history}>
        <Switch>
          <Route path="/" exact={true}>
            <Redirect to="/internal/dashboard" />
          </Route>

          <Route path="/main/menu" exact={true}>
            <Redirect to="/internal/dashboard" />
          </Route>

          <Route
            path='/internal/dashboard'
            render={props => (
              <TableSample {...props} />
            )}
          />
          <Route
            path='/internal/table'
            render={props => (
              <TableSample {...props} />
            )}
          />
          <Route
            path='/internal/form'
            render={props => (
              <FormSample {...props} />
            )}
          />
          <Route
            path='/internal/details/:tab'
            render={props => (
              <DetailsSample {...props} />
            )}
          />


        </Switch>
      </Router>
    </PageWrapper>
  )
}


export default inject("authStore")(observer(Internal));