import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import service from "../../services/assignUser.service";
import services from "../../services/role.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import moment from "moment";

const defaultData = () => ({
  id: "",
  nama: "",
  username: "",
  email: "",
  jenisKelamin: "",
  kontak: "",
  alamat: "",
  companyId: "",
  nik: "",
  tanggalLahir: "",
  tempatLahir: "",
  npwp: "",
  isAktif: false,
  authorities: [],
});
const CreateAssignUser = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let userId = localStorage.getItem("getUserId");
  const gender = [
    { id: "Laki-Laki", nama: "Laki-Laki" },
    { id: "Perempuan", nama: "Perempuan" },
  ];
  let [dataSubmit, setDataSubmit] = useState({
    id: "",
    nama: "",
    username: "",
    email: "",
    jenisKelamin: "",
    kontak: "",
    alamat: "",
    companyId: "",
    npwp: "",
    tanggalLahir: "",
    tempatLahir: "",
    nik: "",
    isAktif: false,
    authorities: [],
  });
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [role, setRole] = useState([]);
  let [disableNama, setDisableNama] = useState(false);
  let [disableUsername, setDisableUsername] = useState(false);
  let [disableCompany, setDisableCompany] = useState(false);
  let [disableNPWP, setDisableNPWP] = useState(false);
  let [disableEmail, setDisableEmail] = useState(false);

  const [inputValidasiNpwp, setInputValidasiNpwp] = useState(
    "##.###.###.#-###.###"
  );
  const [inputValidasiNo, setInputValidasiNo] = useState("#############");

  useEffect(() => {
    services.getRole({ size: 1000 }).then((res) => {
      // console.log(res)
      setRole(res.data);
    });
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();
        if (props.item) {
          // console.log(props.item)
          try {
            let res = await service.getAssignById(props.item.id);
            let { data } = res;
            console.log(data);
            selectedItem.nama = data.nama;
            selectedItem.username = data.username;
            selectedItem.email = data.email;
            selectedItem.jenisKelamin = data.jenisKelamin;
            selectedItem.kontak = data.kontak;
            selectedItem.companyId = data.companyId;
            selectedItem.nik = data.nik;
            selectedItem.tempatLahir = data.tempatLahir;
            selectedItem.tanggalLahir =
              data.tanggalLahir &&
              moment(new Date(data.tanggalLahir)).format("YYYY-MM-DD");
            selectedItem.password = data.password;
            selectedItem.npwp = data.npwp;
            selectedItem.alamat = data.alamat;
            selectedItem.isAktif = data.isAktif;
            selectedItem.id = data.id;
            selectedItem.authorities = data.authorities[0];

            if (selectedItem.nama) {
              setDisableNama(true);
            }
            if (selectedItem.username) {
              setDisableUsername(true);
            }
            if (selectedItem.email) {
              setDisableEmail(true);
            }
            if (selectedItem.companyId) {
              setDisableCompany(true);
            }
            if (selectedItem.npwp != null) {
              setDisableNPWP(true);
            }
            else setDisableNPWP(false);
            setDataSubmit(data);
            // delete selectedItem.authorities
            console.log(selectedItem);
          } catch (e) {
            // onRequestClose()
          }
        }
        setItem(selectedItem);
      } else {
        setItem(defaultData());
      }
      setReady(visible);
    }
    initData();
  }, [visible]);

  const onSubmit = async (data, callback) => {
    // console.log(data)
    let reqData = dataSubmit;
    reqData.authorities.splice(0, 1);
    reqData.nama = data.nama;
    reqData.username = data.username;
    reqData.email = data.email;
    reqData.jenisKelamin = data.jenisKelamin;
    reqData.kontak = data.kontak;
    reqData.companyId = data.companyId;
    reqData.nik = data.nik;
    reqData.tempatLahir = data.tempatLahir;
    reqData.tanggalLahir = moment(new Date(data.tanggalLahir)).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    reqData.password = data.password;
    reqData.npwp = data.npwp;
    reqData.isAktif = data.isAktif;
    reqData.alamat = data.alamat;
    reqData.authorities.push(data.authorities);

    if (data.id === userId) {
      localStorage.setItem("getRole", data.authorities);
    }
    // reqData.data.authorities[0]
    // let roleName = reqData.data.authorities[0]
    if (reqData.id) {
      try {
        const resp = await service.update(reqData, data.id);
        // console.log(resp)
        TableWrapper.updateDataItem("view-assign-user", { id: data.id }, data);
        TableWrapper.reload("view-assign-user");
        callback("Edit Success", false);
      } catch (error) {
        // console.log(error.message)
        callback("Edit Error " + error.message, true, false);
      }
    } else {
      try {
        reqData.password = "Mpkp45t!";
        const resp = await service.create(reqData);
        console.log(resp);
        TableWrapper.updateDataItem("view-assign-user", { id: data.id }, data);
        TableWrapper.reload("view-assign-user");
        callback("Create Success", false);
      } catch (error) {
        // console.log(error.message)
        callback("Create Error " + error.message, true);
      }
    }
  };

  return (
    ready && (
      <DataForm
        baseId="create-assign-user"
        title={t.translate("modules.form.assign-user").toUpperCase()}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("table.npwp"),
            key: "npwp",
            mask: inputValidasiNpwp,
            maskChar: "_",
            maskValue: "string",
            // required: true,
            // validation: "required",
            disabled: disableNPWP,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.nama"),
            key: "nama",
            required: true,
            validation: "required",
            disabled: disableNama,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.username"),
            key: "username",
            required: true,
            validation: "required",
            disabled: disableUsername,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.email"),
            key: "email",
            required: true,
            validation: "required",
            disabled: disableEmail,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("table.nik"),
            key: "nik",
            minlength: "16",
            disabled: true,
            maxLength: "16",
            required: true,
            validation: "required"
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("table.jenisKelamin"),
            key: "jenisKelamin",
            options: gender.map((d) => ({
              value: d.id,
              children: d.nama,
            })),
            required: true,
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.alamat"),
            key: "alamat",
            type: "text",
            required: true,
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("table.kontak"),
            key: "kontak",
            mask: inputValidasiNo,
            maskChar: "_",
            maskValue: "string",
            required: true,
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.DATE,
            label: t.translate("table.birthDate"),
            key: "tanggalLahir",
            required: true,
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("table.birthPlace"),
            key: "tempatLahir",
            required: true,
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.CHECKBOX,
            label: t.translate("table.isAktif"),
            key: "isAktif",
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("table.role"),
            key: "authorities",
            options:
              role !== null
                ? role.map((d, i) => ({
                    value: d.namaRole,
                    label: d.namaRole,
                  }))
                : null,
                required: true,
                validation: "required",
          },
          // {
          //     inputType: DataForm.inputTypes.PRE_DATA
          // }
        ]}
        onBeforeChange={(key, value) => {
          // console.log(key, value);
          return value;
        }}
        onSubmit={onSubmit}
        submitLabel={"Simpan"}
      />
    )
  );
};

export default CreateAssignUser;
