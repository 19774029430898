import React, { useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from '../../services/typeSoal.service'
import moment from 'moment'
import Confirm from '../Confrimation/Confirm'
import CreateTypeSoal from './CreateTypeSoal'

const ViewTypeSoal = ({
    className = '',
    showCommandbar = true
}) => {
    const [showForm, setShowForm] = useState(false)
    const [showEditForm, setEditShowForm] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    let [search, setSearch] = useState({})
    const [showDelete, setShowDelete] = useState(false);

    const showDeleteItem = (data) => {
        setShowDelete(true);
        setSelectedItem(data);
    };
    const deleteItem = (item) => {
        service.deleteById(item.id).then((res) => {
          TableWrapper.updateDataItem("view-type-soal", { id: item.id }, item);
          TableWrapper.reload("view-type-soal");
        });
      };
    return (
        <>
            <TableWrapper
                baseId="view-type-soal"
                title={t.translate('modules.table.typeSoal')}
                className={className}
                // hintMessage={t.translate('modules.table.hint.message')}
                // hintIconClassName="mdi mdi-information"
                // hintMore={t.translate('modules.table.hint.more')}
                defaultData={[]}
                defaultSortBy="namaTipe"
                defaultCollapse={true}
                useCriteria={true}
                selectable={false}
                useQueryHistory={false}
                // extraFilter={<div>Test</div>}
                // multiColumnFilter={true}
                columns={[
                    {
                        label: t.translate("table.nameTypeSoal"),
                        searchable: true,
                        key: "namaTipeSoal",
                        render: (item) => item.namaTipe,
                    },
                    {
                        label: t.translate("table.dibuatOleh"),
                        key: "dibuatOleh",
                        render: (item) => item.dibuatOleh,
                    },
                    {
                        label: t.translate("table.tglDibuat"),
                        type: TableWrapper.dataTypes.DATE,
                        key: "tanggalDibuat",
                        render: item => (moment(item.tanggalDibuat).format('lll'))
                    },
                    {
                        label: t.translate("table.diperbaharui"),
                        key: "diperbaharuiOleh",
                        render: (item) => item.diperbaharuiOleh,
                    },
                    {
                        label: t.translate("table.tglDiperbaharui"),
                        type: TableWrapper.dataTypes.DATE,
                        render: item => (moment(item.tanggalDiperbaharui).format('lll'))
                    },
                ]}
                actions={[
                    // new TableWrapper.action(
                    //     'Add',
                    //     'mdi mdi-plus',
                    //     () => {
                    //         setSelectedItem(null)
                    //         setShowForm(true)
                    //     },
                    //     true
                    // )
                ]}
                itemActions={[
                    // new TableWrapper.action(
                    //     'Edit',
                    //     'mdi mdi-pencil',
                    //     (item) => {
                    //         setSelectedItem(item)
                    //         setEditShowForm(true)
                    //     },
                    //     true
                    // ),
                    // new TableWrapper.action(
                    //     "Delete",
                    //     "mdi mdi-delete",
                    //     (item) => {
                    //         showDeleteItem(item);
                    //     },
                    //     true
                    // ),
                    // new TableWrapper.action('Delete', 'mdi mdi-delete', (item) =>
                    //     service.deleteById(item.name)
                    // )
                ]}
                onFetchData={(query) => {
                    return (
                        new Promise(async (resolve, reject) => {
                            try {
                                // query = {
                                //     ...search,
                                //     ...query
                                // }
                                let getPage = await service.getTypeSoal(query)
                                console.log(query)
                                getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                                resolve(getPage)
                            } catch (e) {
                                reject(e)
                                // ErrorService(e)
                            }
                        })
                    )
                }}
                // onFetchData={ async query => {
                //   return service.getData()
                // }}
                showCommandbar={showCommandbar}
                showFilterPeriod={true}
            />
            <Confirm
                visible={showDelete}
                onRequestClose={() => {
                    setShowDelete(false);
                    setSelectedItem(null);
                }}
                onSubmitRequest={() => {
                    deleteItem(selectedItem);
                    setShowDelete(false);
                    setSelectedItem(null);
                }}
                message={t.translate("message.typeSoalDelete")}
            />
            <CreateTypeSoal
                visible={showForm}
                onRequestClose={() => {
                    setShowForm(false)
                    setSelectedItem(null)
                }}
                item={selectedItem}
            />

            <CreateTypeSoal
                visible={showEditForm}
                onRequestClose={() => {
                    setEditShowForm(false)
                    setSelectedItem(null)
                }}
                item={selectedItem}
            />
        </>
    )
}

export default ViewTypeSoal
