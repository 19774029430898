import iziToast from "izitoast";
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  Button,
  Text,
  Card,
  typography,
  TextArea,
  DialogHeader,
  FileInput,
} from "react-md";
import service from "../../../services/latihan.service";
import soalService from "../../../services/soal.service";
import t from "counterpart";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";

const ViewSoalEssay = ({
  visible = false,
  onRequestClose = () => {},
  onSubmitRequest = () => {},
  message = "",
  ...props
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  let [questionList, setQuestionList] = useState([]);
  let [name, setName] = useState("");
  let [getDate, setGetDate] = useState("");
  const next = () => {
    if (questionList.length - 1 === currentIndex) return;
    setCurrentIndex(currentIndex + 1);
  };

  const previous = () => {
    if (currentIndex === 0) return;
    setCurrentIndex(currentIndex - 1);
  };

  const initData = async () => {
    if (visible) {
      if (props.item) {
        setName(props.item.judulLatihan);
        let finishTime = new Date(props.item.tanggalAkhirLatihan).getTime();
        let now = new Date().getTime();
        let getTime = finishTime - now;
        let days = Math.floor(getTime / (1000 * 60 * 60 * 24));
        // let hours = Math.floor((getTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        // let minutes = Math.floor((getTime / (1000 * 60 * 60)) / (1000 * 60))
        // let seconds = Math.floor((getTime % (1000 * 60)) / 1000)
        // console.log("days", days)
        setGetDate(days);
        // setGetDate((new Date(props.item.tanggalAkhirLatihan).getTime() - new Date().getTime()) / (1000 * 3600 * 24))
        try {
          let res = await service.getSoalLatihan(props.item.id, "Essay");

          let { data, status } = res;
          data.map((d) => {
            d.jawabanLatihan = d.jawabanLatihan ? d.jawabanLatihan : "";
          });

          setQuestionList(data);
          // console.log(data)
        } catch (err) {
          iziToast.error({
            message: err.message,
          });
        }
      }
    }
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const changeAnswer = (e, idx) => {
    let temp = [...questionList];
    temp[idx].jawabanLatihan = e.target.value;

    setQuestionList(temp);
  };

  const downloadQuestionHandler = async (e) => {
    let item = questionList[currentIndex];

    try {
      let res = await soalService.downloadSoal(item.soalId, item.filename);
      iziToast.success({
        message: "success",
      });
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  const changeFile = async (e) => {
    const [file] = Array.from(e.currentTarget.files || [null]);
    if (file) {
      let item = questionList[currentIndex];
      console.log(item);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      try {
        let res = await service.uploadJawaban(
          `/ujian/uploadjawaban/${props.item.latihanId}/${item.soalId}`,
          // filePdf,
          file,
          {},
          config
        );
        let { data, status } = res;
        iziToast.success({
          message: "Success",
        });
      } catch (err) {
        iziToast.error({
          message: err.message,
        });
        // onRequestClose();
      }
    }
  };

  const submit = async (e) => {
    e.preventDefault();

    try {
      let res = await service.answerSubmit(props.item.latihanId, questionList);
      let { data, status } = res;
      iziToast.success({
        message: "success",
      });
      TableWrapper.updateDataItem(props.tableId, { id: data.id }, data);
      TableWrapper.reload(props.tableId);
      onRequestClose();
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
    // service.answerSubmit(questionList)
  };

  return (
    <>
      <Dialog
        visible={visible}
        onRequestClose={onRequestClose}
        style={{
          width: "800px",
          height: "600px",
        }}
      >
        <DialogHeader>Durasi Pengerjaan Sisa {getDate} Hari</DialogHeader>
        <DialogContent>
          <Text
            style={{
              textAlign: "center",
              fontSize: "35px",
            }}
          >
            {name}
            <hr></hr>
          </Text>

          <div
            style={{
              textAlign: "center",
            }}
          >
            <div
              style={{
                justifyItems: "center",
                display: "flex",
                flexWrap: "wrap",
                backgroundColor: "whitesmoke",
              }}
            >
              {questionList.map((item, index) => (
                <Button
                  themeType="contained"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    justifyItems: "center",
                    height: 40,
                    width: 40,
                    marginRight: 2,
                    marginLeft: 2.3,
                    borderRadius: 5,
                    backgroundColor:
                      index === currentIndex
                        ? "cyan"
                        : item.jawabanLatihan
                        ? "grey"
                        : "transparent",
                  }}
                  onClick={() => setCurrentIndex(index)}
                >
                  {index + 1}
                </Button>
              ))}
            </div>
          </div>

          <Card
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                fontSize: "25px",
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              {currentIndex + 1}.{" "}
              {questionList.length > 0 &&
                questionList[currentIndex].soalPertanyaan}
            </div>
            {questionList.length > 0 && !questionList[currentIndex].filename ? (
              <div
                style={{
                  width: "752px",
                  justifyItems: "center",
                  backgroundColor: "whitesmoke",
                  fontSize: "20px",
                }}
              >
                <TextArea
                  placeHolder={`${t.translate("form.jawaban")}`}
                  rows={3}
                  maxRows={3}
                  value={
                    questionList.length > 0 &&
                    questionList[currentIndex].jawabanLatihan
                  }
                  onChange={(e) => changeAnswer(e, currentIndex)}
                />
              </div>
            ) : (
              <>
                <div
                  style={{
                    width: "752px",
                    justifyItems: "center",
                    backgroundColor: "whitesmoke",
                    fontSize: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Button onClick={(e) => downloadQuestionHandler(e)}>
                    Download File
                  </Button>
                  <FileInput
                    id={`Excel`}
                    buttonType="text"
                    theme="primary"
                    themeType="contained"
                    onChange={(e) => changeFile(e)}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  >
                    File Excel
                  </FileInput>
                </div>
              </>
            )}
          </Card>
        </DialogContent>

        <DialogFooter>
          <div>
            <Button
              id="dialog-previous"
              theme="primary"
              themeType="contained"
              style={{ margin: "5px" }}
              onClick={() => previous()}
              disabled={currentIndex === 0 ? true : false}
            >
              Previous
            </Button>

            {questionList.length - 1 === currentIndex ? (
              <Button
                id="dialog-next"
                theme="primary"
                themeType="contained"
                style={{ margin: "5px" }}
                onClick={(e) => submit(e)}
                disabled={questionList.dikerjakan === 2 ? true : false}
              >
                Submit
              </Button>
            ) : (
              <Button
                id="dialog-next"
                theme="primary"
                themeType="contained"
                style={{ margin: "5px" }}
                onClick={() => next()}
                disabled={
                  questionList.length - 1 === currentIndex ? true : false
                }
              >
                Next
              </Button>
            )}
          </div>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ViewSoalEssay;
