import { crud, http } from "../libs/react-mpk/services";

let service = null;
let serviceUrl = "/user_level_produk";
service = new crud("/user_level_produk");

service.getAssignLevel = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.getAssignLevelById = async (Id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${Id}`,
  });
};

service.getAssignId = async (userId, levelProdukId) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query: {
      userId: userId,
      levelProdukId: levelProdukId,
    },
  });
};

service.getFileName = async (userId, levelProdukId) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/get_file_name`,
    query: {
      userId: userId,
      levelProdukId: levelProdukId,
    },
  });
};

service.create = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

service.login = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/login`,
    data,
  });
};

service.update = async (data, id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
  });
};

service.assign = async (
  userId,
  levelProdukId,
  tanggalAwalPembelajaran,
  tanggalAkhirPembelajaran,
  companyId
) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/assign`,
    config: {
      params: {
        userId: userId,
        levelProdukId: levelProdukId,
        kodePerusahaan: companyId
      },
    },
    data: {
      tanggalAwalPembelajaran: tanggalAwalPembelajaran,
      tanggalAkhirPembelajaran: tanggalAkhirPembelajaran,
    },
  });
};

service.deleteById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

service.checkSertifikasiLulus = async (userId) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/check_lulus`,
    config: {
      params: {
        userId: userId,
      },
    },
  });
};

service.generateSertifikasiLulus = async (userId, levelProdukId) => {
  let config = Object.assign({
    responseType: "arraybuffer",
  });
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/download_user_sertifikat`,
    query: {
      userId: userId,
      levelProdukId: levelProdukId,
    },
    config,
  });
};

service.exportData = async (companyId) => {
  return http.download(
    `${serviceUrl}/export/all/${companyId}`,
    {},
    {},
    { fileName: "User_Level_Data.xlsx" }
  );
}

service.importData = async (file, companyId) => {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return http.upload(`${serviceUrl}/import/${companyId}`, file, {}, config)

}

service.generateMultiCertificate = async(levelList, isDefault) => {

  let config = Object.assign({
    responseType: 'arraybuffer',
    params: {
      isDefault: isDefault
    }
  })

  return http.request({
    url: `${serviceUrl}/generate_multiple_sertifikat`,
    method: http.methods.POST,
    data: levelList,
    config: config
  })
}

service.downloadMultiCertificate = async(levelList) => {

  let config = Object.assign({
    responseType: 'arraybuffer',
  })

  return http.request({
    url: `${serviceUrl}/download_multiple_sertifikat`,
    method: http.methods.POST,
    data: levelList,
    config: config
  })
}

service.exportReport = async (companyId) => {
  let options = {
    fileName: "report.xlsx",
  };
  return http.download(`${serviceUrl}/export/report/${companyId}`, {}, {}, options);
};

service.generateCertificate = async (id) => {
  let config = Object.assign({
    responseType: "arraybuffer",
  });
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/generate_sertifikat/${id}`,
    config,
  });
}

export default service;
